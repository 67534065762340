import React, { Component } from 'react';
import OfferAds             from './OfferAds';
import RecentAds            from './RecentAds';
import KissanDeals          from './KissanDeals';
import OtherAds             from './OtherAds';

class Posts extends Component {
    render() {
        const {
            location, category, offerads, recentads, kissandeals, otherads,
            showofferads, showrecentads, showkissandeals, showotherads } = this.props;

        return(
            <React.Fragment>
                <OfferAds
                    location={location}
                    category={category}
                    offerads={offerads}
                    showofferads={showofferads} />
                <RecentAds
                    location={location}
                    category={category}
                    recentads={recentads}
                    showrecentads={showrecentads} />
                <KissanDeals
                    location={location}
                    category={category}
                    kissandeals={kissandeals}
                    showkissandeals={showkissandeals} />
                <OtherAds
                    location={location}
                    category={category}
                    otherads={otherads}
                    showotherads={showotherads} />
            </React.Fragment>
        );
    }
}

export default Posts;