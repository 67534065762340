import React, { Component } from 'react';
import { Link }             from 'react-router-dom';
import Img                  from 'react-image';
import FontAwesome          from 'react-fontawesome';
import { toCurrency }       from '../../helpers/Formatters';

class PropertyTimeOrProfitBased extends Component {
    render() {
        const { imageloader, property, adstatus } = this.props;

        return(
            <React.Fragment>
            <div className="row mb-5 no-mobile" key={property.property_details._id}>
                <div className="col-3">
                    <div className="ad-img-wrapper">
                        <Link to={'/property/' + property.property_details._id}>
                            <Img
                                src={property.property_details.image} alt="..."
                                loader={ imageloader }
                                unloader={ imageloader }
                                className="card-img-top img-fluid img-rounded ad-img" />
                        </Link>
                    </div>
                    {adstatus}
                </div>
                <div className="col-5 ad-details-wrapper">
                    <p className="ad-category">{property.property_details.category}</p>
                    <Link to={'/property/' + property.property_details._id}>
                        <p className="ad-title">{property.property_details.title}</p>
                    </Link>
                    <div className="row ad-location-user-row">
                        <div className="col-12">
                            <span className="ad-location-wrapper">
                                <img src={require("../../images/icons/ad-location.png")} alt="Location Icon" />
                                <span className="ml-2 ad-location">{property.property_details.location}</span>
                            </span>
                            <span className="ml-4 ad-by-wrapper">
                                <img src={require("../../images/icons/ad-by.png")} alt="User Icon" />
                                <span className="ml-2 ad-by">{property.property_details.user_name}</span>
                            </span>
                        </div>
                    </div>
                    <div className="ad-description">{property.property_details.description}</div>
                    <div className="row">
                        <div className="col-10">
                            <p className="ad-total-price-wrapper">
                                <span className="ad-total-price-label">Total Price : </span>
                                <span className="ad-total-price-value">
                                    <FontAwesome name="rupee" /> {toCurrency(property.property_details.price)}
                                </span>
                            </p>
                        </div>
                        <div className="col-2">
                            <img src={require("../../images/icons/share-ad.png")} className="float-right" alt="Share Ad Icon" />
                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <div className="row">
                        <div className="col-6">
                            <div className="ad-share-wrapper">
                                <p className="text-center ad-share-w-label">Joined Share</p>

                                <div className="row no-gutters">
                                    <div className="col-6 text-center">
                                        <img src={require("../../images/icons/users-joined.png")} alt="Users Joined" />
                                        <span className="ml-2 ad-share-w-value">{property.share_details.total_approved_user}</span>
                                    </div>
                                    <div className="col-6 text-center">
                                        <img src={require("../../images/icons/shares-joined.png")} alt="Shares Joined" />
                                        <span className="ml-2 ad-share-w-value">{property.share_details.total_approved_user_share}%</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="ad-share-wrapper">
                                <p className="text-center ad-share-w-label">Pending Share</p>

                                <div className="row no-gutters">
                                    <div className="col-6 text-center">
                                        <img src={require("../../images/icons/users-tojoin.png")} alt="Users To Join" />
                                        <span className="ml-2 ad-share-w-value">{property.share_details.total_pending_user}</span>
                                    </div>
                                    <div className="col-6 text-center">
                                        <img src={require("../../images/icons/shares-tojoin.png")} alt="Shares To Join" />
                                        <span className="ml-2 ad-share-w-value">{property.share_details.total_pending_user_share}%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-12">
                            <Link to={'/property/' + property.property_details._id} 
                                className="btn btn-block apf-button interest-link">Join</Link>
                        </div>
                    </div>
                </div>
            </div>

{/* For mobile view only */}
            <div className="row mb-1 mobile" key={property.property_details._id}>
                <div className="col-5 img-wrapper">
                    <div className="ad-img-wrapper">
                        <Link to={'/property/' + property.property_details._id}>
                            <Img
                                src={property.property_details.image} alt="..."
                                loader={ imageloader }
                                unloader={ imageloader }
                                className="card-img-top img-fluid img-rounded ad-img" />
                        </Link>
                    </div>
                    {adstatus}
                </div>
                <div className="col-7 ad-details-wrapper">
                    <p className="ad-category">{property.property_details.category}</p>
                    <Link to={'/property/' + property.property_details._id}>
                        <p className="ad-title">{property.property_details.title}</p>
                    </Link>
                    <div className="row ad-location-user-row">
                        <div className="col-12 flex-wrap row">
                            <span className="col-12 ad-location-wrapper">
                                <img src={require("../../images/icons/ad-location.png")} alt="Location Icon" />
                                <span className="ml-2 ad-location">{property.property_details.location}</span>
                            </span>
                            <span className="col-12 ad-by-wrapper">
                                <img src={require("../../images/icons/ad-by.png")} alt="User Icon" />
                                <span className="ml-2 ad-by">{property.property_details.user_name}</span>
                            </span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p className="ad-total-price-wrapper">
                                <span className="ad-total-price-label">Total Price : </span>
                                <span className="ad-total-price-value">
                                    <FontAwesome name="rupee" /> {toCurrency(property.property_details.price)}
                                </span>
                            </p>
                        </div>
                        {/* <div className="col-2">
                            <img src={require("../../images/icons/share-ad.png")} className="float-right" alt="Share Ad Icon" />
                        </div> */}
                    </div>
                    </div>
                    
                    
               
                <div className="col-12">
                    <div className="row">
                        <div className="col-6">
                            <div className="ad-share-wrapper">
                                

                                <div className="row no-gutters">
                                    <p className="col-12 text-center ad-share-w-label">Joined Share</p>
                                    <div className="col-6 text-center">
                                        <img src={require("../../images/icons/users-joined.png")} alt="Users Joined" />
                                        <span className="ml-2 ad-share-w-value">{property.share_details.total_approved_user}</span>
                                    </div>
                                    <div className="col-6 text-center">
                                        <img src={require("../../images/icons/shares-joined.png")} alt="Shares Joined" />
                                        <span className="ml-2 ad-share-w-value">{property.share_details.total_approved_user_share}%</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="ad-share-wrapper">
                                <p className="col-12 text-center ad-share-w-label">Pending Share</p>

                                <div className="row no-gutters">
                                    <div className="col-6 text-center">
                                        <img src={require("../../images/icons/users-tojoin.png")} alt="Users To Join" />
                                        <span className="ml-2 ad-share-w-value">{property.share_details.total_pending_user}</span>
                                    </div>
                                    <div className="col-6 text-center">
                                        <img src={require("../../images/icons/shares-tojoin.png")} alt="Shares To Join" />
                                        <span className="ml-2 ad-share-w-value">{property.share_details.total_pending_user_share}%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-12">
                            <Link to={'/property/' + property.property_details._id} 
                                className="btn btn-block apf-button interest-link">Join</Link>
                        </div>
                    </div>
                </div>
                <div className='horizontal-rule'></div>
            </div>
            </React.Fragment>
        );
    }
}

export default PropertyTimeOrProfitBased;