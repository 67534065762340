import React, { Component }                            from 'react';
import PropTypes                                       from 'prop-types';
import Img                                             from 'react-image';
import Select                                          from 'react-select';
import FontAwesome                                     from 'react-fontawesome';
import { FontAwesomeIcon }                             from '@fortawesome/react-fontawesome';
import { faCheck, faTimes }                            from '@fortawesome/free-solid-svg-icons';
import { faCalendar }                                  from '@fortawesome/free-regular-svg-icons';
import Slider                                          from 'react-styled-carousel-am';
import DayPicker, { DateUtils }                        from 'react-day-picker';
import { format }                                      from 'date-fns';
import jwt_decode                                      from 'jwt-decode';
import Modal                                           from 'react-bootstrap/Modal';
import ReactImageVideoLightbox                         from '../../../components/react-image-video-lightbox/index';
import API                                             from '../../../helpers/API';
import { toCurrency, toCustomDate, toDateForCalender } from '../../../helpers/Formatters';
import { getYoutubeEmbedURL }                          from '../../../helpers/StringUtil';

const modifiersStyles = {
    requested : {
        color           : '#FFF',
        backgroundColor : '#37B837'
    },
    rejected  : {
        color           : '#363636',
        backgroundColor : '#EFEFEF'
    },
    outside   : {
        color           : '#FFF',
        backgroundColor : '#FFF'
    }
};

class MyPropertyTimeOrProfitBased extends Component {
    constructor(props) {
        super(props);
        this.state = {
            propertyid            : this.props.property.property_details._id,
            partnershipid         : this.props.property.partnership_id,
            pvideoandimages       : [],
            cuserid               : '',
            cusername             : '',
            cusershareamt         : '',
            cuserperct            : '',
            cuserdays             : '',
            perctorprofit         : '',
            partners              : [],
            selpartner            : '',
            usercyclestep         : '',
            useravamonths         : [],
            useravadays           : '',
            useravadayss          : [],
            requests              : [],
            requesteddays         : [],
            dayrequests           : [],
            rejecteddays          : [],
            modifiers             : {},
            selecteddays          : [],
            requestedday          : '',
            requesteddayf         : '',
            respmsg               : '',
            showacceptrejectmodal : false,
            showdaysrequestmodal  : false,
            showapiresponsemodal  : false
        };

        this.handlePartnerChange      = this.handlePartnerChange.bind(this);
        this.handleDayClick           = this.handleDayClick.bind(this);
        this.toggleAcceptRejectModal  = this.toggleAcceptRejectModal.bind(this);
        this.toggleDaysRequestModal   = this.toggleDaysRequestModal.bind(this);
        this.toggleAPIResponseModal   = this.toggleAPIResponseModal.bind(this);
        this.acceptOrRejectRequest    = this.acceptOrRejectRequest.bind(this);
        this.requestDays              = this.requestDays.bind(this);
        this.relievePartnership       = this.relievePartnership.bind(this);
    }

    componentDidMount() {
        let accesstoken = localStorage.getItem('anshik-token');
        var decoded = jwt_decode(accesstoken);
        var cuserid = decoded.sub;

        const { property } = this.props;

        let pvideoandimages = [];
        let videourl = property.property_details.video_url;
        if (videourl !== '') {
            let embedurl = getYoutubeEmbedURL(videourl);
            pvideoandimages.push({
                url    : embedurl,
                type   : 'video',
                altTag : ''
            });
        }

        let images = property.property_details.image;
        images.forEach((image) => {
            pvideoandimages.push({
                url    : image,
                type   : 'photo',
                altTag : ''
            });
        });

        this.setState({
            cuserid         : cuserid,
            pvideoandimages : pvideoandimages
        });

        property.group_details.forEach((item) => {
            if (item.current_user) {
                this.setState({
                    cusername     : item.user_name,
                    cusershareamt : item.user_share_amount,
                    cuserperct    : item.user_share_percentage,
                    cuserdays     : item.user_share_days,
                });
            }
        });

        var catg = property.property_details.category.category;
        if (catg === 'Automotive') {
            let partners       = [];
            let selpartner     = {};
            let usercyclestep  = '';
            let useravamonths  = [];
            let useravadays    = '';
            let useravadayss   = [];
            let requesteddays  = [];
            let rejecteddays   = [];
            let modifiers      = {};

            property.group_user_name.forEach((guser, index) => {
                partners.push({
                    value : guser._id,
                    label : guser.user_name
                })

                if (guser.current_user) {
                    selpartner = { value : guser._id, label : guser.user_name};
                }
            });

            property.user_available_days.forEach((item) => {
                if (item.cycle_stages.trim() === 'ongoing') {
                    let months = item.user_available_months;
                    months.forEach((month) => {
                        useravamonths += month.substring(0, 3) + ', ';
                    });

                    let uavadays = item.days;
                    uavadays.forEach((uavaday) => {
                        useravadayss.push(toDateForCalender(uavaday));
                    });

                    usercyclestep = item.cycle_step;
                    useravamonths = useravamonths.substring(0, useravamonths.length - 2);
                    useravadays   = item.user_available_days_count;
                }
            });

            property.requested_days.forEach((reqday) => {
                requesteddays.push(toDateForCalender(reqday.day));
            });

            property.rejected_days.forEach((rejday) => {
                rejecteddays.push(toDateForCalender(rejday.day));
            });

            modifiers = {
                requested : requesteddays,
                rejected  : rejecteddays
            };

            this.setState({
                perctorprofit  : 'Percentage',
                partners       : partners,
                selpartner     : selpartner,
                usercyclestep  : usercyclestep,
                useravamonths  : useravamonths,
                useravadays    : useravadays,
                useravadayss   : useravadayss,
                requests       : property.requested_days,
                requesteddays  : requesteddays,
                rejecteddays   : rejecteddays,
                modifiers      : modifiers
            });
        } else if (catg === 'Real Estate' || catg === 'Business') {
            this.setState({ perctorprofit : 'Profit' });
        }
    }

    handlePartnerChange(newValue: any, actionMeta: any) {
        var id = newValue.value;

        this.setState({ selpartner : newValue });

        const { propertyid, cuserid, requesteddays, rejecteddays } = this.state;
        const accesstoken = localStorage.getItem('anshik-token');
        const requestHeaders = {
            headers : {
                'Authorization' : 'Bearer ' + accesstoken,
                'Content-Type'  : 'application/json'
            }
        };

        API
            .get('my-property-calendar_details/' + id + '/' + propertyid, requestHeaders)
            .then((response) => {
                let usercyclestep = '';
                let useravamonths = '';
                let useravadays   = '';
                let useravadayss  = [];
                let modifiers     = {};

                let responsedata = response.data.propertywithgroupandsharedetails;
                responsedata.user_available_days.forEach((item) => {
                    if (item.cycle_stages.trim() === 'ongoing') {
                        let months = item.user_available_months;
                        months.forEach((month) => {
                            useravamonths += month.substring(0, 3) + ', ';
                        });

                        let uavadays = item.days;
                        uavadays.forEach((uavaday) => {
                            useravadayss.push(toDateForCalender(uavaday));
                        });

                        usercyclestep = item.cycle_step;
                        useravamonths = useravamonths.substring(0, useravamonths.length - 2);
                        useravadays   = item.user_available_days_count;
                    }
                });

                if (id === cuserid) {
                    modifiers = {
                        requested : requesteddays,
                        rejected  : rejecteddays
                    };
                } else {
                    modifiers = {
                        requested : [],
                        rejected  : []
                    };
                }

                this.setState({
                    usercyclestep : usercyclestep,
                    useravamonths : useravamonths,
                    useravadays   : useravadays,
                    useravadayss  : useravadayss,
                    modifiers     : modifiers
                });
            })
            .catch((error) => {
                // console.log('AXIOS GET: Error: ');
                // console.log(error);
            });
    }

    handleDayClick(day, { selected }) {
        const { cuserid, selpartner, useravadayss, requests, requesteddays } = this.state;

        if (cuserid === selpartner.value) {
            var indexx = -1;
            var dayrequests = [];

            requesteddays.forEach((reqday, index) => {
                if (DateUtils.isSameDay(reqday, day)) {
                    indexx = index;
                    dayrequests.push(requests[index]);
                }
            });

            if (indexx > -1) {
                this.setState({ dayrequests : dayrequests });
                this.toggleAcceptRejectModal();
            }
        } else {
            const index = useravadayss.findIndex(uavaday => DateUtils.isSameDay(uavaday, day));
            if (index > -1) {
                this.setState({
                    requestedday  : day,
                    requesteddayf : format(day, 'dd MMM yyyy')
                });
                this.toggleDaysRequestModal();
            }
        }
    }

    toggleAcceptRejectModal() {
        this.setState(prevState => ({
            showacceptrejectmodal : !prevState.showacceptrejectmodal
        }));
    }

    toggleDaysRequestModal() {
        this.setState(prevState => ({
            showdaysrequestmodal : !prevState.showdaysrequestmodal
        }));
    }

    toggleAPIResponseModal() {
        this.setState(prevState => ({
            showapiresponsemodal : !prevState.showapiresponsemodal
        }));
    }

    relievePartnership() {
        const accesstoken = localStorage.getItem('anshik-token');
        const requestHeaders = {
            headers : {
                'Authorization' : 'Bearer ' + accesstoken,
                'Content-Type'  : 'application/json'
            }
        };

        const { partnershipid } = this.state;
        const requestParams = {
            'deal_id'     : partnershipid,
            'kissan_mode' : "0"
        };

        API
            .post('deals-relieving-requests', requestParams, requestHeaders)
            .then((response) => {
                this.setState({ respmsg : 'Relieve request successfully sent to Admin' });
                this.toggleAPIResponseModal();
            })
            .catch((error) => {
                // console.log('AXIOS POST: Error');
                // console.log(error);
            });
    }

    acceptOrRejectRequest(targeter, date, requeststatus) {
        const accesstoken = localStorage.getItem('anshik-token');
        const requestHeaders = {
            headers : {
                'Authorization' : 'Bearer ' + accesstoken,
                'Content-Type'  : 'application/json'
            }
        };

        const { propertyid, partnershipid } = this.state;
        const requestParams = {
            'property_id'    : propertyid,
            'partnership_id' : partnershipid,
            'targeter'       : targeter,
            'date'           : date,
            'request_status' : requeststatus
        };

        API
            .post('request-accept-or-reject', requestParams, requestHeaders)
            .then((response) => {
                var responsemsg = '';
                if (requeststatus === 'accepted') {
                    responsemsg = 'Accepted day request';
                } else {
                    responsemsg = 'Rejected day request';
                }

                this.setState({ respmsg : responsemsg });
                this.toggleAcceptRejectModal();
                this.toggleAPIResponseModal();
            })
            .catch((error) => {
                // console.log('AXIOS POST: Error');
                // console.log(error);
            });
    }

    requestDays() {
        const accesstoken = localStorage.getItem('anshik-token');
        const requestHeaders = {
            headers : {
                'Authorization' : 'Bearer ' + accesstoken,
                'Content-Type'  : 'application/json'
            }
        };

        const { propertyid, partnershipid, selpartner, usercyclestep, requestedday } = this.state;

        const requestParams = new FormData();
        requestParams.append('property_id', propertyid);
        requestParams.append('partnership_id', partnershipid);
        requestParams.append('targeter', selpartner.value);
        requestParams.append('cycle_step', usercyclestep);
        requestParams.append('day[]', format(requestedday, 'yyyy-MM-dd'));

        API
            .post('partner-day-request', requestParams, requestHeaders)
            .then((response) => {
                this.setState({ respmsg : 'Request sent successfully' });
                this.toggleDaysRequestModal();
                this.toggleAPIResponseModal();
            })
            .catch((error) => {
                // console.log('AXIOS POST: Error');
                // console.log(error);
            });
    }

    render() {
        const { imageloader, showdesc, showimagegallery, showimagevideomodal, property, adstatus } = this.props;
        const { pvideoandimages, cusername, cusershareamt, cuserperct, cuserdays, perctorprofit, partners, selpartner, 
            useravamonths, useravadays, useravadayss, modifiers, dayrequests, requesteddayf, respmsg, 
            showacceptrejectmodal, showdaysrequestmodal, showapiresponsemodal } = this.state;

        return(
            <React.Fragment>
                <section className="pg-g83h-ad-det-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="pg-g83h-ad-catg-img-wrapper">
                                    <Img src={property.property_details.category.image} alt="..." className="img-fluid" />
                                    {adstatus}
                                </div>

                                <div className="pg-g83h-ad-det-wrapper">
                                    <div className="row align-items-center">
                                        <div className="col-9 pg-g83h-ad-det-col1">
                                            <p className="pg-g83h-ad-category">{property.property_details.category.category}</p>
                                            <p className="pg-g83h-ad-title">{property.property_details.title}</p>

                                            <div className="row pg-g83h-lu-row">
                                                <div className="col-12">
                                                    <span>
                                                        <img src={require("../../../images/icons/ad-location.png")} alt="Location Icon" />
                                                        <span className="ml-2 pg-g83h-ad-location">{property.property_details.location}</span>
                                                    </span>
                                                    <span className="ml-3">
                                                        <img src={require("../../../images/icons/ad-by.png")} alt="User Icon" />
                                                        <span className="ml-2 pg-g83h-ad-by">{property.property_details.user_name}</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-3">
                                            <div className="pg-g83h-ad-tprice-wrapper">
                                                <p className="pg-g83h-ad-tprice-label">Total Price</p>
                                                <p className="pg-g83h-ad-tprice-value">
                                                    <FontAwesome name="rupee" /> {toCurrency(property.property_details.price)}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row pg-g83h-ad-toggle-controls-row">
                                        <div className="col-12">
                                            <span className="pg-g83h-sh-link" 
                                                onClick={this.props.toggleDesc}>{ showdesc ? 'Hide details' : 'View details' }</span>
                                            <span className="ml-3 pg-g83h-sh-link" 
                                                onClick={this.props.toggleImageGallery}>{ showimagegallery ? 'Hide gallery' : 'View gallery' }</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="pg-g83h-descadimgs-wrapper">
                    <section className={"pg-g83h-desc-section" + (showdesc ? '' : ' d-none')}>
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="row pg-g83h-sect-heading-row">
                                        <div className="col-12">
                                            <span className="pg-g83h-section-heading">Description</span>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12">
                                            <p className="pg-g83h-desc">{property.property_details.description}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className={"pg-g83h-ad-imgs-section" + (showimagegallery ? '' : ' d-none')}>
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <Slider autoSlide={false} showDots={false} hideArrowsOnNoSlides={false} padding={'0px 20px'} cardsToShow={4}>
                                        <div className="pg-g83h-ad-video-wrapper" onClick={this.props.toggleImageVideoModal}>
                                            <Img src={property.property_details.image[0]} alt="..." className="img-fluid" loader={imageloader} unloader={imageloader} />
                                            <div className="pg-g83h-video-play-icon"></div>
                                        </div>
                                        {
                                            property.property_details.image.map((pimage, index) => {
                                                return(
                                                    <div key={'pimage' + index} className="pg-g83h-ad-img-wrapper" onClick={this.props.toggleImageVideoModal}>
                                                        <Img src={pimage} alt="..." className="img-fluid" loader={imageloader} unloader={imageloader} />
                                                    </div>
                                                );
                                            })
                                        }
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <section className="pg-g83h-grp-det-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="row pg-g83h-sect-heading-row">
                                    <div className="col-12">
                                        <span className="pg-g83h-section-heading">Group Details</span>
                                    </div>
                                </div>

                                {
                                    (property.partnership_id !== null) ? (
                                        <React.Fragment>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="pg-g83h-currentuser-row">
                                                        <div className="row h-100 align-items-center">
                                                            <div className="col-3">
                                                                <div className="pg-g83h-cuser-wrapper">
                                                                    <Img src={require("../../../images/icons/pg-g83h-rs-cuser.png")} alt="CUser Icon" />
                                                                    <span className="pg-g83h-currentuser-text">{cusername}</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-9">
                                                                <div className="row align-items-center">
                                                                    <div className="col-4">
                                                                        <div className="pg-g83h-icon-wrapper">
                                                                            <Img src={require("../../../images/icons/pg-g83h-rs-amt.png")} alt="CUser Share" />
                                                                        </div>
                                                                        <div className="pg-g83h-lv-wrapper">
                                                                            <p className="pg-g83h-currentuser-label">Share Amount</p>
                                                                            <p className="pg-g83h-currentuser-value">
                                                                                <FontAwesome name="rupee" /> {toCurrency(cusershareamt)}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <div className="pg-g83h-icon-wrapper">
                                                                            <Img src={require("../../../images/icons/pg-g83h-rs-perct.png")} alt="CUser Perct" />
                                                                        </div>
                                                                        <div className="pg-g83h-lv-wrapper">
                                                                            <p className="pg-g83h-currentuser-label">{perctorprofit}</p>
                                                                            <p className="pg-g83h-currentuser-value">{cuserperct}%</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-2">
                                                                    {
                                                                        (property.property_details.category.category === "Automotive") && 
                                                                        <React.Fragment>
                                                                            <div className="pg-g83h-icon-wrapper">
                                                                                <Img src={require("../../../images/icons/pg-g83h-rs-days.png")} alt="CUser Days" />
                                                                            </div>
                                                                            <div className="pg-g83h-lv-wrapper">
                                                                                <p className="pg-g83h-currentuser-label">Days</p>
                                                                                <p className="pg-g83h-currentuser-value">{cuserdays}</p>
                                                                            </div>
                                                                        </React.Fragment>
                                                                    }
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <button className="btn float-right pg-g83h-relieve-btn" 
                                                                            onClick={() => { this.relievePartnership() }}>Relieve</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row pg-g83h-users-row">
                                            {
                                                property.group_details.map((guser, index) => {
                                                    return(
                                                        <React.Fragment key={'guser' + index}>
                                                        {
                                                            !guser.current_user &&
                                                            <div className="col-3">
                                                                <div className="pg-g83h-user-det-wrapper">
                                                                    <div className="pg-g83h-guser-status-icons">
                                                                    {
                                                                        (guser.holder_status === "1") &&
                                                                        <div className="pg-g83h-guser-holdericon"></div>
                                                                    }
                                                                    {
                                                                        (guser.user_type === "1") &&
                                                                        <div className="pg-g83h-guser-genuineusericon"></div>
                                                                    }
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-12 text-center">
                                                                            <Img src={require("../../../images/icons/pg-g83h-group-user.png")} alt="GUser Icon" />
                                                                        </div>
                                                                        <div className="col-12">
                                                                            <div className="pg-g83h-guser-name">{guser.user_name}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row pg-g83h-guser-spec-row">
                                                                        <div className="col-12">
                                                                            <div className="pg-a67d-icon-wrapper">
                                                                                <img src={require("../../../images/icons/pg-a67d-amount.png")} alt="SAmt Icon" />
                                                                            </div>
                                                                            <div className="pg-a67d-lv-wrapper">
                                                                                <p className="pg-a67d-oshare-label">Share Amount</p>
                                                                                <p className="pg-a67d-oshare-value">
                                                                                    <FontAwesome name="rupee" /> {toCurrency(guser.user_share_amount)}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row pg-g83h-guser-spec-row">
                                                                        <div className="col-12">
                                                                            <div className="pg-a67d-icon-wrapper">
                                                                                <img src={require("../../../images/icons/pg-a67d-share.png")} alt="Perc Icon" />
                                                                            </div>
                                                                            <div className="pg-a67d-lv-wrapper">
                                                                                <p className="pg-a67d-oshare-label">{perctorprofit}</p>
                                                                                <p className="pg-a67d-oshare-value">{guser.user_share_percentage}%</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        (property.property_details.category.category === "Automotive") && 
                                                                        <div className="row pg-g83h-guser-spec-row">
                                                                            <div className="col-12">
                                                                                <div className="pg-a67d-icon-wrapper">
                                                                                    <img src={require("../../../images/icons/pg-a67d-days.png")} alt="Days Icon" />
                                                                                </div>
                                                                                <div className="pg-a67d-lv-wrapper">
                                                                                    <p className="pg-a67d-oshare-label">Days</p>
                                                                                    <p className="pg-a67d-oshare-value">{guser.user_share_days}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        }
                                                        </React.Fragment>
                                                    );
                                                })
                                            }
                                            </div>
                                        </React.Fragment>
                                    ) : (
                                        <div className="row pg-g83h-nogroupdet-row">
                                            <div className="col-12 pg-g83h-nogroupdet-msg">Group details will be available after a Partnership is formed</div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </section>

                {
                    (property.property_details.category.category === "Automotive" && property.partnership_id !== null) && 
                    <section className="pg-g83h-daypicker-section" id="pgg83hdaypicker">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <span className="pg-g83h-section-heading-history">History</span>
                                </div>
                            </div>
                            <div className="row no-gutters pg-g83h-tcycle-row">
                            {
                                property.days_cycle.map((tcycle, index) => {
                                    return(
                                        <div key={'tcycle' + index} className="col-4 pg-g83h-tcycle-col">
                                            <div className={"pg-g83h-tcycle" + (tcycle.cycle_stages === 'ongoing' ? ' pg-g83h-tcycle-active' : '')}>
                                                <span className="pg-g83h-tcycle-name">{tcycle.total_days_per_cycle} days cycle</span>
                                                <span className="pg-g83h-tcycle-duration">
                                                    {toCustomDate(tcycle.starting_date)} - {toCustomDate(tcycle.ending_date)}</span>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                            </div>

                            <div className="row pg-g83h-sect-heading-row pg-g83h-cal-heading-row">
                                <div className="col-12">
                                    <span className="pg-g83h-section-heading">Calendar</span>
                                </div>
                            </div>
                            <div className="row align-items-center">
                                <div className="col-3">
                                {
                                    (selpartner !== '') &&
                                    <Select
                                        value={selpartner}
                                        options={partners}
                                        isSearchable={false}
                                        classNamePrefix="pg-g83h-pselect"
                                        components={{ IndicatorSeparator : null }}
                                        onChange={this.handlePartnerChange} />
                                }
                                </div>
                                <div className="col-3"></div>
                                <div className="col-6 pg-g83h-partner-ava-lv">
                                    <span className="pg-g83h-partner-ava-label">Available Days : </span>
                                    <span className="pg-g83h-partner-ava-value">{useravadays}</span>
                                    <span className="ml-3 pg-g83h-partner-ava-label">Available Months : </span>
                                    <span className="pg-g83h-partner-ava-value">{useravamonths}</span>
                                </div>
                            </div>

                            <div className="row pg-g83h-cal-row">
                                <div className="col-12">
                                    <DayPicker
                                        initialMonth={new Date()}
                                        selectedDays={useravadayss}
                                        numberOfMonths={3}
                                        pagedNavigation
                                        modifiers={modifiers}
                                        modifiersStyles={modifiersStyles}
                                        onDayClick={this.handleDayClick} />
                                </div>
                            </div>
                        </div>
                    </section>
                }

                <section className={"pg-g83h-docs-section" + (property.property_details.category.category === 'Automotive' ? '' : ' pg-g83h-docs-section-alt')}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="row pg-g83h-sect-heading-row">
                                    <div className="col-12">
                                        <span className="pg-g83h-section-heading">Documents</span>
                                    </div>
                                </div>

                                <div className="row">
                                {
                                    property.user_document.map((udoc, index) => {
                                        return(
                                            <div key={'udoc' + index} className="col-4 pg-g83h-prop-doc-col">
                                                <a href={udoc.document_link} target="_blank" rel="noopener noreferrer" className="pg-g83h-prop-doc">{udoc.document_name}</a>
                                            </div>
                                        );
                                    })
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {
                    showimagevideomodal &&
                    <ReactImageVideoLightbox
                        data={pvideoandimages}
                        startIndex={0}
                        showResourceCount={false}
                        onCloseCallback={this.props.toggleImageVideoModal} />
                }

                <Modal show={showacceptrejectmodal} onHide={this.toggleAcceptRejectModal} size="sm" aria-labelledby="modal-acceptreject" centered>
                    <Modal.Header closeButton>
                        <Modal.Title id="modal-acceptreject"></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    {
                        dayrequests.map((dayrequest, index) => {
                            return (
                                <div key={'dayrequest' + index} className="row align-items-center pg-g83h-req-user-row">
                                    <div className="col-6">{dayrequest.initiator_name}</div>
                                    <div className="col-6">
                                        <div className="row">
                                            <div className="col-6 text-center pg-g83h-req-uact-row"
                                                onClick={() => {this.acceptOrRejectRequest(dayrequest.initiator_id, dayrequest.day, 'accepted')}}>
                                                <FontAwesomeIcon icon={faCheck} /><br />
                                                <span className="pg-g83h-aorr-text">Accept</span>
                                            </div>
                                            <div className="col-6 text-center pg-g83h-req-uact-row"
                                                onClick={() => {this.acceptOrRejectRequest(dayrequest.initiator_id, dayrequest.day, 'rejected')}}>
                                                <FontAwesomeIcon icon={faTimes} /><br />
                                                <span className="pg-g83h-aorr-text">Reject</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                    </Modal.Body>
                </Modal>

                <Modal show={showdaysrequestmodal} onHide={this.toggleDaysRequestModal} size="sm" aria-labelledby="modal-daysrequest" centered>
                    <Modal.Header closeButton>
                        <Modal.Title id="modal-daysrequest"></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="pg-xxxx-mform-group">
                            <label htmlFor="requestedday" className="pg-g83h-modal-form-label">Request</label>
                            <div className="input-group pg-g83h-reqday-igroup">
                                <input type="text" id="requestedday" className="form-control pg-g83h-textbox-reqday"
                                    value={requesteddayf} disabled="disabled" />
                                <div className="input-group-append">
                                    <span className="input-group-text">
                                        <FontAwesomeIcon icon={faCalendar} />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <button className="mt-4 btn btn-block btn-signin" onClick={this.requestDays}>Request</button>
                    </Modal.Body>
                </Modal>

                <Modal show={showapiresponsemodal} onHide={this.toggleAPIResponseModal} size="sm" aria-labelledby="modal-apiresponse" centered>
                    <Modal.Header closeButton>
                        <Modal.Title id="modal-apiresponse"></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="text-center">
                            <Img src={require("../../../images/icons/success.png")} className="img-fluid" alt="Success Icon" />
                        </div>

                        <div className="form-group mt-4 text-center">
                            <label className="d-block otpveri-message">{respmsg}</label>
                        </div>
                        <button className="mt-5 d-flex mx-auto btn btn-signin btn-done" onClick={this.toggleAPIResponseModal}>Done</button>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}

MyPropertyTimeOrProfitBased.protoTypes = {
    toggleDesc            : PropTypes.func,
    toggleImageGallery    : PropTypes.func,
    toggleImageVideoModal : PropTypes.func
};

export default MyPropertyTimeOrProfitBased;