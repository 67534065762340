import React, { Component } from 'react';
import FacebookLogin        from 'react-facebook-login/dist/facebook-login-render-props';
import PropTypes            from 'prop-types';
import API                  from '../../helpers/API';

class FacebookOAuth extends Component {
    constructor(props) {
        super(props);
        this.responseFacebook = this.responseFacebook.bind(this);
    }

    responseFacebook(response) {
        console.log('Facebook Response: ');
        console.log(response);

        if (response.status !== "unknown") {
            const socialapiresponse = {
                provider : 'facebook',
                token    : response.accessToken
            };

            console.log('AXIOS POST: Facebook Social Authentication');

            API
                .post('OAuth', socialapiresponse)
                .then((response) => {
                    console.log('AXIOS POST: Response: ');
                    console.log(response);

                    localStorage.setItem('anshik-token', response.data.accesstoken);

                    this.props.setModalState('isloggedin', true);
                    this.props.setModalState('showsigninmodal', false);
                });
        }
    }

    render() {
        return (
            <FacebookLogin
                appId="221619678934367"
                callback={this.responseFacebook}
                render={(renderProps) => (
                    <img src={require("../../images/icons/social1-facebook.png")} alt="Facebook Signin Link" 
                        className="mr-2 float-right pg-xxxx-social-btn" onClick={renderProps.onClick} />
                )}
            />
        );
    }
}

FacebookOAuth.protoTypes = {
    setModalState : PropTypes.func
};

export default FacebookOAuth;