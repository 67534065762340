import React, { Component } from 'react';
import FontAwesome          from 'react-fontawesome';
import Slider               from 'react-rangeslider';
import                           './RangeSlider.css';
import PropTypes            from 'prop-types';
import { numregex }         from '../../helpers/Constants';
import { toCurrency }       from '../../helpers/Formatters';

class FormWizardStepThree extends Component {
    constructor(props) {
        super(props);
        this.state = {
            totalamount   : this.props.productprice,
            totaldays     : 90,
            totalshare    : 100,
            useramount    : this.props.useramount,
            userdays      : this.props.userdays,
            usershare     : this.props.usershare,
            balanceamount : this.props.balanceamount,
            balancedays   : this.props.balancedays,
            balanceshare  : this.props.balanceshare
        };

        this.updateStateValues      = this.updateStateValues.bind(this);
        this.handleUserAmountChange = this.handleUserAmountChange.bind(this);
        this.handleUserDaysChange   = this.handleUserDaysChange.bind(this);
        this.handleUserShareChange  = this.handleUserShareChange.bind(this);
    }
       
    updateStateValues(totalamount, totaldays, totalshare, useramount, userdays, usershare) {
        let balanceamount = totalamount - useramount;
        let balancedays   = totaldays - userdays;
        let balanceshare  = totalshare - usershare;

        this.setState({
            useramount    : useramount,
            userdays      : userdays,
            usershare     : usershare,
            balanceamount : balanceamount,
            balancedays   : balancedays,
            balanceshare  : balanceshare
        });

        this.props.updateAddPostFormState('useramount', useramount);
        this.props.updateAddPostFormState('userdays', userdays);
        this.props.updateAddPostFormState('usershare', usershare);
        this.props.updateAddPostFormState('balanceamount', balanceamount);
        this.props.updateAddPostFormState('balancedays', balancedays);
        this.props.updateAddPostFormState('balanceshare', balanceshare);
    }

    handleUserAmountChange(e) {
        let useramount = e.target.value.replace(/,/g, '');

        if (useramount === '' || numregex.test(useramount)) {
            const { totalamount, totaldays, totalshare } = this.state;

            let usershare     = (useramount / totalamount);
            let usershareperc = usershare * 100;
            let userdays      = totaldays * usershare;

            this.updateStateValues(totalamount, totaldays, totalshare, useramount, userdays, usershareperc);
        }
    }
    handleUserDaysChange(e) {
        let userdays = e.target.value;

        if (userdays === '' || numregex.test(userdays)) {
            const { totalamount, totaldays, totalshare } = this.state;

            let usershare     = (userdays / totaldays);
            let usershareperc = usershare * 100;
            let useramount    = totalamount * usershare;

            this.updateStateValues(totalamount, totaldays, totalshare, useramount, userdays, usershareperc);
        }
    }
    handleUserShareChange(value) {
        let usershareperc = value;

        const { totalamount, totaldays, totalshare } = this.state;

        let usershare  = usershareperc / 100;
        let useramount = totalamount * usershare;
        let userdays   = totaldays * usershare;

        this.updateStateValues(totalamount, totaldays, totalshare, useramount, userdays, usershareperc);
    }

    render() {
        const { category } = this.props;
        const { totalamount, totaldays, totalshare, useramount, userdays, usershare, 
            balanceamount, balancedays, balanceshare } = this.state;
            const roundTotalamount = Math.round(totalamount);
            const roundTotaldays = Math.round(totaldays);
            const roundTotalshare = Math.round(totalshare);
            const roundUseramount =Math.round(useramount);
            const roundUserdays = Math.round(userdays);
            const roundUsershare = Math.round(usershare);
            const roundBalanceamount = Math.round(balanceamount);
            const roundBalancedays =Math.round(balancedays);
            const roundBalanceshare =Math.round(balanceshare);
        return (
            <React.Fragment>
                <div className="row add-post-heading-row">
                    <div className="col-12">
                        <span className="add-post-heading">Share Details</span>
                    </div>
                </div>

                <div className="row add-post-form">
                    <div className="col-12">
                        <div className="row no-gutters form-group apf-bg-grey apf-step3-row1">
                            <div className="col-6">
                                <div className="row">
                                    <div className="col-12 apf-step3-row1-label">Total Price</div>
                                </div>
                                <div className="row">
                                    <div className="col-12 apf-step3-row1-value">
                                        <FontAwesome name="rupee" /> {toCurrency(totalamount)}
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                            {
                                (category.value === "5e6612586d4437782916dd24") ? (
                                    <React.Fragment>
                                        <div className="row">
                                            <div className="col-12 apf-step3-row1-label text-right">Total Days</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 apf-step3-row1-value text-right">{roundTotaldays} Days Cycle</div>
                                        </div>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <div className="row">
                                            <div className="col-12 apf-step3-row1-label text-right">Total Profit</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 apf-step3-row1-value text-right">{roundTotalshare}%</div>
                                        </div>
                                    </React.Fragment>
                                )
                            }
                            </div>
                        </div>

                        <div className="row form-group apf-step3-row2">
                            <div className="col-12 col-sm-4">
                                <div className="apf-bg-grey">
                                    <label htmlFor="userAmount" className="d-block control-label add-post-form-label">Your Share</label>
                                    <div className="input-group apf-input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text"><FontAwesome name="rupee" /></span>
                                        </div>
                                        <input type="text" id="userAmount" className="form-control apf-textbox apf-textbox-step3"
                                            value={roundUseramount === '' ? '' : toCurrency(roundUseramount)} onChange={this.handleUserAmountChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-4 mt-4 mt-md-0">
                                <div className="apf-bg-grey">
                                {
                                    (category.value === "5e6612586d4437782916dd24") ? (
                                        <React.Fragment>
                                            <label htmlFor="userDays" className="d-block control-label add-post-form-label">Your Days</label>
                                            <input type="text" id="userDays" className="form-control apf-textbox apf-textbox-step3"
                                                value={roundUserdays} onChange={this.handleUserDaysChange} />
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <label htmlFor="userProfit" className="d-block control-label add-post-form-label">Your Profit</label>
                                            <div className="input-group pg-6ef4-mshare-igroup">
                                                <input type="text" id="userProfit" className="form-control pg-6ef4-mshare-textbox-yield"
                                                    value={roundUsershare} onChange={this.handleUserShareChange} />
                                                <div className="input-group-append">
                                                    <span className="input-group-text pg-6ef4-mshare-igt-yield">%</span>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )
                                }
                                </div>
                            </div>
                            <div className="col-12 col-sm-4 mt-4 mt-md-0">
                                <div className="apf-bg-grey">
                                    <label className="d-block control-label add-post-form-label">Balance</label>
                                    <label id="balanceShare" className="control-label add-post-form-label apf-step3-balance-value">
                                        <span><FontAwesome name="rupee" /> {toCurrency(roundBalanceamount)}</span>
                                        {
                                            (category.value === "5e6612586d4437782916dd24") ? (
                                                <span className="apf-step3-balance-days">{roundBalancedays} days</span>
                                            ) : (
                                                <span className="apf-step3-balance-days">{roundBalanceshare}% Profit</span>
                                            )
                                        }
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="row form-group">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-6">
                                        <span className="w-100 rangesilder-values rangeslider-current">{usershare}%</span>
                                    </div>
                                    <div className="col-6 text-right">
                                        <span className="w-100 rangesilder-values rangeslider-balance">{balanceshare}%</span>
                                    </div>
                                </div>
                                <Slider
                                    min={0}
                                    max={totalshare}
                                    value={usershare}
                                    onChange={this.handleUserShareChange} />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

FormWizardStepThree.protoTypes = {
    updateAddPostFormState : PropTypes.func
};

export default FormWizardStepThree;