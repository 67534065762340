import firebase from 'firebase/app';
import               'firebase/messaging';
import               'firebase/firestore';

const firebaseConfig = {
    apiKey            : "AIzaSyCCmF2Duz7rjZXUi9J2_Xv4p8e42pxgnSo",
    authDomain        : "aanshik-896a3.firebaseapp.com",
    databaseURL       : "https://aanshik-896a3.firebaseio.com",
    projectId         : "aanshik-896a3",
    storageBucket     : "aanshik-896a3.appspot.com",
    messagingSenderId : "996599650254",
    appId             : "1:996599650254:web:720d6c5cfadd2c5e167d9f",
    measurementId     : "G-LFEL6XBQST"
};

firebase.initializeApp(firebaseConfig);

const firestore = firebase.firestore();

const firebasemessaging = firebase.messaging();
const askUserPermission = async () => {
    try {
        await firebasemessaging.requestPermission();

        const token = await firebasemessaging.getToken();
        localStorage.setItem('anshik-fcmtoken', token);
        return token;
    } catch(error) {
        console.error('Error in generating FCM Token');
        console.error(error);
    }
}

export { firebasemessaging, askUserPermission, firestore, firebase };