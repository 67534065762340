import React, { Component } from 'react';
import { Link }             from 'react-router-dom';
import Masonry              from 'react-masonry-css';
import API                  from '../../helpers/API';

const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1
  };
class AllCategories extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sectiondata : []
        };
    }

    componentDidMount() {
        API
            .get('property-with-subcategory-count')
            .then((response) => {
                this.setState({ sectiondata : response.data.propertywithsubcategorycount });
            });
    }

    render() {
        const { location } = this.props;
        const { sectiondata } = this.state;

        const allcategories = sectiondata.map((category) => {
           return (
                <div className="card mb-4 category-card" key={category.category_id}>
                    <div className="card-body">
                        <Link to={'/properties/category/' + location.value + '/' + category.category_id + '/0'} className="category-title-link">
                            <h4 className="card-title mb-4 category-title">{category.category}</h4>
                        </Link>
                        <ul className="card-text subcategory-list">
                        {
                            category.subcategory.map((subcategory) => {
                                return (
                                    <li key={subcategory.subcategory_id}>
                                        <Link to={'/properties/category/' + location.value + '/' + category.category_id 
                                            + '/' + subcategory.subcategory_id + '/' + subcategory.sub} 
                                            className="subcategory-item">
                                            {subcategory.sub} ({subcategory.count})
                                        </Link>
                                    </li>
                                );
                            })
                        }
                        </ul>
                    </div>
                </div>
            );
        });

        return(
            <section id="all-categories" className="alternate-section">
                <div className="container">
                    <div className="row">
                        <div className="col pt-5 pb-5 text-center ads-section-heading">All Categories</div>
                    </div>

                    <Masonry
                        breakpointCols={breakpointColumnsObj}
                        className="all-categories-masonry-grid"
                        columnClassName="all-categories-masonry-grid-column">
                        { allcategories }
                    </Masonry>
                </div>
            </section>
        );
    }
}

export default AllCategories;