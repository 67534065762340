import                                './Profile.css';
import React, { Component }      from 'react';
import Img                       from 'react-image';
import Modal                     from 'react-bootstrap/Modal';
import jwt_decode                from 'jwt-decode';
import API                       from '../../../helpers/API';
import UCommon                   from '../common/UCommon';

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            locations            : [],
            user                 : null,
            alternativeemail     : [],
            alternativemobile    : [],
            alternativelocation  : [],
            newemail             : '',
            newemailerror        : '',
            newphoneno           : '',
            newphonenoerror      : '',
            newloc               : '',
            newlocerror          : '',
            curpassword          : '',
            newpassword          : '',
            pwdchangeerror       : '',
            respmsg              : '',
            showaddemailmodal    : false,
            showaddphonenomodal  : false,
            showplocchangemodal  : false,
            showpwdchangemodal   : false,
            showapiresponsemodal : false
        };

        this.loadLocations              = this.loadLocations.bind(this);
        this.loadProfile                = this.loadProfile.bind(this);
        this.handleFormTextboxChange    = this.handleFormTextboxChange.bind(this);
        this.toggleAddEmailModal        = this.toggleAddEmailModal.bind(this);
        this.toggleAddPhoneNoModal      = this.toggleAddPhoneNoModal.bind(this);
        this.togglePLocationChangeModal = this.togglePLocationChangeModal.bind(this);
        this.togglePasswordChangeModal  = this.togglePasswordChangeModal.bind(this);
        this.toggleAPIResponseModal     = this.toggleAPIResponseModal.bind(this);
        this.addNewEmail                = this.addNewEmail.bind(this);
        this.addNewPhoneNo              = this.addNewPhoneNo.bind(this);
        this.changePrimaryLocation      = this.changePrimaryLocation.bind(this);
        this.updateProfile              = this.updateProfile.bind(this);
        this.updatePassword             = this.updatePassword.bind(this);
    }

    componentDidMount() {
        this.loadLocations();
        this.loadProfile();
    }

    loadLocations() {
        let locations = [];

        API
            .get('location')
            .then((response) => {
                let responsedata = response.data.location;
                responsedata.forEach((loc) => 
                    locations.push({
                        id    : loc._id,
                        title : loc.location
                    })
                );
                this.setState({ locations : locations });
            });
    }

    loadProfile() {
        let accesstoken = localStorage.getItem('anshik-token');
        var decoded = jwt_decode(accesstoken);
        var userid = decoded.sub;

        const requestHeaders = {
            headers : {
                'Authorization' : 'Bearer ' + accesstoken,
                'Content-Type'  : 'application/json'
            }
        };

        API
            .get('user/' + userid, requestHeaders)
            .then((response) => {
                let responsedata = response.data.user;
                this.setState({
                    user                : responsedata,
                    alternativeemail    : responsedata.alternate_email,
                    alternativemobile   : responsedata.alternate_mobile,
                    alternativelocation : responsedata.alternate_location
                });
            })
            .catch((error) => {
                // console.log('AXIOS GET: Error: ');
                // console.log(error);
            });
    }

    handleFormTextboxChange(e) {
        const target = e.target;
        const key    = target.id;
        const value  = target.value;
        this.setState({ [key] : value });
    }

    toggleAddEmailModal() {
        this.setState(prevState => ({
            showaddemailmodal : !prevState.showaddemailmodal
        }));
        this.setState({
            newemail      : '',
            newemailerror : ''
        });
    }

    toggleAddPhoneNoModal() {
        this.setState(prevState => ({
            showaddphonenomodal : !prevState.showaddphonenomodal
        }));
        this.setState({
            newphoneno      : '',
            newphonenoerror : ''
        });
    }

    togglePLocationChangeModal() {
        this.setState(prevState => ({
            showplocchangemodal : !prevState.showplocchangemodal
        }));
        this.setState({ newlocerror : '' });
    }

    togglePasswordChangeModal() {
        this.setState(prevState => ({
            showpwdchangemodal : !prevState.showpwdchangemodal
        }));
        this.setState({
            curpassword    : '',
            newpassword    : '',
            pwdchangeerror : ''
        });
    }

    toggleAPIResponseModal() {
        this.setState(prevState => ({
            showapiresponsemodal : !prevState.showapiresponsemodal
        }));
    }

    addNewEmail() {
        this.setState({ newemailerror : '' });

        const { alternativeemail, alternativemobile, alternativelocation, newemail } = this.state;

        if (newemail !== '') {
            let alternateemailarr = alternativeemail;
            alternateemailarr.push(newemail);

            const requestParams = new FormData();
            alternateemailarr.forEach((altemail) => {
                requestParams.append('alternative_email[]', altemail);
            });
            alternativemobile.forEach((altmobile) => {
                requestParams.append('alternative_mobile[]', altmobile);
            });
            alternativelocation.forEach((altloc) => {
                requestParams.append('alternative_location[]', altloc);
            });

            this.updateProfile(alternateemailarr, requestParams, 'email');
        } else {
            this.setState({ newemailerror : 'Please provide an Email ID' });
        }
    }

    addNewPhoneNo() {
        this.setState({ newphonenoerror : '' });

        const { alternativeemail, alternativemobile, alternativelocation, newphoneno } = this.state;

        if (newphoneno !== '') {
            let alternatemobilearr = alternativemobile;
            alternatemobilearr.push(newphoneno);

            const requestParams = new FormData();
            alternativeemail.forEach((altemail) => {
                requestParams.append('alternative_email[]', altemail);
            });
            alternatemobilearr.forEach((altmobile) => {
                requestParams.append('alternative_mobile[]', altmobile);
            });
            alternativelocation.forEach((altloc) => {
                requestParams.append('alternative_location[]', altloc);
            });

            this.updateProfile(alternatemobilearr, requestParams, 'phoneno');
        } else {
            this.setState({ newphonenoerror : 'Please provide a Contact No' });
        }
    }

    changePrimaryLocation() {
        this.setState({ newlocerror : '' });

        const { alternativeemail, alternativemobile, alternativelocation, newloc } = this.state;

        if (newloc !== '') {
            var alternatelocationarr = alternativelocation;
            alternatelocationarr.push(newloc);

            const requestParams = new FormData();
            alternativeemail.forEach((altemail) => {
                requestParams.append('alternative_email[]', altemail);
            });
            alternativemobile.forEach((altmobile) => {
                requestParams.append('alternative_mobile[]', altmobile);
            });
            alternatelocationarr.forEach((altloc) => {
                requestParams.append('alternative_location[]', altloc);
            });

            this.updateProfile(alternatelocationarr, requestParams, 'plocation');
        } else {
            this.setState({ newlocerror : 'Please select a Location' });
        }
    }

    updateProfile(dataarr, requestParams, updateType) {
        const accesstoken = localStorage.getItem('anshik-token');
        const requestheaders = {
            headers : {
                'Authorization' : 'Bearer ' + accesstoken,
                'Content-Type'  : 'application/json'
            }
        };

        API
            .post('profile-update', requestParams, requestheaders)
            .then((response) => {
                if (updateType === 'email') {
                    this.setState({
                        respmsg          : 'Successfully updated profile details',
                        newemail         : '',
                        alternativeemail : dataarr
                    });
                    this.toggleAddEmailModal();
                } else if (updateType === 'phoneno') {
                    this.setState({
                        respmsg           : 'Successfully updated profile details',
                        newphoneno        : '',
                        alternativemobile : dataarr
                    });
                    this.toggleAddPhoneNoModal();
                } else if (updateType === 'plocation') {
                    this.setState({
                        respmsg             : 'Successfully updated profile details',
                        alternativelocation : dataarr
                    });
                    this.togglePLocationChangeModal();
                }

                this.toggleAPIResponseModal();
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    if (updateType === 'email') {
                        this.setState({ newemailerror : error.response.data.message });
                    } else if (updateType === 'phoneno') {
                        this.setState({ newphonenoerror : error.response.data.message });
                    }
                }
            });
    }

    updatePassword() {
        this.setState({ pwdchangeerror : '' });

        const accesstoken = localStorage.getItem('anshik-token');
        const requestHeaders = {
            headers : {
                'Authorization' : 'Bearer ' + accesstoken,
                'Content-Type'  : 'application/json'
            }
        };

        const { curpassword, newpassword } = this.state;
        const requestParams = {
            'current_password' : curpassword,
            'new_password'     : newpassword
        };

        API
            .post('profile-password-update', requestParams, requestHeaders)
            .then((response) => {
                this.setState({ respmsg : 'Successfully updated account password' });
                this.togglePasswordChangeModal();
                this.toggleAPIResponseModal();

                // TODO: FORCE LOGOUT WITH APPROPRIATE MSG
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    this.setState({ pwdchangeerror : error.response.data.message });
                }
            });
    }

    render() {
        const {
            locations, user, alternativeemail, alternativemobile, newemail, newemailerror, newphoneno, 
            newphonenoerror, newlocerror, curpassword, newpassword, pwdchangeerror, respmsg, showaddemailmodal, 
            showaddphonenomodal, showplocchangemodal, showpwdchangemodal, showapiresponsemodal } = this.state;

        return(
            <React.Fragment>
                <UCommon />

                {
                    user !== null &&
                    <section className="pg-1mw0-uprofile-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 offset-lg-3">
                                    <div className="row text-center">
                                        <div className="col-lg-12">
                                            <Img src={require("../../../images/icons/pg-1mw0-user-icon.png")} alt="User Icon" />
                                        </div>
                                        <div className="col-lg-12 pg-1mw0-uname-col">
                                            <span className="pg-1mw0-uname">{user.name}</span>
                                        </div>
                                    </div>

                                    <div className="row pg-1mw0-up-value-row">
                                        <div className="col-lg-12 pg-1mw0-up-value-col">
                                            <div className="pg-1mw0-up-value-icon">
                                                <Img src={require("../../../images/icons/pg-1mw0-email-icon.png")} alt="Email Icon" />
                                            </div>
                                            <div className="pg-1mw0-up-value-wrapper">
                                                <div className="row">
                                                    <div className="col-lg-10">
                                                        <span className="d-block pg-1mw0-up-label">Email</span>
                                                        <span className="d-block pg-1mw0-up-value">{user.email} (P)</span>
                                                        {
                                                            alternativeemail.map((email, index) => {
                                                                return (<span key={'email' + index} className="d-block pg-1mw0-up-value-alt">{email}</span>);
                                                            })
                                                        }
                                                    </div>
                                                    <div className="col-lg-2">
                                                        <button className="btn float-right pg-1mw0-up-add-btn" onClick={this.toggleAddEmailModal}></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 pg-1mw0-up-value-col">
                                            <div className="pg-1mw0-up-value-icon">
                                                <Img src={require("../../../images/icons/pg-1mw0-mobile-icon.png")} alt="Mobile Icon" />
                                            </div>
                                            <div className="pg-1mw0-up-value-wrapper">
                                                <div className="row">
                                                    <div className="col-lg-10">
                                                        <span className="d-block pg-1mw0-up-label">Mobile</span>
                                                        <span className="d-block pg-1mw0-up-value">{user.phone}</span>
                                                        {
                                                            alternativemobile.map((phone, index) => {
                                                                return (<span key={'phone' + index} className="d-block pg-1mw0-up-value-alt">{phone}</span>);
                                                            })
                                                        }
                                                    </div>
                                                    <div className="col-lg-2">
                                                        <button className="btn float-right pg-1mw0-up-add-btn" onClick={this.toggleAddPhoneNoModal}></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 pg-1mw0-up-value-col">
                                            <div className="pg-1mw0-up-value-icon">
                                                <Img src={require("../../../images/icons/pg-1mw0-plocation-icon.png")} alt="PLocation Icon" />
                                            </div>
                                            <div className="pg-1mw0-up-value-wrapper">
                                                <div className="row">
                                                    <div className="col-lg-10">
                                                        <span className="d-block pg-1mw0-up-label">Primary Location</span>
                                                        <span className="d-block pg-1mw0-up-value">{user.location}</span>
                                                    </div>
                                                    <div className="col-lg-2">
                                                        <button className="btn float-right pg-1mw0-up-edit-btn" onClick={this.togglePLocationChangeModal}></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 pg-1mw0-up-value-col">
                                            <div className="pg-1mw0-up-value-icon">
                                                <Img src={require("../../../images/icons/pg-1mw0-password-icon.png")} alt="Password Icon" />
                                            </div>
                                            <div className="pg-1mw0-up-value-wrapper">
                                                <div className="row align-items-center">
                                                    <div className="col-lg-7">
                                                        <span className="d-block pg-1mw0-up-label">Password</span>
                                                        <span className="d-block pg-1mw0-up-value pg-1mw0-up-pwd-value">.....</span>
                                                    </div>
                                                    <div className="col-lg-5">
                                                        <button className="btn float-right pg-1mw0-up-changepwd-btn" 
                                                            onClick={this.togglePasswordChangeModal}>Change Password</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                }

                <Modal show={showaddemailmodal} onHide={this.toggleAddEmailModal} size="sm" aria-labelledby="modal-addemail" centered>
                    <Modal.Header closeButton>
                        <Modal.Title id="modal-addemail"></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            (newemailerror !== '') &&
                            <div className="pg-xxxx-mform-error mb-4">{newemailerror}</div>
                        }

                        <div className="pg-xxxx-mform-group">
                            <label htmlFor="newemail" className="modal-form-label">New Email</label>
                            <input type="text" className="form-control modal-form-control" id="newemail" 
                                value={newemail} onChange={this.handleFormTextboxChange} />
                        </div>
                        <button className="mt-4 btn btn-block btn-signin" onClick={this.addNewEmail}>Add</button>
                    </Modal.Body>
                </Modal>

                <Modal show={showaddphonenomodal} onHide={this.toggleAddPhoneNoModal} size="sm" aria-labelledby="modal-addphoneno" centered>
                    <Modal.Header closeButton>
                        <Modal.Title id="modal-addphoneno"></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            (newphonenoerror !== '') &&
                            <div className="pg-xxxx-mform-error mb-4">{newphonenoerror}</div>
                        }

                        <div className="pg-xxxx-mform-group">
                            <label htmlFor="newphoneno" className="modal-form-label">New Phone No</label>
                            <input type="text" className="form-control modal-form-control" id="newphoneno" 
                                value={newphoneno} onChange={this.handleFormTextboxChange} />
                        </div>
                        <button className="mt-4 btn btn-block btn-signin" onClick={this.addNewPhoneNo}>Add</button>
                    </Modal.Body>
                </Modal>

                <Modal show={showplocchangemodal} onHide={this.togglePLocationChangeModal} size="sm" aria-labelledby="modal-changeploc" centered>
                    <Modal.Header closeButton>
                        <Modal.Title id="modal-changeploc"></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            (newlocerror !== '') &&
                            <div className="pg-xxxx-mform-error mb-4">{newlocerror}</div>
                        }

                        <div className="pg-xxxx-mform-group">
                            <label htmlFor="newloc" className="modal-form-label">New Location</label>
                            <select className="form-control modal-form-control" id="newloc" onChange={this.handleFormTextboxChange}>
                                <option value="0">Select</option>
                                {
                                    locations.map((loc) => {
                                        return( <option key={loc.id} value={loc.id}>{loc.title}</option> );
                                    })
                                }
                            </select>
                        </div>
                        <button className="mt-4 btn btn-block btn-signin" onClick={this.changePrimaryLocation}>Change</button>
                    </Modal.Body>
                </Modal>

                <Modal show={showpwdchangemodal} onHide={this.togglePasswordChangeModal} size="sm" aria-labelledby="modal-changepwd" centered>
                    <Modal.Header closeButton>
                        <Modal.Title id="modal-changepwd"></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="mx-auto modal-logo mb-4">
                            <Img src={require("../../../images/logos/anshik-logo.png")} className="img-fluid" alt="Anshik Logo" />
                        </div>

                        {
                            (pwdchangeerror !== '') &&
                            <div className="pg-xxxx-mform-error mb-2">{pwdchangeerror}</div>
                        }

                        <div className="pg-xxxx-mform-group">
                            <label htmlFor="curpassword" className="modal-form-label">Current Password</label>
                            <input type="password" className="form-control modal-form-control" id="curpassword" 
                                value={curpassword} onChange={this.handleFormTextboxChange} />
                        </div>
                        <div className="pg-xxxx-mform-group">
                            <label htmlFor="newpassword" className="modal-form-label">New Password</label>
                            <input type="password" className="form-control modal-form-control" id="newpassword" 
                                value={newpassword} onChange={this.handleFormTextboxChange} />
                        </div>
                        <button className="mt-4 btn btn-block btn-signin" onClick={this.updatePassword}>Change Password</button>
                    </Modal.Body>
                </Modal>

                <Modal show={showapiresponsemodal} onHide={this.toggleAPIResponseModal} size="sm" aria-labelledby="modal-apiresponse" centered>
                    <Modal.Header closeButton>
                        <Modal.Title id="modal-apiresponse"></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="text-center">
                            <Img src={require("../../../images/icons/success.png")} className="img-fluid" alt="Success Icon" />
                        </div>

                        <div className="form-group mt-4 text-center">
                            <label className="d-block otpveri-message">{respmsg}</label>
                        </div>
                        <button className="mt-5 d-flex mx-auto btn btn-signin btn-done" onClick={this.toggleAPIResponseModal}>Done</button>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}

export default Profile;