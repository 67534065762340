import                                './RightChatBubble.css';
import React from 'react'

export default class RightChatBubble extends React.Component {

    constructor(props) {
        super(props)
    }
    render(){
        return(
            <div className="d-flex justify-content-end">
                <div className="right-bubble-container">
                    <div style={{position:'relative'}}>
                    <div className="right-message-box">
                    {this.props.children}
                    </div>
                    </div>
                </div>
                
            </div>
        )
    }
}