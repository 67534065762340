import                           './UCommon.css';
import React, { Component } from 'react';
import Img                  from 'react-image';
import { Link }             from 'react-router-dom';
import API                  from '../../../helpers/API';

class UCommon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            requestentries : [],
            acceptentries  : []
        };

        this.loadRequestDropdown = this.loadRequestDropdown.bind(this);
        this.loadAcceptDropdown  = this.loadAcceptDropdown.bind(this);
    }

    componentDidMount() {
        const accesstoken = localStorage.getItem('anshik-token');
        const requestheaders = {
            headers : {
                'Authorization' : 'Bearer ' + accesstoken,
                'Content-Type'  : 'application/json'
            }
        };

        this.loadRequestDropdown(requestheaders);
        this.loadAcceptDropdown(requestheaders);
    }

    loadRequestDropdown(requestheaders) {
        API
            .get('request-details', requestheaders)
            .then((response) => {
                this.setState({ requestentries : response.data.property });
            })
            .catch((error) => {
                // console.log('AXIOS GET: Error: ');
                // console.log(error);
            });
    }

    loadAcceptDropdown(requestheaders) {
        API
            .get('accept-details', requestheaders)
            .then((response) => {
                this.setState({ acceptentries : response.data.property });
            })
            .catch((error) => {
                // console.log('AXIOS GET: Error: ');
                // console.log(error);
            });
    }

    render() {
        const { requestentries, acceptentries } = this.state;

        return(
            <section className="pg-xxxx-uactions-section">
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <div className="float-left pg-xxxx-uactions-link">
                                <Link to={'/user/home'}>
                                    <span className="pg-xxxx-ul-icon pg-xxxx-ul-home-icon"></span>
                                    <span className="pg-xxxx-ul-text">Home</span>
                                </Link>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="float-right ml-4 pg-xxxx-uactions-link">
                            {
                                acceptentries.length === 0 ? (
                                    <React.Fragment>
                                        <span className="pg-xxxx-ul-icon pg-xxxx-ul-accept-icon"></span>
                                        <span className="pg-xxxx-ul-text">Accept</span>
                                    </React.Fragment>
                                ) : (
                                    <div className="dropdown">
                                        <div id="accept-dd" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <span className="pg-xxxx-ul-icon pg-xxxx-ul-accept-icon"></span>
                                            <span className="pg-xxxx-ul-text">Accept</span>
                                            <span className="pg-xxxx-ul-count">{acceptentries.length}</span>
                                        </div>
                                        <div className="dropdown-menu dropdown-menu-right pg-xxxx-uprop-dd" aria-labelledby="accept-dd">
                                        {
                                            acceptentries.map((entry, index) => {
                                                return (
                                                    <Link key={'uaccept' + index} to={'/user/myproperty/' + entry._id + '/#pgg83hdaypicker'} 
                                                        className="dropdown-item pg-xxxx-uprop">
                                                        <div className="pg-xxxx-uprop-img-wrapper">
                                                            <Img src={entry.image} alt="..." className="pg-xxxx-uprop-img" />
                                                        </div>
                                                        <div className="pg-xxxx-uprop-tu-wrapper">
                                                            <div className="pg-xxxx-uprop-title">{entry.title}</div>
                                                            <div className="pg-xxxx-uprop-user">
                                                                <Img src={require("../../../images/icons/pg-306f-icon-user@2x.png")} alt="..." 
                                                                    className="pg-xxxx-uprop-icon-user" />
                                                                <span>{entry.user_name}</span>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                );
                                            })
                                        }
                                        </div>
                                    </div>
                                )
                            }
                            </div>
                            <div className="float-right ml-4 pg-xxxx-uactions-link">
                            {
                                requestentries.length === 0 ? (
                                    <React.Fragment>
                                        <span className="pg-xxxx-ul-icon pg-xxxx-ul-request-icon"></span>
                                        <span className="pg-xxxx-ul-text">Request</span>
                                    </React.Fragment>
                                ) : (
                                    <div className="dropdown">
                                        <div id="requests-dd" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <span className="pg-xxxx-ul-icon pg-xxxx-ul-request-icon"></span>
                                            <span className="pg-xxxx-ul-text">Request</span>
                                            <span className="pg-xxxx-ul-count">{requestentries.length}</span>
                                        </div>
                                        <div className="dropdown-menu dropdown-menu-right pg-xxxx-uprop-dd" aria-labelledby="requests-dd">
                                        {
                                            requestentries.map((entry, index) => {
                                                return (
                                                    <Link key={'urequest' + index} to={'/user/myproperty/' + entry._id + '/#pgg83hdaypicker'} 
                                                        className="dropdown-item pg-xxxx-uprop">
                                                        <div className="pg-xxxx-uprop-img-wrapper">
                                                            <Img src={entry.image} alt="..." className="pg-xxxx-uprop-img" />
                                                        </div>
                                                        <div className="pg-xxxx-uprop-tu-wrapper">
                                                            <div className="pg-xxxx-uprop-title">{entry.title}</div>
                                                            <div className="pg-xxxx-uprop-user">
                                                                <Img src={require("../../../images/icons/pg-306f-icon-user@2x.png")} alt="..." 
                                                                    className="pg-xxxx-uprop-icon-user" />
                                                                <span>{entry.user_name}</span>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                );
                                            })
                                        }
                                        </div>
                                    </div>
                                )
                            }
                            </div>
                            <div className="float-right pg-xxxx-uactions-link">
                                <Link to={'/user/chat'}>
                                    <span className="pg-xxxx-ul-icon pg-xxxx-ul-chat-icon"></span>
                                    <span className="pg-xxxx-ul-text">Chat</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default UCommon;