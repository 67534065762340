import React      from 'react';
import FormWizard from './FormWizard';

const AddPost = (props) => {
	return(
        <section className="add-post fw-content-row">
            <div className="container">
                <FormWizard {...props} setAppState={props.setAppState} />
            </div>
        </section>
    );
}

export default AddPost;