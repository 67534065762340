import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import HomeBanner           from './HomeBanner';
import Posts                from './Posts';
import HowWeWork            from './HowWeWork';
import AllCategories        from './AllCategories';

class Home extends Component {
    componentDidMount() {
        const { showresetpwdmodal } = this.props;

        if (!showresetpwdmodal && this.props.match.path === '/password/reset/:token') {
            this.props.setAppState('resetpwdtoken', this.props.match.params.token);
            this.props.setAppState('showresetpwdmodal', true);
        }
    }

    render() {
        const { category, location, categories, locations, showofferads, showrecentads, 
            showkissandeals, showotherads, offerads, recentads, kissandeals, otherads } = this.props;

        return(
            <React.Fragment>
                <HomeBanner
                    {...this.props}
                    category={category}
                    location={location}
                    categories={categories}
                    locations={locations} />
                <Posts
                    category={category}
                    location={location}
                    showofferads={showofferads}
                    showrecentads={showrecentads}
                    showkissandeals={showkissandeals}
                    showotherads={showotherads}
                    offerads={offerads}
                    recentads={recentads}
                    kissandeals={kissandeals}
                    otherads={otherads} />
                <HowWeWork />
                <AllCategories
                    location={location} />
            </React.Fragment>
        );
    }
}

Home.protoTypes = {
    setAppState : PropTypes.func
};

export default Home;