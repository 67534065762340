import React, { Component } from 'react';
import Carousel, { consts } from 'react-elastic-carousel'
import { Link }                          from 'react-router-dom';
import './HomeBanner.css'

export default class CategoryCarousel extends Component {
    myArrow({ type, onClick, isEdge }) {
        if(type === consts.PREV) {
            return(
                <div onClick={onClick} className="prev-arrow">
                    <span class="carousel-arrow"> &lsaquo; </span>
                </div>
            )
        } else {
            return(
                <div onClick={onClick} class="next-arrow">
                    <span class="carousel-arrow"> &rsaquo; </span>
                </div>
            )
        }

      }
    render() {
        const { location } = this.props;
        return (
            <div className="category-carousel">
                <div className="carousel-body">
                    <Carousel  itemsToShow={3}
                    renderPagination={()=>{return <div></div>}}
                    renderArrow={this.myArrow}
                    >
                        <div className="custom-carousel-item">
                            <Link to={'/properties/category/' + location.value + '/5e6612586d4437782916dd24/0'}>
                                <img src={require("../../images/icons/cat-1.png")} alt="Automotive" />
                            </Link>
                        </div>
                        <div className="custom-carousel-item">
                            <Link to={'/properties/category/' + location.value + '/5e661296c44aa032797a1c72/0'}>
                                <img src={require("../../images/icons/cat-2.png")} alt="Real Estate" />
                            </Link>
                        </div>
                        <div className="custom-carousel-item">
                            <Link to={'/properties/category/' + location.value + '/5e6612c1992fb52d286478f5/0'}>
                                <img src={require("../../images/icons/cat-3.png")} alt="Business" />
                            </Link>
                        </div>
                        <div className="custom-carousel-item">
                            <Link to={'/properties/category/' + location.value + '/5e6612f28d757322385c17e5/0'}>
                                <img src={require("../../images/icons/cat-4.png")} alt="Kissan" />
                            </Link>
                        </div>
                    {/* hiding others menu  */}

                        {/* <div className="custom-carousel-item">
                            <Link to={'/properties/category/' + location.value + '/5e6612378d757322385c17e4/0'}>
                                <img className='hide' src={require("../../images/icons/cat-5.png")} alt="Others" />
                            </Link>
                        </div> */}

                    </Carousel> 
                </div>
            </div>
        );
    }
}