import                                './Property.css';
import React, { Component }      from 'react';
import FontAwesome               from 'react-fontawesome';
import API                       from '../../helpers/API';
import PropertySearch            from '../../components/PropertySearch';
import PropertyTimeOrProfitBased from './PropertyTimeOrProfitBased';

class Property extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isfetching    : true,
            categorydata  : null,
            propertydata  : null,
            groupdata     : null
        };

        this.goBack = this.goBack.bind(this);
    }

    componentDidMount() {
        const { id } = this.props.match.params;

        API
            .get('property-details-with-group/' + id)
            .then((response) => {
                let responsedata = response.data.propertywithgroupdetails;
                this.setState({
                    categorydata : responsedata.category,
                    propertydata : responsedata.property_details,
                    groupdata    : responsedata.group,
                    isfetching   : false
                });
            })
            .catch((error) => {
                // console.log('AXIOS GET Error:');
                // console.log(error);
            });
    }

    goBack(e) {
        e.preventDefault();
        this.props.history.goBack();
    }

    render() {
        const { categories, locations, searchMode, normalSearchCategory, normalSearchValue, advSearchLocation } = this.props;
        const { isfetching, categorydata, propertydata, groupdata } = this.state;
        
        return(
            <React.Fragment>
                <PropertySearch
                    {...this.props}
                    categories={categories}
                    locations={locations}
                    searchMode={searchMode}
                    normalSearchCategory={normalSearchCategory}
                    normalSearchValue={normalSearchValue}
                    advSearchLocation={advSearchLocation} />

                {
                    !isfetching &&
                    <React.Fragment>
                        <div className="container">
                            <div className="row">
                                <div className="col-12 back-link-wrapper">
                                    <button className="properties-back-link" onClick={this.goBack}>
                                        <FontAwesome name="angle-left" className="properties-back-icon" />
                                        <span className="properties-back-text">Back</span>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <PropertyTimeOrProfitBased
                            categorydata={categorydata}
                            propertydata={propertydata}
                            groupdata={groupdata} />
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

export default Property;