import React, { Component }     from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import                               'react-day-picker/lib/style.css';
import PropTypes                from 'prop-types';

class FormWizardStepFour extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selecteddays : this.props.selecteddays
        };

        this.handleDayClick = this.handleDayClick.bind(this);
    }

    handleDayClick(day, { selected }) {
        const { selecteddays } = this.state;

        if (selected) {
            const selectedindex = selecteddays.findIndex(selectedday =>
                DateUtils.isSameDay(selectedday, day)
            );
            selecteddays.splice(selectedindex, 1);
        } else {
            selecteddays.push(day);
        }

        this.setState({ selecteddays : selecteddays });
        this.props.updateAddPostFormState('selecteddays', selecteddays);
    }

    render() {
        const today = new Date();
        const { selecteddays } = this.state;

        return (
            <React.Fragment>
                <div className="row add-post-heading-row">
                    <div className="col-12">
                        <span className="add-post-heading">Usage Date</span>
                    </div>
                </div>

                <div className="row pg-ber9-s4-calendar-row">
                    <div className="col-12">
                        <DayPicker
                            fromMonth={new Date()}
                            onDayClick={this.handleDayClick}
                            initialMonth={new Date()}
                            disabledDays={{ before: today }}
                            selectedDays={selecteddays}
                            numberOfMonths={2}
                            pagedNavigation />
                            {/* {console.log(selecteddays)} */}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

FormWizardStepFour.protoTypes = {
    updateAddPostFormState : PropTypes.func
};

export default FormWizardStepFour;