import React, { Component } from 'react';

class HowWeWork extends Component {
    render() {
        return(
            <section id="how-we-work" className="ads-section">
                <div className="container">
                    <div className="row">
                        <div className="col pt-5 pb-5 text-center ads-section-heading">How We Work?</div>
                    </div>

                    <div className="hww-steps">
                        <div className="hww-steps-row">
                            <div className="row container">
                            <div className="hww-step col-6 col-md-3">
                                <div className="step-circle"></div>
                                <p>Create Account</p>
                            </div>
                            <div className="hww-second-step hww-step col-6 col-md-3">
                                <div className="step-circle"></div>
                                <p>Add/Join Post</p>
                            </div>
                            <div className="hww-step col-6 col-md-3">
                                <div className="step-circle"></div>
                                <p>Share Partnership</p>
                            </div>
                            <div className="hww-step col-6 col-md-3">
                                <div className="step-circle"></div>
                                <p>Deal Done</p>
                            </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default HowWeWork;