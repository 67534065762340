import React, { Component } from 'react';
import { Link }             from 'react-router-dom';
import Img                  from 'react-image';
import { imageloader }      from '../../helpers/Constants';

class RecentAds extends Component {
    render() {
        const { category, location, showrecentads, recentads } = this.props;

        return(
            <section id="recent-ads" className={"alternate-section" + (showrecentads ? '' : ' d-none')}>
                <div className="container">
                    <div className="row">
                        <div className="col pt-5 pb-5 text-center ads-section-heading">Recent Ads</div>
                    </div>
                    <div className="row">
                        
                        {
                            recentads.map((recentad) => {
                                let adstatus = '';
                                if (recentad.property_status === "0") {
                                    adstatus = <div className="ad-status ad-status-new">NEW</div>;
                                } else if (recentad.property_status === "1") {
                                    adstatus = <div className="ad-status ad-status-used">USED</div>;
                                }
                                else if (recentad.property_status ==="2"){
                                    adstatus = <div className='ad-status ad-status-running'>RUNNING</div>
                                }

                                return(
                                    <div className="mb-4 col-xl-3 col-6">
                                        <div className="card ad-card" key={recentad._id}>
                                            <div className="card-body ad-card-body">
                                                <div className="ad-img-wrapper">
                                                {
                                                    (recentad.category === "Kissan") ? (
                                                        <Link to={'/propertyint/' + recentad._id}>
                                                            <Img
                                                                src={recentad.image} alt="..."
                                                                loader={ imageloader }
                                                                unloader={ imageloader }
                                                                className="img-fluid img-rounded ad-img" />
                                                        </Link>
                                                    ) : (
                                                        <Link to={'/property/' + recentad._id}>
                                                            <Img
                                                                src={recentad.image} alt="..."
                                                                loader={ imageloader }
                                                                unloader={ imageloader }
                                                                className="img-fluid img-rounded ad-img" />
                                                        </Link>
                                                    )
                                                }
                                                </div>
                                                {adstatus}
                                                <p className="ad-category">{recentad.category}</p>

                                                {
                                                    (recentad.category === "Kissan") ? (
                                                        <Link to={'/propertyint/' + recentad._id}>
                                                            <p className="ad-title">{recentad.title}</p>
                                                        </Link>
                                                    ) : (
                                                        <Link to={'/property/' + recentad._id}>
                                                            <p className="ad-title">{recentad.title}</p>
                                                        </Link>
                                                    )
                                                }

                                                
                                                <div className="row">
                                                    <div className="col-10">
                                                        <p className="ad-location-wrapper">
                                                            <img src={require("../../images/icons/ad-location.png")} alt="Location Icon" />
                                                            <span className="ml-2 ad-location">{recentad.location}</span>
                                                        </p>
                                                        <p className="ad-by-wrapper">
                                                            <img src={require("../../images/icons/ad-by.png")} alt="User Icon" />
                                                            <span className="ml-2 ad-by">{recentad.user_name}</span>
                                                        </p>
                                                    </div>
                                                    <div className="col-2">
                                                        <img src={require("../../images/icons/share-ad.png")} alt="Share Ad Icon" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                );
                            })
                        }
                        
                    </div>

                    <div className="row">
                        <div className="col-12 pt-2 pb-4 text-right">
                            <Link to={'/properties/viewmore/recent/' + location.value + '/' + category.value} className="view-more-link">
                                <span className="view-more-link">View More</span>&nbsp;
                                <img src={require("../../images/icons/view-more.png")} alt="..." />
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default RecentAds;