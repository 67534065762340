import                           './MyProperties.css';
import React, { Component } from 'react';
import { Link }             from 'react-router-dom';
import Img                  from 'react-image';
import FontAwesome          from 'react-fontawesome';
import jwt_decode           from 'jwt-decode';
import API                  from '../../../helpers/API';
import { toCurrency }       from '../../../helpers/Formatters';
import { imageloader }      from '../../../helpers/Constants';
import UCommon              from '../common/UCommon';

class MyPropertiesTimeBased extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isfetching     : false,
            timebasedprops : [],
            currentpage    : 0,
            numberofpages  : 0
        };

        this.loadProperties = this.loadProperties.bind(this);
    }

    componentDidMount() {
        this.loadProperties();
    }

    loadProperties() {
        const { timebasedprops, currentpage } = this.state;

        let accesstoken = localStorage.getItem('anshik-token');
        const requestHeaders = {
            headers : {
                'Authorization' : 'Bearer ' + accesstoken,
                'Content-Type'  : 'application/json'
            }
        };

        let pageno  = currentpage + 1;
        var decoded = jwt_decode(accesstoken);
        var userid  = decoded.sub;

        this.setState({
            isfetching  : true,
            currentpage : pageno
        });

        API
            .get('my-property-details-with-share-expanded/' + userid + '/timebased?page=' + pageno, requestHeaders)
            .then((response) => {
                let responsedata = response.data.categorybaseddetails.timebased;
                this.setState({
                    timebasedprops : timebasedprops.concat(responsedata.data),
                    currentpage    : responsedata.current_page,
                    numberofpages  : responsedata.last_page,
                    isfetching     : false
                });
            })
            .catch((error) => {
                // console.log('AXIOS GET: Error: ');
                // console.log(error);
            });
    }

    render() {
        const { isfetching, currentpage, numberofpages, timebasedprops } = this.state;

        return(
            <React.Fragment>
                <UCommon />

                <div className="pg-22rt-myprops-wrapper">
                    <div className="container">
                        <div className="row pg-22rt-myp-heading-row">
                            <div className="col-6">
                                <span className="pg-22rt-myp-heading">My Properties - Time Based</span>
                            </div>
                            <div className="col-6">
                                <Link to={'/user/home'}>
                                    <div className="float-right pg-22rt-myp-minimize"></div>
                                </Link>
                            </div>
                        </div>

                        {
                            timebasedprops.map((property) => {
                                let adstatus = '';
                                if (property.property_details.property_status === "0") {
                                    adstatus = <span className="ml-4 pg-22rt-ad-status pg-22rt-ad-status-new">NEW</span>;
                                } else if (property.property_details.property_status === "1") {
                                    adstatus = <span className="ml-4 pg-22rt-ad-status pg-22rt-ad-status-used">USED</span>;
                                }

                                let wfastatus = '';
                                if (property.property_details.is_aproved === 0) {
                                    wfastatus = <div className="pg-22rt-wfa-overlay"><div className="pg-22rt-wfa-text">Waiting for Approval...</div></div>;
                                }

                                return (
                                    <div className="pg-22rt-ad-wrapper" key={property.property_details._id}>
                                        <Link to={'/user/myproperty/' + property.property_details._id} className="d-block h-100 pg-22rt-ad-link">
                                            {wfastatus}
                                            <div className="row h-100">
                                                <div className="col-3">
                                                    <div className="pg-22rt-ad-img-wrapper">
                                                        <Img
                                                            src={property.property_details.image} alt="..."
                                                            loader={ imageloader }
                                                            unloader={ imageloader }
                                                            className="img-fluid img-rounded pg-22rt-ad-img" />
                                                    </div>
                                                </div>
                                                <div className="col-4 pg-22rt-ad-details-col">
                                                    <p className="pg-22rt-ad-category">{property.property_details.category}</p>
                                                    <p className="pg-22rt-ad-title">{property.property_details.title}</p>

                                                    <div className="row pg-22rt-ad-lus-row">
                                                        <div className="col-12">
                                                            <span>
                                                                <img src={require("../../../images/icons/ad-location.png")} alt="Location Icon" />
                                                                <span className="ml-2 pg-22rt-ad-location">{property.property_details.location}</span>
                                                            </span>
                                                            <span className="ml-4">
                                                                <img src={require("../../../images/icons/ad-by.png")} alt="User Icon" />
                                                                <span className="ml-2 pg-22rt-ad-by">{property.property_details.user_name}</span>
                                                            </span>
                                                            {adstatus}
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-7">
                                                            <span className="d-block pg-22rt-ad-total-price-label">Total Price</span>
                                                            <span className="pg-22rt-ad-total-price-value">
                                                                <FontAwesome name="rupee" /> {toCurrency(property.property_details.price)}</span>
                                                        </div>
                                                        <div className="col-5">
                                                            <span className="d-block pg-22rt-ad-total-price-label">Total Days</span>
                                                            <span className="pg-22rt-ad-total-price-value">90</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-5">
                                                    <div className="row h-100">
                                                        <div className="col-4 pg-22rt-mshare-col">
                                                            <div className="h-100 pg-22rt-mshare-wrapper">
                                                                <p className="text-center pg-22rt-mshare-heading">Own Share</p>

                                                                <div className="row pg-22rt-mshare-row">
                                                                    <div className="col-12">
                                                                        <span className="d-block pg-22rt-mshare-label">Amount</span>
                                                                        <span className="d-block pg-22rt-mshare-value">
                                                                            <FontAwesome name="rupee" /> {toCurrency(property.share_details.own_amount)}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="row pg-22rt-mshare-row">
                                                                    <div className="col-12">
                                                                        <span className="d-block pg-22rt-mshare-label">Days</span>
                                                                        <span className="d-block pg-22rt-mshare-value">{property.share_details.own_share}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-4 pg-22rt-oshare-col1">
                                                            <div className="h-100 pg-22rt-oshare-wrapper">
                                                                <p className="text-center pg-22rt-oshare-heading">Joined Share</p>

                                                                <div className="row pg-22rt-oshare-row">
                                                                    <div className="col-12">
                                                                        <span className="d-block pg-22rt-oshare-label">Amount</span>
                                                                        <span className="d-block pg-22rt-oshare-value">
                                                                            <FontAwesome name="rupee" /> {toCurrency(property.share_details.total_approved_amount)}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="row pg-22rt-oshare-row">
                                                                    <div className="col-6">
                                                                        <span className="d-block pg-22rt-oshare-label">Days</span>
                                                                        <span className="d-block pg-22rt-oshare-value">{property.share_details.total_approved_user_share}</span>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <div className="pg-22rt-share-users">
                                                                            <div className="pg-22rt-share-ucount">{property.share_details.total_approved_user}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-4 pg-22rt-oshare-col2">
                                                            <div className="h-100 pg-22rt-oshare-wrapper">
                                                                <p className="text-center pg-22rt-oshare-heading">Pending Share</p>

                                                                <div className="row pg-22rt-oshare-row">
                                                                    <div className="col-12">
                                                                        <span className="d-block pg-22rt-oshare-label">Amount</span>
                                                                        <span className="d-block pg-22rt-oshare-value">
                                                                            <FontAwesome name="rupee" /> {toCurrency(property.share_details.total_pending_amount)}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="row pg-22rt-oshare-row">
                                                                    <div className="col-6">
                                                                        <span className="d-block pg-22rt-oshare-label">Days</span>
                                                                        <span className="d-block pg-22rt-oshare-value">{property.share_details.total_pending_user_share}</span>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <div className="pg-22rt-share-users">
                                                                            <div className="pg-22rt-share-ucount">{property.share_details.total_pending_user}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                );
                            })
                        }

                        <div className="row">
                            <div className="col-12">
                            {
                                (currentpage < numberofpages) &&
                                <button className="btn-block view-more-button" disabled={isfetching} 
                                        onClick={() => this.loadProperties()}>
                                    <span className="view-more-link">View More</span>&nbsp;&nbsp;
                                    <img src={require("../../../images/icons/view-more.png")} alt="..." />
                                </button>
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default MyPropertiesTimeBased;