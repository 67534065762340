import React, { Component } from 'react';
import Modal                from 'react-bootstrap/Modal';
import PropTypes            from 'prop-types';
import API                  from '../helpers/API';
import FacebookOAuth        from './social/FacebookOAuth';
import GoogleOAuth          from './social/GoogleOAuth';

class Modals extends Component {
    constructor(props) {
        super(props);
        this.state = {
            locations          : [],
            // LOGIN
            lemail             : '',
            lemailerror        : '',
            lphone             : '',
            lphoneerror        : '',
            lpassword          : '',
            lpassworderror     : '',
            lerror             : '',
            // REGISTER
            rname              : '',
            rnameerror         : '',
            remail             : '',
            remailerror        : '',
            rphone             : '',
            rphoneerror        : '',
            rlocation          : '',
            rlocationerror     : '',
            rpassword          : '',
            rpassworderror     : '',
            rconfpassword      : '',
            rconfpassworderror : '',
            rerror             : '',
            ruserid            : '',
            rphoneno           : '',
            // OTP
            votpdigit1         : '',
            votpdigit2         : '',
            votpdigit3         : '',
            votpdigit4         : '',
            votperror          : '',
            // FORGOT PASSWORD
            fpemail            : '',
            fpemailerror       : '',
            fperror            : '',
            // RESET PASSWORD
            rppassword         : '',
            rppassworderror    : '',
            rperror            : '',
            // modalSigninShown : false,
            // modalSignupShown : false,
            modalVerifyOTPShown : false,
            modalOTPVeriStatusShown : false,
            modalForgotPasswordShown : false,
            modalForgotPasswordStatusShown : false,
            showresetpwdstatusmodal : false
        };

        this.handleLEmailChange = this.handleLEmailChange.bind(this);
        this.handleLPasswordChange = this.handleLPasswordChange.bind(this);

        this.handleRNameChange = this.handleRNameChange.bind(this);
        this.handleREmailChange = this.handleREmailChange.bind(this);
        this.handleRPhoneChange = this.handleRPhoneChange.bind(this);
        this.handleRLocationChange = this.handleRLocationChange.bind(this);
        this.handleRPasswordChange = this.handleRPasswordChange.bind(this);
        this.handleRConfPasswordChange = this.handleRConfPasswordChange.bind(this);

        this.handleOTPDigit1Change = this.handleOTPDigit1Change.bind(this);
        this.handleOTPDigit2Change = this.handleOTPDigit2Change.bind(this);
        this.handleOTPDigit3Change = this.handleOTPDigit3Change.bind(this);
        this.handleOTPDigit4Change = this.handleOTPDigit4Change.bind(this);

        this.handleFPEmailChange = this.handleFPEmailChange.bind(this);

        this.handleRPPasswordChange = this.handleRPPasswordChange.bind(this);
        this.toggleResetPasswordStatusModal = this.toggleResetPasswordStatusModal.bind(this);

        this.setModalState       = this.setModalState.bind(this);
        this.signin              = this.signin.bind(this);
        this.addOrUpdateFcmToken = this.addOrUpdateFcmToken.bind(this);
        this.signup              = this.signup.bind(this);
        this.verifyotp           = this.verifyotp.bind(this);
        this.resendotp           = this.resendotp.bind(this);
        this.forgotpassword      = this.forgotpassword.bind(this);
        this.resetPassword       = this.resetPassword.bind(this);
    }

    componentDidMount() {
        let locations = [];

        API
            .get('location')
            .then((response) => {
                response.data.location.forEach((loc) => 
                    locations.push({
                        id : loc._id,
                        key : 'locations',
                        title : loc.location,
                        selected : false
                    })
                );
                this.setState({
                    locations : locations,
                    rlocation : locations[0]['_id']
                });
            });
    }

    showSigninModal = () => { this.props.setAppState('showsigninmodal', true); }
    showSignupModal = () => { this.props.setAppState('showsignupmodal', true); }
    showVerifyOTPModal = () => { this.setState({ modalVerifyOTPShown: true }); }
    showOTPVeriStatusModal = () => { this.setState({ modalOTPVeriStatusShown: true }); }
    showForgotPasswordModal = () => { this.setState({ modalForgotPasswordShown: true }); }
    showForgotPasswordStatusModal = () => { this.setState({ modalForgotPasswordStatusShown: true }); }
    showResetPwdModal = () => { this.props.setAppState('showresetpwdmodal', true); }

    closeSigninModal = () => {
        this.setState({
            lemail         : '',
            lemailerror    : '',
            lphone         : '',
            lphoneerror    : '',
            lpassword      : '',
            lpassworderror : '',
            lerror         : ''
        });
        this.props.setAppState('showsigninmodal', false);
    }
    closeSignupModal = () => {
        this.setState({
            rname              : '',
            rnameerror         : '',
            remail             : '',
            remailerror        : '',
            rphone             : '',
            rphoneerror        : '',
            rlocation          : '',
            rlocationerror     : '',
            rpassword          : '',
            rpassworderror     : '',
            rconfpassword      : '',
            rconfpassworderror : '',
            rerror             : ''
        });
        this.props.setAppState('showsignupmodal', false);
    }
    closeVerifyOTPModal = () => {
        this.setState({
            votpdigit1          : '',
            votpdigit2          : '',
            votpdigit3          : '',
            votpdigit4          : '',
            votperror           : '',
            modalVerifyOTPShown : false
        });
    }
    closeOTPVeriStatusModal = () => {
        this.setState({ modalOTPVeriStatusShown : false });
    }
    closeForgotPasswordModal = () => {
        this.setState({
            fpemail                  : '',
            fpemailerror             : '',
            fperror                  : '',
            modalForgotPasswordShown : false
        });
    }
    closeForgotPasswordStatusModal = () => {
        this.setState({ modalForgotPasswordStatusShown : false });
    }
    hideResetPwdModal = () => {
        this.setState({
            rppassword      : '',
            rppassworderror : '',
            rperror         : ''
        });
        this.props.setAppState('showresetpwdmodal', false);
    }

    handleLEmailChange = (e) => { this.setState({ lemail: e.target.value }); }
    handleLPasswordChange = (e) => { this.setState({ lpassword: e.target.value }); }
    handleRNameChange = (e) => { this.setState({ rname: e.target.value }); }
    handleREmailChange = (e) => { this.setState({ remail: e.target.value }); }
    handleRPhoneChange = (e) => { this.setState({ rphone: e.target.value }); }
    handleRLocationChange = (e) => { this.setState({ rlocation: e.target.value }); }
    handleRPasswordChange = (e) => { this.setState({ rpassword: e.target.value }); }
    handleRConfPasswordChange = (e) => { this.setState({ rconfpassword: e.target.value }); }
    handleOTPDigit1Change = (e) => { this.setState({ votpdigit1: e.target.value }); }
    handleOTPDigit2Change = (e) => { this.setState({ votpdigit2: e.target.value }); }
    handleOTPDigit3Change = (e) => { this.setState({ votpdigit3: e.target.value }); }
    handleOTPDigit4Change = (e) => { this.setState({ votpdigit4: e.target.value }); }
    handleFPEmailChange = (e) => { this.setState({ fpemail: e.target.value }); }
    handleRPPasswordChange(e) { this.setState({ rppassword: e.target.value }); }

    onSignupLinkClick = () => {
        this.closeSigninModal();
        this.showSignupModal();
    }
    onSigninLinkClick = () => {
        this.closeSignupModal();
        this.showSigninModal();
    }
    onForgotPasswordLinkClick = () => {
        this.closeSigninModal();
        this.showForgotPasswordModal();
    }
    onSigninLinkClick2 = () => {
        this.closeForgotPasswordModal();
        this.showSigninModal();
    }

    toggleResetPasswordStatusModal() {
        this.setState(prevState => ({
            showresetpwdstatusmodal : !prevState.showresetpwdstatusmodal
        }));
    }

    setModalState(key, value) {
        this.props.setAppState(key, value);
    }

    signin(e) {
        e.preventDefault();
        this.setState({
            lerror         : '',
            lemailerror    : '',
            lpassworderror : ''
        });

        const { lemail, lpassword } = this.state;
        const requestHeaders = {
            headers : {
                'Content-Type' : 'application/json'
            }
        };
        
        var requestParams = {};
        if (isNaN(lemail)) {
            requestParams = {
                'email'    : lemail,
                'password' : lpassword
            };
        } else {
            requestParams = {
                'phone'    : lemail,
                'password' : lpassword
            };
        }

        API
            .post('login', requestParams, requestHeaders)
            .then((response) => {
                localStorage.setItem('anshik-token', response.data.accesstoken);
                localStorage.setItem('anshik-username', response.data.user.name);

                this.addOrUpdateFcmToken();
                this.props.setAppState('isloggedin', true);
                this.setState({
                    lemail    : '',
                    lpassword : ''
                });
                this.closeSigninModal();
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    this.setState({ lerror : error.response.data.message });
                } else if (error.response.status === 422) {
                    var eresp = error.response.data;
                    if (eresp.hasOwnProperty('email')) {
                        this.setState({ lemailerror : eresp.email[0] });
                    }
                    if (eresp.hasOwnProperty('phone')) {
                        this.setState({ lemailerror : eresp.phone[0] });
                    }
                    if (eresp.hasOwnProperty('password')) {
                        this.setState({ lpassworderror : eresp.password[0] });
                    }
                }
            });
    }

    addOrUpdateFcmToken() {
        const accesstoken = localStorage.getItem('anshik-token');
        const requestHeaders = {
            headers : {
                'Authorization' : 'Bearer ' + accesstoken,
                'Content-Type'  : 'application/json'
            }
        };

        const fcmtoken = localStorage.getItem('anshik-fcmtoken');
        const requestParams = {
            'fcm_token' : fcmtoken
        };

        API
            .post('fcm-token', requestParams, requestHeaders)
            .then((response) => {
                // console.log('AXIOS POST: Response: ');
                // console.log(response.data);
            })
            .catch((error) => {
                // console.log('AXIOS POST: Error: ');
                // console.log(error);
            });
    }

    signup(e) {
        e.preventDefault();
        this.setState({
            rerror             : '',
            rnameerror         : '',
            remailerror        : '',
            rphoneerror        : '',
            rlocationerror     : '',
            rpassworderror     : '',
            rconfpassworderror : ''
        });

        const { rname, remail, rphone, rlocation, rpassword, rconfpassword } = this.state;
        const requestHeaders = {
            headers : {
                'Content-Type' : 'application/json'
            }
        };
        const requestParams = {
            'name'                  : rname,
            'email'                 : remail,
            'phone'                 : rphone,
            'location_id'           : rlocation,
            'password'              : rpassword,
            'password_confirmation' : rconfpassword
        };

        API
            .post('register', requestParams, requestHeaders)
            .then((response) => {
                this.setState({
                    ruserid       : response.data.user._id,
                    rphoneno      : response.data.user.phone,
                    rname         : '',
                    remail        : '',
                    rphone        : '',
                    rlocation     : '',
                    rpassword     : '',
                    rconfpassword : ''
                });
                this.closeSignupModal();
                this.showVerifyOTPModal();
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    this.setState({ rerror : error.response.data.message });
                } else if (error.response.status === 422) {
                    var eresp = error.response.data;
                    if (eresp.hasOwnProperty('name')) {
                        this.setState({ rnameerror : eresp.name[0] });
                    }
                    if (eresp.hasOwnProperty('email')) {
                        this.setState({ remailerror : eresp.email[0] });
                    }
                    if (eresp.hasOwnProperty('phone')) {
                        this.setState({ rphoneerror : eresp.phone[0] });
                    }
                    if (eresp.hasOwnProperty('location_id')) {
                        this.setState({ rlocationerror : eresp.location_id[0] });
                    }
                    if (eresp.hasOwnProperty('password')) {
                        this.setState({ rpassworderror : eresp.password[0] });
                    }
                    if (eresp.hasOwnProperty('password_confirmation')) {
                        this.setState({ rconfpassworderror : eresp.password_confirmation[0] });
                    }
                }
            });
    }

    verifyotp(e) {
        e.preventDefault();
        this.setState({ votperror : '' });

        const { ruserid, votpdigit1, votpdigit2, votpdigit3, votpdigit4 } = this.state;
        const otp = votpdigit1 + votpdigit2 + votpdigit3 + votpdigit4;
        const requestUrl = 'otp-validate/' + ruserid;
        const requestHeaders = {
            headers : {
                'Content-Type' : 'application/json'
            }
        };
        const requestParams = {
            'otp' : otp
        };

        API
            .post(requestUrl, requestParams, requestHeaders)
            .then((response) => {
                this.setState({
                    votpdigit1 : '',
                    votpdigit2 : '',
                    votpdigit3 : '',
                    votpdigit4 : ''
                });
                this.closeVerifyOTPModal();
                this.showOTPVeriStatusModal();
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    this.setState({ votperror : error.response.data.message });
                } else if (error.response.status === 409) {
                    this.setState({ votperror : error.response.data.message });
                } else if (error.response.status === 422) {
                    this.setState({ votperror : error.response.data.otp[0] });
                }
            });
    }

    resendotp(e) {
        e.preventDefault();

        const { rphoneno } = this.state;
        const requestHeaders = {
            headers : {
                'Content-Type' : 'application/json'
            }
        };
        const requestParams = {
            'phone' : rphoneno
        };

        API
            .post('otp-resend', requestParams, requestHeaders)
            .then((response) => {
                // console.log('AXIOS POST: Response: ');
                // console.log(response.data);
            })
            .catch((error) => {
                // console.log('AXIOS POST: Error: ');
                // console.log(error);
            });
    }

    forgotpassword(e) {
        e.preventDefault();
        this.setState({
            fperror      : '',
            fpemailerror : ''
        });

        const { fpemail } = this.state;
        const requestHeaders = {
            headers : {
                'Content-Type' : 'application/json'
            }
        };
        const requestParams = {
            'email' : fpemail
        };

        API
            .post('forgetpassword', requestParams, requestHeaders)
            .then((response) => {
                this.setState({ fpemail : '' });
                this.closeForgotPasswordModal();
                this.showForgotPasswordStatusModal();
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    this.setState({ fperror : error.response.data.message });
                }
                if (error.response.status === 422) {
                    this.setState({ fpemailerror : error.response.data.email[0] });
                }
            });
    }

    resetPassword() {
        this.setState({
            rperror         : '',
            rppassworderror : ''
        });

        const { resetpwdtoken } = this.props;
        const { rppassword } = this.state;
        const requestHeaders = {
            headers : {
                'Content-Type' : 'application/json'
            }
        };
        const requestParams = {
            'password' : rppassword
        };

        API
            .post('reset-password/' + resetpwdtoken, requestParams, requestHeaders)
            .then((response) => {
                this.setState({ rppassword : '' });
                this.hideResetPwdModal();
                this.toggleResetPasswordStatusModal();
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    this.setState({ rperror : error.response.data.message });
                }
                if (error.response.status === 422) {
                    this.setState({ rppassworderror : error.response.data.password[0] });
                }
            });
    }

    render() {
        const { showsigninmodal, showsignupmodal, showresetpwdmodal } = this.props;
        const {
            locations,
            modalVerifyOTPShown, modalOTPVeriStatusShown, modalForgotPasswordShown, modalForgotPasswordStatusShown, showresetpwdstatusmodal,
            lerror, rerror, votperror, fperror, rperror,
            lemailerror, lpassworderror, rnameerror, remailerror, rphoneerror, rlocationerror, rpassworderror, rconfpassworderror, fpemailerror, rppassworderror,
            lemail, lpassword, rname, remail, rphone, rlocation, rpassword, rconfpassword, votpdigit1, votpdigit2, votpdigit3, votpdigit4, fpemail, rppassword } = this.state;

        return (
            <div className="container">
                <Modal show={showsigninmodal} onHide={this.closeSigninModal} size="sm" aria-labelledby="modal-signin" centered>
                    <Modal.Header closeButton>
                        <Modal.Title id="modal-signin"></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="mx-auto modal-logo mb-4">
                            <img src={require("../images/logos/anshik-logo.png")} className="img-fluid" alt="Anshik Logo" />
                        </div>

                        {
                            (lerror !== '') &&
                            <div className="pg-xxxx-mform-error mb-2">{lerror}</div>
                        }

                        <div className="pg-xxxx-mform-group">
                            <label htmlFor="lemail" className="modal-form-label">Email/Mobile</label>
                            <input type="text" className="form-control modal-form-control" id="lemail" 
                                placeholder="Email/Mobile" value={lemail} onChange={this.handleLEmailChange} />
                        </div>
                        {
                            (lemailerror !== '') &&
                            <div className="pg-xxxx-mform-error text-left">{lemailerror}</div>
                        }

                        <div className="pg-xxxx-mform-group">
                            <label htmlFor="lpassword" className="modal-form-label">Password</label>
                            <input type="password" className="form-control modal-form-control" id="lpassword" 
                                placeholder="Password" value={lpassword} onChange={this.handleLPasswordChange} />
                        </div>
                        {
                            (lpassworderror !== '') &&
                            <div className="pg-xxxx-mform-error text-left">{lpassworderror}</div>
                        }

                        <label className="d-block text-right forgot-pass-link" onClick={this.onForgotPasswordLinkClick}>Forgot Password?</label>
                        <button type="button" className="mt-4 btn btn-block btn-signin" onClick={this.signin}>Sign In</button>
                        <label className="d-block mt-3 mb-3 text-center or-text">or</label>

                        <div className="pg-xxxx-mform-group">
                            <div className="row no-gutters">
                                
                                
                                <div className="col-6">
                                    <FacebookOAuth setModalState={this.setModalState} />
                                </div>
                                <div className="col-6">
                                    <GoogleOAuth setModalState={this.setModalState} />
                                </div>
                               
                                {/* <div className="col-12 d-flex justify-content-center">
                                    <GoogleOAuth setModalState={this.setModalState} />
                                </div> */}
                            </div>
                        </div>

                        <label className="d-block mt-4 text-center">
                            <span className="signup-text1">Don't have an account? </span>
                            <span className="signup-text2" onClick={this.onSignupLinkClick}>Sign Up</span>
                        </label>
                    </Modal.Body>
                </Modal>

                <Modal show={showsignupmodal} onHide={this.closeSignupModal} size="sm" aria-labelledby="modal-signup" centered>
                    <Modal.Header closeButton>
                        <Modal.Title id="modal-signup"></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="mx-auto modal-logo mb-4">
                            <img src={require("../images/logos/anshik-logo.png")} className="img-fluid" alt="Anshik Logo" />
                        </div>

                        {
                            (rerror !== '') &&
                            <div className="pg-xxxx-mform-error mb-2">{rerror}</div>
                        }

                        <div className="pg-xxxx-mform-group">
                            <label htmlFor="rname" className="modal-form-label">Name</label>
                            <input type="text" className="form-control modal-form-control" id="rname" 
                                placeholder="Your Name" value={rname} onChange={this.handleRNameChange} />
                        </div>
                        {
                            (rnameerror !== '') &&
                            <div className="pg-xxxx-mform-error text-left">{rnameerror}</div>
                        }

                        <div className="pg-xxxx-mform-group">
                            <label htmlFor="remail" className="modal-form-label">Email</label>
                            <input type="text" className="form-control modal-form-control" id="remail" 
                                placeholder="Your Email" value={remail} onChange={this.handleREmailChange} />
                        </div>
                        {
                            (remailerror !== '') &&
                            <div className="pg-xxxx-mform-error text-left">{remailerror}</div>
                        }

                        <div className="pg-xxxx-mform-group">
                            <label htmlFor="rphone" className="modal-form-label">Mobile</label>
                            <input type="text" className="form-control modal-form-control" id="rphone" 
                                placeholder="Your Mobile" value={rphone} onChange={this.handleRPhoneChange} />
                        </div>
                        {
                            (rphoneerror !== '') &&
                            <div className="pg-xxxx-mform-error text-left">{rphoneerror}</div>
                        }

                        <div className="pg-xxxx-mform-group">
                            <label htmlFor="rlocation" className="modal-form-label">Location</label>
                            <select className="form-control modal-form-control" id="rlocation" 
                                defaultValue={rlocation} onChange={this.handleRLocationChange}>
                                <option value="0">Select</option>
                            {
                                locations.map((loc) => {
                                    return( <option key={loc.id} value={loc.id}>{loc.title}</option> );
                                })
                            }
                            </select>
                        </div>
                        {
                            (rlocationerror !== '') &&
                            <div className="pg-xxxx-mform-error text-left">{rlocationerror}</div>
                        }

                        <div className="pg-xxxx-mform-group">
                            <label htmlFor="rpassword" className="modal-form-label">Password</label>
                            <input type="password" className="form-control modal-form-control" id="rpassword" 
                                placeholder="Create Password" value={rpassword} onChange={this.handleRPasswordChange} />
                        </div>
                        {
                            (rpassworderror !== '') &&
                            <div className="pg-xxxx-mform-error text-left">{rpassworderror}</div>
                        }

                        <div className="pg-xxxx-mform-group">
                            <label htmlFor="rconfpassword" className="modal-form-label">Confirm Password</label>
                            <input type="password" className="form-control modal-form-control" id="rconfpassword" 
                                placeholder="Re-enter Password" value={rconfpassword} onChange={this.handleRConfPasswordChange} />
                        </div>
                        {
                            (rconfpassworderror !== '') &&
                            <div className="pg-xxxx-mform-error text-left">{rconfpassworderror}</div>
                        }

                        <button type="button" className="mt-4 btn btn-block btn-signin" onClick={this.signup}>Sign Up</button>
                        <label className="d-block mt-3 mb-3 text-center or-text">or</label>

                        <div className="pg-xxxx-mform-group">
                            <div className="row no-gutters">
                                
                                <div className="col-6">
                                    <FacebookOAuth setModalState={this.setModalState} />
                                </div>
                                <div className="col-6">
                                    <GoogleOAuth setModalState={this.setModalState} />
                                </div>
                               
                                {/* <div className="col-12 d-flex justify-content-center">
                                    <GoogleOAuth setModalState={this.setModalState} />
                                </div> */}
                            </div>
                        </div>

                        <label className="d-block mt-4 text-center">
                            <span className="signup-text1">Already have an account? </span>
                            <span className="signup-text2" onClick={this.onSigninLinkClick}>Sign In</span>
                        </label>
                    </Modal.Body>
                </Modal>

                <Modal show={modalVerifyOTPShown} onHide={this.closeVerifyOTPModal} size="sm" aria-labelledby="modal-verifyotp" centered>
                    <Modal.Header closeButton>
                        <Modal.Title id="modal-verifyotp"></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="mx-auto modal-logo mb-4">
                            <img src={require("../images/logos/anshik-logo.png")} className="img-fluid" alt="Anshik Logo" />
                        </div>

                        {
                            (votperror !== '') &&
                            <div className="pg-xxxx-mform-error mb-2">{votperror}</div>
                        }

                        <div className="pg-xxxx-mform-group">
                            <label className="modal-form-label">Waiting for the OTP</label>
                            <div className="row no-gutters">
                                <div className="col-3">
                                    <input type="text" className="form-control modal-form-control votpdigit" id="votpdigit1"
                                        maxlength="1" value={votpdigit1} onChange={this.handleOTPDigit1Change} />
                                </div>
                                <div className="col-3">
                                    <input type="text" className="form-control modal-form-control votpdigit" id="votpdigit2"
                                        maxlength="1" value={votpdigit2} onChange={this.handleOTPDigit2Change} />
                                </div>
                                <div className="col-3">
                                    <input type="text" className="form-control modal-form-control votpdigit" id="votpdigit3"
                                        maxlength="1" value={votpdigit3} onChange={this.handleOTPDigit3Change} />
                                </div>
                                <div className="col-3">
                                    <input type="text" className="form-control modal-form-control votpdigit" id="votpdigit4"
                                        maxlength="1" value={votpdigit4} onChange={this.handleOTPDigit4Change} />
                                </div>
                            </div>
                        </div>

                        <div className="pg-xxxx-mform-group mt-4">
                            <div className="row">
                                <div className="col-7">
                                    <label className="modal-form-label">Didn't receive OTP?</label>
                                </div>
                                <div className="col-5">
                                    <button type="button" className="btn btn-block btn-resendotp" 
                                        onClick={this.resendotp}>Resend</button>
                                </div>
                            </div>
                        </div>

                        <button type="button" className="mt-4 btn btn-block btn-signin" 
                            onClick={this.verifyotp}>Verify</button>
                    </Modal.Body>
                </Modal>

                <Modal show={modalOTPVeriStatusShown} onHide={this.closeOTPVeriStatusModal} size="sm" aria-labelledby="modal-otpveristatus" centered>
                    <Modal.Header closeButton>
                        <Modal.Title id="modal-otpveristatus"></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="text-center">
                            <img src={require("../images/icons/success.png")} className="img-fluid" alt="Success Icon" />
                        </div>

                        <div className="pg-xxxx-mform-group mt-4">
                            <label className="d-block text-center otpveri-status">Success</label>
                            <label className="d-block text-center otpveri-message">Account successfully created</label>
                        </div>

                        <button type="button" className="mt-5 d-flex mx-auto btn btn-signin btn-done" 
                            onClick={this.closeOTPVeriStatusModal}>Done</button>
                    </Modal.Body>
                </Modal>

                <Modal show={modalForgotPasswordShown} onHide={this.closeForgotPasswordModal} size="sm" aria-labelledby="modal-forgotpassword" centered>
                    <Modal.Header closeButton>
                        <Modal.Title id="modal-forgotpassword"></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="mx-auto modal-logo mb-4">
                            <img src={require("../images/logos/anshik-logo.png")} className="img-fluid" alt="Anshik Logo" />
                        </div>

                        {
                            (fperror !== '') &&
                            <div className="pg-xxxx-mform-error mb-2">{fperror}</div>
                        }

                        <div className="pg-xxxx-mform-group">
                            <label htmlFor="fpemail" className="modal-form-label">Email</label>
                            <input type="text" className="form-control modal-form-control" id="fpemail" 
                                placeholder="Email" value={fpemail} onChange={this.handleFPEmailChange} />
                        </div>
                        {
                            (fpemailerror !== '') &&
                            <div className="pg-xxxx-mform-error text-left">{fpemailerror}</div>
                        }

                        <button type="button" className="mt-4 btn btn-block btn-signin" onClick={this.forgotpassword}>Submit</button>

                        <label className="d-block mt-4 text-center">
                            <span className="signup-text1">Already have an account? </span>
                            <span className="signup-text2" onClick={this.onSigninLinkClick2}>Sign In</span>
                        </label>
                    </Modal.Body>
                </Modal>

                <Modal show={modalForgotPasswordStatusShown} onHide={this.closeForgotPasswordStatusModal} size="sm" aria-labelledby="modal-fpassstatus" centered>
                    <Modal.Header closeButton>
                        <Modal.Title id="modal-fpassstatus"></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="text-center">
                            <img src={require("../images/icons/success.png")} className="img-fluid" alt="Success Icon" />
                        </div>

                        <div className="pg-xxxx-mform-group mt-4">
                            <label className="d-block text-center otpveri-status">Success</label>
                            <label className="d-block text-center otpveri-message">An email with instructions on 
                                how to reset your password was sent to your registered email</label>
                        </div>

                        <button type="button" className="mt-5 d-flex mx-auto btn btn-signin btn-done" 
                            onClick={this.closeForgotPasswordStatusModal}>Done</button>
                    </Modal.Body>
                </Modal>

                <Modal show={showresetpwdmodal} onHide={this.hideResetPwdModal.bind(this)} size="sm" aria-labelledby="modal-resetpassword" centered>
                    <Modal.Header closeButton>
                        <Modal.Title id="modal-resetpassword"></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="mx-auto modal-logo mb-4">
                            <img src={require("../images/logos/anshik-logo.png")} className="img-fluid" alt="Anshik Logo" />
                        </div>

                        {
                            (rperror !== '') &&
                            <div className="pg-xxxx-mform-error mb-2">{rperror}</div>
                        }

                        <div className="pg-xxxx-mform-group">
                            <label htmlFor="rppassword" className="modal-form-label">New Password</label>
                            <input type="password" className="form-control modal-form-control" id="rppassword" 
                                placeholder="Password" value={rppassword} onChange={this.handleRPPasswordChange} />
                        </div>
                        {
                            (rppassworderror !== '') &&
                            <div className="pg-xxxx-mform-error text-left">{rppassworderror}</div>
                        }

                        <button type="button" className="mt-4 btn btn-block btn-signin" onClick={this.resetPassword}>Reset Password</button>
                    </Modal.Body>
                </Modal>

                <Modal show={showresetpwdstatusmodal} onHide={this.toggleResetPasswordStatusModal} size="sm" aria-labelledby="modal-resetpasswordstatus" centered>
                    <Modal.Header closeButton>
                        <Modal.Title id="modal-resetpasswordstatus"></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="text-center">
                            <img src={require("../images/icons/success.png")} className="img-fluid" alt="Success Icon" />
                        </div>

                        <div className="pg-xxxx-mform-group mt-4">
                            <label className="d-block text-center otpveri-status">Success</label>
                            <label className="d-block text-center otpveri-message">Your password was reset successfully</label>
                        </div>

                        <button type="button" className="mt-5 d-flex mx-auto btn btn-signin btn-done" 
                            onClick={this.toggleResetPasswordStatusModal}>Done</button>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

Modals.protoTypes = {
    setAppState : PropTypes.func
};

export default Modals;