import React, { Component }    from 'react';
import Img                     from 'react-image';
import { Link }                from 'react-router-dom';
import FontAwesome             from 'react-fontawesome';
import Slider                  from 'react-styled-carousel-am';
import ReactImageVideoLightbox from '../../components/react-image-video-lightbox/index';
import { toCurrency }          from '../../helpers/Formatters';
import { getYoutubeEmbedURL }  from '../../helpers/StringUtil';


class PropertyTimeOrProfitBased extends Component {
    constructor(props) {
        super(props);
        this.state = {
            adstatus            : null,
            daysorprofit        : '',
            pvideoandimages     : [],
            showimagevideomodal : false
        };

        this.toggleImageVideoModal = this.toggleImageVideoModal.bind(this);
    }

    componentDidMount() {
        const { categorydata, propertydata } = this.props;

        let adstatus = '';
        if (propertydata.property_status === "0") {
            adstatus = <div className="pg-6ef4-ad-status ad-status-new">NEW</div>;
        } else if (propertydata.property_status === "1") {
            adstatus = <div className="pg-6ef4-ad-status ad-status-used">USED</div>;
        }

        let daysorprofit = '';
        if (categorydata.category === "Automotive") {
            daysorprofit = ' days';
        } else if (categorydata.category === "Real Estate" || categorydata.category === "Business") {
            daysorprofit = '% Profit';
        }

        let pvideoandimages = [];
        let videourl = propertydata.video_url;
        if (videourl !== '') {
            let embedurl = getYoutubeEmbedURL(videourl);
            pvideoandimages.push({
                url    : embedurl,
                type   : 'video',
                altTag : ''
            });
        }

        let images = propertydata.image;
        images.forEach((image) => {
            pvideoandimages.push({
                url    : image,
                type   : 'photo',
                altTag : ''
            });
        });

        this.setState({
            adstatus        : adstatus,
            daysorprofit    : daysorprofit,
            pvideoandimages : pvideoandimages
        });
    }

    toggleImageVideoModal() {
        this.setState(prevState => ({
            showimagevideomodal : !prevState.showimagevideomodal
        }));
    }

    calcpbarstyle(shareperc) {
        var pbarstyle = { width : shareperc + '%' };
        return pbarstyle;
    }

    render() {
        const { categorydata, propertydata, groupdata } = this.props;
        const { adstatus, daysorprofit, pvideoandimages, showimagevideomodal } = this.state;
        const responsive = [
            { breakPoint: 768, cardsToShow: 4 }, // this will be applied if screen size is greater than 1280px. cardsToShow will become 4.
            { breakPoint: 480, cardsToShow: 3 },
            { breakPoint: 0, cardsToShow: 2 },
          ];

        return(
            <React.Fragment>
                <section className="pg-6ef4-ad-det-section">
                    <div className="container">
                        <div className="row no-mobile">
                            <div className="col-12">
                                <div className="pg-6ef4-ad-catg-img-wrapper">
                                    <Img src={categorydata.image} alt="..." className="img-fluid" />
                                    {adstatus}
                                </div>

                                <div className="pg-6ef4-ad-det-wrapper">
                                    <div className="row align-items-center">
                                        <div className="col-9 pg-6ef4-ad-det-col1">
                                            <p className="pg-6ef4-ad-category">{categorydata.category}</p>
                                            <p className="pg-6ef4-ad-title">{propertydata.title}</p>
                                            <div className="row pg-6ef4-ad-location-user-row">
                                                <div className="col-12">
                                                    <span>
                                                        <img src={require("../../images/icons/ad-location.png")} alt="Location Icon" />
                                                        <span className="ml-2 pg-6ef4-ad-location">{propertydata.location}</span>
                                                    </span>
                                                    <span className="ml-3">
                                                        <img src={require("../../images/icons/ad-by.png")} alt="User Icon" />
                                                        <span className="ml-2 pg-6ef4-ad-by">{propertydata.user_name}</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="pg-6ef4-ad-description">
                                                <span>{propertydata.description}</span>
                                            </div>
                                        </div>

                                        <div className="col-3">
                                            <div className="pg-6ef4-ad-tprice-wrapper">
                                                <p className="pg-6ef4-ad-tprice-label">Total Price</p>
                                                <p className="pg-6ef4-ad-tprice-value">
                                                    <FontAwesome name="rupee" /> {toCurrency(propertydata.price)}</p>

                                                <div className="row">
                                                    <div className="col-12">
                                                        <Link to={'/propertyint/' + propertydata._id} 
                                                            className="btn btn-block apf-button interest-link">Join</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row pg-6ef4-ad-toggle-controls-row">
                                        <div className="col-12">
                                            <a className="pg-6ef4-sh-link" href="#ad-full-desc">View full details</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* mobile only view */}
                        <div className="row mobile">
                            <div className="col-12 pg-6ef4-ad-catg-img-wrapper">
                                <Img src={categorydata.image} alt="..." className="img-fluid" />
                                {adstatus}
                            </div>
                            <div className="col-12 pg-6ef4-ad-det-col1">
                                            <p className="pg-6ef4-ad-category">{categorydata.category}</p>
                                            <p className="pg-6ef4-ad-title">{propertydata.title}</p>
                                            <div className="row pg-6ef4-ad-location-user-row">
                                                <div className="col-12">
                                                    <span>
                                                        <img src={require("../../images/icons/ad-location.png")} alt="Location Icon" />
                                                        <span className="ml-2 pg-6ef4-ad-location">{propertydata.location}</span>
                                                    </span>
                                                    <span className="ml-3">
                                                        <img src={require("../../images/icons/ad-by.png")} alt="User Icon" />
                                                        <span className="ml-2 pg-6ef4-ad-by">{propertydata.user_name}</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="pg-6ef4-ad-description">
                                                <span>{propertydata.description}</span>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <a className="pg-6ef4-sh-link" href="#ad-full-desc">View full details</a>
                                        </div>
                                        <div className="col-12">
                                            <div className="pg-6ef4-ad-tprice-wrapper">
                                                <p className="pg-6ef4-ad-tprice-label">Total Price</p>
                                                <p className="pg-6ef4-ad-tprice-value">
                                                    <FontAwesome name="rupee" /> {toCurrency(propertydata.price)}</p>

                                                <div className="row">
                                                    <div className="col-12">
                                                        <Link to={'/propertyint/' + propertydata._id} 
                                                            className="btn btn-block apf-button interest-link">Join</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                        </div>
                    </div>
                </section>

                <section className="pg-6ef4-ad-imgs-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <Slider autoSlide={false} showDots={false} hideArrowsOnNoSlides={false} padding={'0px 20px'} responsive={responsive}>
                                    <div className="pg-6ef4-ad-video-wrapper" onClick={this.toggleImageVideoModal}>
                                        <Img src={propertydata.image[0]} alt="..." className="img-fluid" />
                                        <div className="pg-6ef4-video-play-icon"></div>
                                    </div>
                                    {
                                        propertydata.image.map((pimage, index) => {
                                            return(
                                                <div key={'pimage' + index} className="pg-6ef4-ad-img-wrapper" onClick={this.toggleImageVideoModal}>
                                                    <Img src={pimage} alt="..." className="img-fluid" />
                                                </div>
                                            );
                                        })
                                    }
                                </Slider>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="pg-6ef4-gd-int-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="row pg-6ef4-sect-heading-row">
                                    <div className="col-12">
                                        <span className="pg-6ef4-section-heading">Group Details</span>
                                    </div>
                                </div>

                                <div className="row pg-6ef4-gd-users-row">
                                {
                                    groupdata.map((groupi, index) => {
                                        return(
                                            <div key={'groupi' + index} className="col-12 col-md-6">
                                                <div className="row pg-6ef4-gd-user-row align-items-end">
                                                    <div className="col-3 col-md-2">
                                                        <Img src={require("../../images/icons/user-in-group.png")} alt="..." />
                                                    </div>
                                                    <div className="col-9 col-md-10">
                                                        <div className="row pg-6ef4-gd-ushare-row">
                                                            <div className="col-6">
                                                                <span className="pg-6ef4-gd-ushare">
                                                                    <FontAwesome name="rupee" /> {toCurrency(groupi.user_interest_share_amount)}</span>
                                                            </div>
                                                            <div className="col-6 text-right">
                                                                <span className="pg-6ef4-gd-ushare">{groupi.user_interest_share_days}{daysorprofit}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="pg-6ef4-gd-us-pbar">
                                                                    <div className="pg-6ef4-gd-us-pbar-seek"
                                                                        style={this.calcpbarstyle(groupi.user_interest_share)}>
                                                                        {groupi.user_interest_share}%
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                                </div>

                                <div className="row text-center">
                                    <div className="col-12">
                                        <Link to={'/propertyint/' + propertydata._id} 
                                            className="btn apf-button pg-6ef4-int-btn">Join</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="pg-6ef4-desc-section" id="ad-full-desc">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="row pg-6ef4-sect-heading-row">
                                    <div className="col-12">
                                        <span className="pg-6ef4-section-heading">Description</span>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <p className="pg-6ef4-desc">{propertydata.description}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {
                    showimagevideomodal &&
                    <ReactImageVideoLightbox
                        data={pvideoandimages}
                        startIndex={0}
                        showResourceCount={false}
                        onCloseCallback={this.toggleImageVideoModal} />
                }
            </React.Fragment>
        );
    }
}

export default PropertyTimeOrProfitBased;