import                                './Properties.css';
import React, { Component }      from 'react';
import Select                    from 'react-select';
import { Link }                  from 'react-router-dom';
import FontAwesome               from 'react-fontawesome';
import API                       from '../../helpers/API';
import { imageloader }           from '../../helpers/Constants';
import PropertySearch            from '../../components/PropertySearch';
import PropertyKissan            from './PropertyKissan';
import PropertyTimeOrProfitBased from './PropertyTimeOrProfitBased';

class Properties extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sortddoptions        : [],
            properties           : null,
            isfetching           : false,
            currentpage          : 0,
            numberofpages        : 0,
            resultscount         : 0,
            listingtype          : '',
            titleorder           : 'normal',
            priceorder           : 'normal',
        };

        this.loadSortDropdownOptions = this.loadSortDropdownOptions.bind(this);
        this.loadProperties          = this.loadProperties.bind(this);
        this.handleSortOptionChange  = this.handleSortOptionChange.bind(this);
    }

    componentDidMount() {
        this.loadSortDropdownOptions();
        this.loadProperties('normal', 'normal', 0);
    }

    loadSortDropdownOptions() {
        let sortddoptions = [
            { value : 'tasc', label : 'Title Ascending' },
            { value : 'tdes', label : 'Title Descending' },
            { value : 'pasc', label : 'Price Ascending' },
            { value : 'pdes', label : 'Price Descending' }
        ];

        this.setState({ sortddoptions : sortddoptions });
    }

    loadProperties(titleorder, priceorder, currentpage) {
        const { properties } = this.state;

        // NAVIGATION FROM SEARCH SECTION
        //  1. FROM BANNER SECTION OF HOME PAGE
        //  2. FROM SEARCH COMPONENT OF PROPERTIES LISTING PAGE
        //  3. FROM SEARCH COMPONENT OF PROPERTY PAGE
        if (this.props.location.pathname === '/properties/search' || 
            this.props.location.pathname === '/properties/compsearch') {
            const qstring = require('query-string');
            const qparams = qstring.parse(this.props.location.search);

            let searchparams = {};
            if (qparams.smode === '0') {
                searchparams = {
                    'search_mode' : 0,
                    'keyword'     : qparams.key,
                    'category_id' : qparams.cid
                };
            } else {
                searchparams = {
                    'search_mode'   : 1,
                    'keyword'       : qparams.key,
                    'category_id'   : qparams.cid,
                    'location_id'   : qparams.lid,
                    'property_mode' : qparams.pmode,
                    'min_price'     : qparams.minprice,
                    'max_price'     : qparams.maxprice
                };
            }

            let pageno = currentpage + 1;
            let listingtype = 'Search (' + qparams.key + ')';

            this.setState({
                isfetching  : true,
                currentpage : pageno,
                listingtype : listingtype
            });

            API
                .post('property-details-search?page=' + pageno, searchparams)
                .then((response) => {
                    let responsedata = response.data.propertydetails;
                    this.setState({
                        properties    : (pageno === 1) ? responsedata.data : properties.concat(responsedata.data),
                        currentpage   : responsedata.current_page,
                        numberofpages : responsedata.last_page,
                        resultscount  : responsedata.total,
                        isfetching    : false
                    });
                });
        }
        // NAVIGATION FROM HOME > VIEW MORE
        // NAVIGATION FROM CATEGORY/SUBCATEGORY ICON/LINK CLICK
        else {
            let location    = this.props.match.params.location;
            let category    = this.props.match.params.category;
            let subcategory = this.props.match.params.subcategory;

            let requesturl = '';
            let listingtype = '';

            // NAVIGATION FROM HOME > VIEW MORE
            if (this.props.match.params.section !== undefined) {
                let section = this.props.match.params.section;

                if (section === 'offer') {
                    listingtype = 'Offer Ads';
                    requesturl  = 'property-details-offer-ads-sort/' + category + '/' + location + '/' + titleorder + '/' + priceorder;
                } else if (section === 'recent') {
                    listingtype = 'Recent Ads';
                    requesturl  = 'property-details-recent-ads-sort/' + location + '/' + category + '/' + titleorder + '/' + priceorder;
                } else if (section === 'kissan') {
                    listingtype = 'Kissan';
                    requesturl  = 'property-details-kissan-ads-sort/' + location + '/' + titleorder + '/' + priceorder;
                } else if (section === 'other') {
                    listingtype = 'Other Ads';
                    requesturl  = 'property-details-other-ads-sort/' + location + '/' + titleorder + '/' + priceorder;
                }
            }
            // NAVIGATION FROM CATEGORY/SUBCATEGORY ICON/LINK CLICK
            else {
                if (category === '5e6612586d4437782916dd24') {
                    listingtype = 'Automotive';
                } else if (category === '5e661296c44aa032797a1c72') {
                    listingtype = 'Real Estate';
                } else if (category === '5e6612c1992fb52d286478f5') {
                    listingtype = 'Business';
                } else if (category === '5e6612f28d757322385c17e5') {
                    listingtype = 'Kissan';
                } else if (category === '5e6612378d757322385c17e4') {
                    listingtype = 'Others';
                } else if (category === '0') {
                    listingtype = 'All Ads';
                }

                if (subcategory === '0') {
                    requesturl = 'property-details-sort/' + category + '/' + location + '/' + titleorder + '/' + priceorder;
                } else {
                    listingtype = listingtype + ' (' + this.props.match.params.scatgname + ')';
                    requesturl  = 'property-details-subcategorized-ads-sort/' + category + '/' + subcategory + '/' + titleorder + '/' + priceorder;
                }
            }

            let pageno = currentpage + 1;

            this.setState({
                isfetching  : true,
                currentpage : pageno,
                listingtype : listingtype
            });

            API
                .get(requesturl + '?page=' + pageno)
                .then((response) => {
                    let responsedata = response.data.propertydetails;
                    this.setState({
                        properties    : (pageno === 1) ? responsedata.data : properties.concat(responsedata.data),
                        currentpage   : responsedata.current_page,
                        numberofpages : responsedata.last_page,
                        resultscount  : responsedata.total,
                        isfetching    : false
                    });
                });
        }
    }

    handleSortOptionChange(newValue: any, actionMeta: any) {
        var id = newValue.value;

        let titleorder = '';
        let priceorder = '';
        let properties = null;

        if (id === 'tasc') {
            titleorder = 'asc';
            priceorder = 'normal';
        } else if (id === 'tdes') {
            titleorder = 'desc';
            priceorder = 'normal';
        } else if (id === 'pasc') {
            titleorder = 'normal';
            priceorder = 'min';
        } else if (id === 'pdes') {
            titleorder = 'normal';
            priceorder = 'max';
        }

        this.setState({
            currentpage   : 0,
            numberofpages : 0,
            resultscount  : 0,
            properties    : properties,
            titleorder    : titleorder,
            priceorder    : priceorder
        });
        this.loadProperties(titleorder, priceorder, 0);
    }

    render() {
        const { categories, locations, searchMode, normalSearchCategory, normalSearchValue, advSearchLocation } = this.props;
        const { isfetching, listingtype, resultscount, sortddoptions, currentpage, numberofpages, properties, titleorder, priceorder } = this.state;

        return(
            <React.Fragment>
                <PropertySearch
                    {...this.props}
                    categories={categories}
                    locations={locations}
                    searchMode={searchMode}
                    normalSearchCategory={normalSearchCategory}
                    normalSearchValue={normalSearchValue}
                    advSearchLocation={advSearchLocation} />

                <section className="properties-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 back-link-wrapper">
                                <Link to="/" className="properties-back-link">
                                    <FontAwesome name="angle-left" className="properties-back-icon" />
                                    <span className="properties-back-text">Back</span>
                                </Link>
                            </div>
                        </div>

                        <div className="row pg-tmr5-catg-rcount-wrapper">
                            <div className="col-6">
                                <span className="pg-tmr5-props-category">{listingtype}</span>
                            </div>
                            <div className="col-6 text-right">
                                <span className="pg-tmr5-props-result-count">{resultscount} 
                                    {resultscount < 2 ? ' Result' : ' Results'}</span>
                            </div>
                        </div>

                        <div className="row no-gutters pg-tmr5-fs-wrapper-row">
                            <div className="col-12 pg-tmr5-fs-wrapper-col">
                                <div className="row h-100">
                                    <div className="col-6">
                                        <div className="h-100 pg-tmr5-fs-wrapper-left"></div>
                                    </div>
                                    <div className="col-6">
                                        <div className="row h-100">
                                            <div className="col-6 text-right">
                                                <span className="pg-tmr5-filterdd-header">Filter</span>
                                            </div>
                                            <div className="col-6">
                                                <Select
                                                    options={sortddoptions}
                                                    placeholder="Sorting"
                                                    isSearchable={false}
                                                    className="pg-tmr5-sselect-o"
                                                    classNamePrefix="pg-tmr5-sselect"
                                                    components={{ IndicatorSeparator : null }}
                                                    onChange={this.handleSortOptionChange} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            properties !== null &&
                            <React.Fragment>
                            {
                                properties.length === 0 ? (
                                    <div className="row pg-tmr5-noads-text-row align-items-center">
                                        <div className="col-12">No data found</div>
                                    </div>
                                ) : (
                                    <div className="row">
                                        <div className="col-12">
                                        {
                                            properties.map((property) => {
                                                let adstatus = '';
                                                if (property.property_details.property_status === "0") {
                                                    adstatus = <div className="pg-tmr5-ad-status ad-status-new">NEW</div>;
                                                } else if (property.property_details.property_status === "1") {
                                                    adstatus = <div className="pg-tmr5-ad-status ad-status-used">USED</div>;
                                                }

                                                if (property.property_details.kissan_mode === "1") {
                                                    return (
                                                        <PropertyKissan
                                                            key={property.property_details._id}
                                                            imageloader={imageloader}
                                                            property={property}
                                                            adstatus={adstatus} />
                                                    );
                                                } else {
                                                    return (
                                                        <PropertyTimeOrProfitBased
                                                            key={property.property_details._id}
                                                            imageloader={imageloader}
                                                            property={property}
                                                            adstatus={adstatus} />
                                                    );
                                                }
                                            })
                                        }
                                        </div>
                                    </div>
                                )
                            }
                            </React.Fragment>
                        }

                        <div className="row">
                            <div className="col-12">
                            {
                                (currentpage < numberofpages) &&
                                <button type="button" className="btn-block view-more-button" disabled={isfetching} 
                                    onClick={() => this.loadProperties(titleorder, priceorder, currentpage)}>
                                    <span className="view-more-link">View More</span>&nbsp;&nbsp;
                                    <img src={require("../../images/icons/view-more.png")} alt="..." />
                                </button>
                            }
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default Properties;