import React, { Component } from 'react';
import { Link }             from 'react-router-dom';

class PageFooter extends Component {
    render() {
        return(
            <section id="footer" className="footer-section">
                <div className="container">
                    <div className="footer-container row pt-5">
                        <div className="col-12 col-lg-3 col-xl-3">
                            <div className="card mb-4 footer-card">
                                <div className="card-body footer-card-body">
                                    <img src={require("../images/logos/anshik-logo.png")} className="d-block img-fluid footer-logo" 
                                        alt="Anshik Logo" />
                                    <p className="mt-3 product-caption">Own Impossible With a Little</p>
                                    <div className="row">
                                        <div className="col-6">
                                            <img src={require("../images/logos/app-store-logo.png")} className="d-block" alt="App Store Logo" />
                                        </div>
                                        <div className="col-6">
                                            <img src={require("../images/logos/google-play-logo.png")} className="d-block" alt="Play Store Logo" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 col-xl-3">
                            <div className="card mb-4 footer-card">
                                <div className="card-body footer-card-body">
                                    <h4 className="card-title mb-4 footer-category-title">Available Locations</h4>
                                    <ul className="card-text footer-subcategory-list">
                                        <li className="footer-subcategory-item">Mumbai</li>
                                        <li className="footer-subcategory-item">Delhi</li>
                                        <li className="footer-subcategory-item">Bangalore</li>
                                        <li className="footer-subcategory-item">Hyderabad</li>
                                        <li className="footer-subcategory-item">Ahmedabad</li>
                                        <li className="footer-subcategory-item">Kolkata</li>
                                        <li className="footer-subcategory-item">Surat</li>
                                        <li className="footer-subcategory-item">Pune</li>
                                        <li className="footer-subcategory-item">More</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 col-xl-3">
                            <div className="card mb-4 footer-card">
                                <div className="card-body footer-card-body">
                                    <h4 className="card-title mb-4 footer-category-title">Quick Links</h4>
                                    <ul className="card-text footer-subcategory-list">
                                        <li className="footer-subcategory-item">
                                            <Link to={'/'}>Home</Link>
                                        </li>
                                        <li className="footer-subcategory-item">About Us</li>
                                        <li className="footer-subcategory-item">
                                            <Link to={'/properties/category/0/0/0'}>All Categories</Link>
                                        </li>
                                        <li className="footer-subcategory-item">Contact Us</li>
                                        <li className="footer-subcategory-item">
                                            <Link to={'/addpost'}>Post an Ad</Link>
                                        </li>
                                        <li className="footer-subcategory-item">Privacy Policy</li>
                                        <li className="footer-subcategory-item">FAQ</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 col-xl-3">
                            <div className="card mb-4 footer-card">
                                <div className="card-body footer-card-body">
                                    <h4 className="card-title mb-4 footer-category-title">Contact Info</h4>
                                    <ul className="card-text footer-subcategory-list">
                                        <li className="footer-subcategory-item">
                                            <img className="mr-2" src={require("../images/icons/location.png")} alt="Location" />
                                            <span>Office Address</span>
                                        </li>
                                        <li className="mt-2 footer-subcategory-item">
                                            <img className="mr-2" src={require("../images/icons/email.png")} alt="Email" />
                                            <span>info@aanshik.com</span>
                                        </li>
                                        <li className="mt-2 footer-subcategory-item">
                                            <img className="mr-2" src={require("../images/icons/phone.png")} alt="Phone" />
                                            <span>123 456 7890</span>
                                        </li>
                                        <li className="mt-4 footer-subcategory-item">
                                            <img src={require("../images/icons/social.png")} alt="Social Icons" />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col pt-4 pb-4 text-center footer-section-copyright">Copyright 2019 &copy; Aanshik. All Rights Reserved.</div>
                    </div>
                </div>
            </section>
        );
    }
}

export default PageFooter;