import React, { Component } from 'react';
import Select               from 'react-select';
import FontAwesome          from 'react-fontawesome';
import ImageUploader        from 'react-images-upload';
import                           './ImageUploader.css';
import PropTypes            from 'prop-types';
import { numregex }         from '../../helpers/Constants';
import { toCurrency }       from '../../helpers/Formatters';


class FormWizardStepTwo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            propertystatuses : [],
            pid              : '',
            ptitle           : this.props.ptitle,
            pdescr           : this.props.pdescr,
            pprice           : this.props.pprice,
            pvideo           : this.props.pvideo,
            pstatus          : this.props.pstatus,
            pimages          : this.props.pimages,
            pimagepreviews   : this.props.pimagepreviews
        };

        this.handleProdTitleChange  = this.handleProdTitleChange.bind(this);
        this.handleProdDescrChange  = this.handleProdDescrChange.bind(this);
        this.handleProdPriceChange  = this.handleProdPriceChange.bind(this);
        this.handleProdVideoChange  = this.handleProdVideoChange.bind(this);
        this.handleProdStatusChange = this.handleProdStatusChange.bind(this);
        this.handleImageChange      = this.handleImageChange.bind(this);
    }

    componentDidMount() {
        let propertystatuses = [
            { value :  '', label : 'Select Status' },
            { value : '0', label : 'New' },
            { value : '1', label : 'Used' },
            { value : '2', label : 'Running' }
        ];
        this.setState({ propertystatuses : propertystatuses });
    }

    handleProdTitleChange(e) {
        this.setState({ ptitle : e.target.value });
        this.props.updateAddPostFormState('ptitle', e.target.value);
    }
    handleProdDescrChange(e) {
        this.setState({ pdescr : e.target.value });
        this.props.updateAddPostFormState('pdescr', e.target.value);
    }
    handleProdPriceChange(e) {
        var value = e.target.value.replace(/,/g, '');
        if (value === '' || numregex.test(value)) {
            this.setState({ pprice : value });
            this.props.updateAddPostFormState('pprice', value);
            this.props.updateAddPostFormState('balanceamount', value);
        }
    }
    handleProdVideoChange(e) {
        this.setState({ pvideo : e.target.value });
        this.props.updateAddPostFormState('pvideo', e.target.value);
    }
    handleProdStatusChange(newValue: any, actionMeta: any) {
        this.setState({ pstatus : newValue });
        this.props.updateAddPostFormState('pstatus', newValue);
    }
    handleImageChange(picturefiles, picturedataurls) {
        this.setState({
            pimages        : picturefiles,
            pimagepreviews : picturedataurls
        });

        this.props.updateAddPostFormState('pimages', picturefiles);
        this.props.updateAddPostFormState('pimagepreviews', picturedataurls);
    }

    render() {
        const { ptitle, pdescr, pprice, pstatus, propertystatuses, pvideo, pimagepreviews } = this.state;

        return (
            <React.Fragment>
                <div className="row add-post-heading-row">
                    <div className="col-12">
                        <span className="add-post-heading">Property Details</span>
                    </div>
                </div>

                <div className="row add-post-form">
                    <div className="col-12">
                        <div className="row form-group">
                            <div className="col-12">
                                <label htmlFor="productTitle" className="control-label add-post-form-label">Product Title</label>
                                <input type="text" id="productTitle" className="form-control apf-textbox"
                                    value={ptitle} onChange={this.handleProdTitleChange} />
                            </div>
                        </div>
                        <div className="row form-group">
                            <div className="col-12">
                                <label htmlFor="productDesc" className="control-label add-post-form-label">Product Description</label>
                                <textarea id="productDesc" className="form-control apf-textarea" rows="4"
                                    value={pdescr} onChange={this.handleProdDescrChange}></textarea>
                            </div>
                        </div>
                        <div className="row form-group">
                            <div className="col-12 col-sm-4">
                                <label htmlFor="productPrice" className="control-label add-post-form-label">Product Price</label>
                                <div className="input-group apf-input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text"><FontAwesome name="rupee" /></span>
                                    </div>
                                    <input type="text" id="productPrice" className="form-control apf-textbox"
                                        value={pprice === '' ? '' : toCurrency(pprice)} onChange={this.handleProdPriceChange} />
                                </div>
                            </div>
                            <div className="col-12 col-sm-4">
                                <label htmlFor="productStatus" className="control-label add-post-form-label">Product Status</label>
                                <Select
                                    value={pstatus}
                                    options={propertystatuses}
                                    isSearchable={false}
                                    classNamePrefix="pg-ber9-apfselect"
                                    components={{ IndicatorSeparator : null }}
                                    onChange={this.handleProdStatusChange} />
                            </div>
                            <div className="col-12 col-sm-4">
                                <label htmlFor="productVideo" className="control-label add-post-form-label">Product Video URL</label>
                                <input type="text" id="productVideo" className="form-control apf-textbox"
                                    value={pvideo} onChange={this.handleProdVideoChange} />
                            </div>
                        </div>
                        <div className="row form-group">
                            <div className="col-12">
                                <label htmlFor="pg-ber9-image-files" className="control-label add-post-form-label">Gallery</label>
                                <div className="row">
                                    <div className="col-12">
                                        <ImageUploader
                                            name="pg-ber9-image-files"
                                            withIcon={false}
                                            onChange={this.handleImageChange}
                                            withLabel={false}
                                            buttonType="button"
                                            buttonText=""
                                            withPreview={true}
                                            maxFileSize={5242880}
                                            imgExtension={[".jpg", ".png"]}
                                            defaultImages={pimagepreviews} />
                                        <span className="add-post-form-gallery-label">Images</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

FormWizardStepTwo.protoTypes = {
    updateAddPostFormState : PropTypes.func
};

export default FormWizardStepTwo;