import React, { Component } from 'react';
import { Link }             from 'react-router-dom';
import Img                  from 'react-image';
import { imageloader }      from '../../helpers/Constants';

class KissanDeals extends Component {
    render() {
        const { category, location, showkissandeals, kissandeals } = this.props;

        return(
            <section id="kissan-deals" className={"ads-section" + (showkissandeals ? '' : ' d-none')}>
                <div className="container">
                    <div className="row">
                        <div className="col pt-5 pb-5 text-center ads-section-heading">Kissan Deals</div>
                    </div>

                    <div className="row">
                    {
                        kissandeals.map((kissandeal) => {
                            let adstatus = '';
                            if (kissandeal.property_status === "0") {
                                adstatus = <div className="ad-status ad-status-new">NEW</div>;
                            } else if (kissandeal.property_status === "1") {
                                adstatus = <div className="ad-status ad-status-used">USED</div>;
                            }

                            return(
                                <div className="mb-4 col-xl-3 col-6" key={kissandeal._id}>
                                    <div className="card ad-card">
                                        <div className="card-body ad-card-body">
                                            <div className="ad-img-wrapper">
                                                <Link to={'/propertyint/' + kissandeal._id}>
                                                    <Img
                                                        src={kissandeal.image} alt="..."
                                                        loader={ imageloader }
                                                        unloader={ imageloader }
                                                        className="img-fluid img-rounded ad-img" />
                                                </Link>
                                            </div>
                                            {adstatus}
                                            <p className="ad-category">{kissandeal.category}</p>
                                            <Link to={'/propertyint/' + kissandeal._id}>
                                                <p className="ad-title">{kissandeal.title}</p>
                                            </Link>
                                            <div className="row">
                                                <div className="col-10">
                                                    <p className="ad-location-wrapper">
                                                        <img src={require("../../images/icons/ad-location.png")} alt="Location Icon" />
                                                        <span className="ml-2 ad-location">{kissandeal.location}</span>
                                                    </p>
                                                    <p className="ad-by-wrapper">
                                                        <img src={require("../../images/icons/ad-by.png")} alt="User Icon" />
                                                        <span className="ml-2 ad-by">admin</span>
                                                    </p>
                                                </div>
                                                <div className="col-2">
                                                    <img src={require("../../images/icons/share-ad.png")} alt="Share Ad Icon" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                    </div>

                    <div className="row">
                        <div className="col-12 pt-2 pb-4 text-right">
                            <Link to={'/properties/viewmore/kissan/' + location.value + '/' + category.value} className="view-more-link">
                                <span className="view-more-link">View More</span>&nbsp;
                                <img src={require("../../images/icons/view-more.png")} alt="..." />
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default KissanDeals;