import                                        './PropertySearch.css';
import React, { Component }              from 'react';
import PropTypes                         from 'prop-types';
import Select                            from 'react-select';
import { Slider, Rail, Handles, Tracks } from 'react-compound-slider';
import FontAwesome                       from 'react-fontawesome';
import { toCurrency }                    from '../helpers/Formatters';
import { SliderRail, Handle, Track }     from './CompoundSlider';

class PropertySearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchMode           : this.props.searchMode,
            showPropertyStatusCb : this.props.showPropertyStatusCb,
            showPropertyOfferCb  : this.props.showPropertyOfferCb,
            advSearchPriceRange  : this.props.advSearchPriceRange,
            normalSearchCategory : this.props.normalSearchCategory,
            normalSearchValue    : this.props.normalSearchValue,
            advSearchLocation    : this.props.advSearchLocation,
            advSearchPropStatus  : this.props.advSearchPropStatus,
            advSearchPrice       : this.props.advSearchPrice,
            qstring              : ''
        };

        this.handleNormSearchCategoryChange  = this.handleNormSearchCategoryChange.bind(this);
        this.handleNormSearchValueChange     = this.handleNormSearchValueChange.bind(this);
        this.handleAdvSearchLocationChange   = this.handleAdvSearchLocationChange.bind(this);
        this.handleAdvSearchPropStatusChange = this.handleAdvSearchPropStatusChange.bind(this);
        this.handleAdvSearchPriceChange      = this.handleAdvSearchPriceChange.bind(this);
        this.toggleSearchMode                = this.toggleSearchMode.bind(this);
        this.doSearch                        = this.doSearch.bind(this);
    }

    /***************************************************************************************/
    /* NORMAL SEARCH MODE */

    handleNormSearchCategoryChange(newValue: any, actionMeta: any) {
        const { searchMode, normalSearchValue } = this.state;
        var id = newValue.value;
        let qstring = '';

        if (searchMode) {
            const { advSearchLocation, advSearchPropStatus, advSearchPrice } = this.state;
            qstring = 'smode=1&key=' + normalSearchValue + '&cid=' + id + '&lid=' + advSearchLocation.value + 
                '&minprice=' + advSearchPrice[0] + '&maxprice=' + advSearchPrice[1] + '&pmode=' + advSearchPropStatus;

            // Automotive
            if (id === '5e6612586d4437782916dd24') {
                this.setState({
                    qstring              : qstring,
                    normalSearchCategory : newValue,
                    showPropertyStatusCb : true,
                    showPropertyOfferCb  : false
                });

                this.props.setAppState('showPropertyStatusCb', true);
                this.props.setAppState('showPropertyOfferCb', false);
            }
            // Kissan
            else if (id === '5e6612f28d757322385c17e5') {
                this.setState({
                    qstring              : qstring,
                    normalSearchCategory : newValue,
                    showPropertyStatusCb : false,
                    showPropertyOfferCb  : true
                });

                this.props.setAppState('showPropertyStatusCb', false);
                this.props.setAppState('showPropertyOfferCb', true);
            }
            // Real Estate | Business | Others
            else if (
                id === '5e661296c44aa032797a1c72' ||
                id === '5e6612c1992fb52d286478f5' ||
                id === '5e6612378d757322385c17e4') {
                this.setState({
                    qstring              : qstring,
                    normalSearchCategory : newValue,
                    showPropertyStatusCb : false,
                    showPropertyOfferCb  : false
                });

                this.props.setAppState('showPropertyStatusCb', false);
                this.props.setAppState('showPropertyOfferCb', false);
            }
        } else {
            qstring = 'smode=0&key=' + normalSearchValue + '&cid=' + id;
            this.setState({
                qstring              : qstring,
                normalSearchCategory : newValue
            });
        }

        this.props.setAppState('normalSearchCategory', newValue);
    }
    handleNormSearchValueChange(e) {
        const { searchMode, normalSearchCategory } = this.state;
        let qstring = '';

        if (searchMode) {
            const { advSearchLocation, advSearchPropStatus, advSearchPrice } = this.state;
            qstring = 'smode=1&key=' + e.target.value + '&cid=' + normalSearchCategory.value + '&lid=' + advSearchLocation.value + 
                '&minprice=' + advSearchPrice[0] + '&maxprice=' + advSearchPrice[1] + '&pmode=' + advSearchPropStatus;

            this.setState({
                qstring           : qstring,
                normalSearchValue : e.target.value
            });
        } else {
            qstring = 'smode=0&key=' + e.target.value + '&cid=' + normalSearchCategory.value;
            this.setState({
                qstring           : qstring,
                normalSearchValue : e.target.value
            });
        }

        this.props.setAppState('normalSearchValue', e.target.value);
    }

    /***************************************************************************************/
    /* ADVANCED SEARCH MODE (INCLUDES NORMAL SEARCH'S CATEGORY AND VALUE FIELDS) */

    handleAdvSearchLocationChange(newValue: any, actionMeta: any) {
        const { normalSearchCategory, normalSearchValue, advSearchPropStatus, advSearchPrice } = this.state;
        var id = newValue.value;
        let qstring = 'smode=1&key=' + normalSearchValue + '&cid=' + normalSearchCategory.value + '&lid=' + id + 
            '&minprice=' + advSearchPrice[0] + '&maxprice=' + advSearchPrice[1] + '&pmode=' + advSearchPropStatus;

        this.setState({
            qstring           : qstring,
            advSearchLocation : newValue
        });
        this.props.setAppState('advSearchLocation', newValue);
    }
    handleAdvSearchPropStatusChange(e) {
        const { normalSearchCategory, normalSearchValue, advSearchLocation, advSearchPrice } = this.state;
        let qstring = 'smode=1&key=' + normalSearchValue + '&cid=' + normalSearchCategory.value + '&lid=' + advSearchLocation.value + 
            '&minprice=' + advSearchPrice[0] + '&maxprice=' + advSearchPrice[1] + '&pmode=' + e.target.value;

        this.setState({
            qstring             : qstring,
            advSearchPropStatus : e.target.value
        });
        this.props.setAppState('advSearchPropStatus', e.target.value);
    }

    /* ADVANCED SEARCH MIN-MAX PRICE SLIDER EVENT HANDLERS */

    handleAdvSearchPriceChange(sliderValues) {
        const { normalSearchCategory, normalSearchValue, advSearchLocation, advSearchPropStatus } = this.state;
        let qstring = 'smode=1&key=' + normalSearchValue + '&cid=' + normalSearchCategory.value + '&lid=' + advSearchLocation.value + 
            '&minprice=' + sliderValues[0] + '&maxprice=' + sliderValues[1] + '&pmode=' + advSearchPropStatus;

        this.setState({
            qstring        : qstring,
            advSearchPrice : sliderValues
        });
        this.props.setAppState('advSearchPrice', sliderValues);
    }

    /***************************************************************************************/
    /* SWITCH SEARCH MODES */

    toggleSearchMode() {
        const { searchMode, normalSearchCategory, normalSearchValue } = this.state;

        let qstring = '';
        if (searchMode) {
            const { advSearchLocation, advSearchPropStatus, advSearchPrice } = this.state;
            qstring = 'smode=1&key=' + normalSearchValue + '&cid=' + normalSearchCategory.value + '&lid=' + advSearchLocation.value + 
                '&minprice=' + advSearchPrice[0] + '&maxprice=' + advSearchPrice[1] + '&pmode=' + advSearchPropStatus;
        } else {
            qstring = 'smode=0&key=' + normalSearchValue + '&cid=' + normalSearchCategory.value;
        }

        this.setState({ qstring : qstring });
        this.setState(prevState => ({
            searchMode : !prevState.searchMode
        }));

        this.props.setAppState('searchMode', !searchMode);
    }

    /***************************************************************************************/

    doSearch() {
        const { history } = this.props;
        const { qstring } = this.state;

        if (history) {
            if (this.props.match.path === '/properties/search') {
                history.push('/properties/compsearch?' + qstring);
            } else if (this.props.match.path === '/properties/compsearch') {
                history.push('/properties/search?' + qstring);
            } else {
                history.push('/properties/search?' + qstring);
            }
        }
    }

    /***************************************************************************************/

    render() {
        const { categories, locations } = this.props;
        const { searchMode, normalSearchCategory, normalSearchValue, advSearchLocation, 
            showPropertyStatusCb, showPropertyOfferCb, advSearchPriceRange, advSearchPrice } = this.state;

        return(
            <section className={ searchMode ? 'pg-xxxx-search-banner pg-xxxx-search-banner-searchmode' : 'pg-xxxx-search-banner' } >
                <div className="container">
                    <div className="row no-gutters h-100 align-items-center pg-xxxx-search-row">
                        <div className="col-12">
                            <div className="row no-gutters search-container-row">
                                <div className="col-8 offset-lg-2 search-container-col">
                                    <div className="row pg-xxxx-search-bar">
                                        <div className="search-box h-100 float-left pg-xxxx-psb-category-wrapper">
                                            <div className="float-left pg-xxxx-ns-category-icon"></div>
                                            <Select
                                                value={normalSearchCategory}
                                                options={categories}
                                                placeholder="Select Category"
                                                isSearchable={false}
                                                className="pg-xxxx-nsselect-o"
                                                classNamePrefix="pg-xxxx-nsselect"
                                                components={{ IndicatorSeparator : null }}
                                                onChange={this.handleNormSearchCategoryChange} />
                                        </div>
                                        <div className="search-box w-50 h-100 float-left">
                                            <div className="input-group pg-xxxx-ns-input-group">
                                                <div className="h-100 input-group-prepend">
                                                    <span className="h-100 input-group-text pg-xxxx-ns-input-group-text">
                                                        <span className="h-100 pg-xxxx-ns-search-icon"></span>
                                                    </span>
                                                </div>
                                                <input type="text" id="ns-value" className="form-control pg-xxxx-ns-search-value" 
                                                    placeholder="What are you looking for?" value={normalSearchValue}
                                                    onChange={this.handleNormSearchValueChange} />
                                            </div>
                                        </div>
                                        <div className="search-box h-100 float-left pg-xxxx-psb-search-btn-wrapper">
                                            <button type="button" className="w-100 h-100 btn pg-xxxx-properties-search-btn"
                                                onClick={this.doSearch}>Search</button>
                                        </div>
                                    </div>

                                    <div id="advanced-search" className={ searchMode ? "" : "d-none" }>
                                        <div className="row no-gutters pg-xxxx-adv-search-row">
                                            <div className="col-12">
                                                <div className="h-100 float-left adv-location-icon"></div>
                                                <Select
                                                    value={advSearchLocation}
                                                    options={locations}
                                                    placeholder="Select Location"
                                                    isSearchable={false}
                                                    className="pg-xxxx-asselect-o"
                                                    classNamePrefix="pg-xxxx-nsselect"
                                                    components={{ IndicatorSeparator : null }}
                                                    onChange={this.handleAdvSearchLocationChange} />
                                            </div>
                                        </div>
                                        <div className={"row no-gutters pg-xxxx-adv-search-row2" + (showPropertyStatusCb ? "" : " d-none")}>
                                            <div className="col-12">
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" className="form-check-input pg-xxxx-form-check-ih" id="property-status1" 
                                                        name="property-status" value="1" onChange={this.handleAdvSearchPropStatusChange} />
                                                    <label htmlFor="property-status1" className="form-check-label pg-xxxx-adv-search-radiobtn">Used</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" className="form-check-input pg-xxxx-form-check-ih" id="property-status2" 
                                                        name="property-status" value="0" onChange={this.handleAdvSearchPropStatusChange} />
                                                    <label htmlFor="property-status2" className="form-check-label pg-xxxx-adv-search-radiobtn">New</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"row no-gutters pg-xxxx-adv-search-row2" + (showPropertyOfferCb ? "" : " d-none")}>
                                            <div className="col-12">
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" className="form-check-input pg-xxxx-form-check-ih" id="property-offer" 
                                                        name="property-offer" value="offer" onChange={this.handlePropertyOfferChange} />
                                                    <label htmlFor="property-offer" className="form-check-label pg-xxxx-adv-search-radiobtn">Offer</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row no-gutters pg-xxxx-adv-search-row2">
                                            <div className="col-12">
                                                <div className="row adv-search-price-text">
                                                    <div className="col-6">Min price</div>
                                                    <div className="col-6 text-right">Max price</div>
                                                </div>
                                                <div className="row no-gutters adv-search-slider-row">
                                                    <div className="col-12">
                                                        <Slider
                                                            mode={2}
                                                            step={1}
                                                            domain={advSearchPriceRange}
                                                            values={advSearchPrice}
                                                            onChange={this.handleAdvSearchPriceChange}>
                                                            <Rail>
                                                            {
                                                                ({ getRailProps }) => <SliderRail getRailProps={getRailProps} />
                                                            }
                                                            </Rail>
                                                            <Handles>
                                                            {
                                                                ({ handles, getHandleProps }) => (
                                                                    <div className="slider-handles">
                                                                    {
                                                                        handles.map(handle => (
                                                                            <Handle
                                                                                key={handle.id}
                                                                                handle={handle}
                                                                                domain={advSearchPriceRange}
                                                                                getHandleProps={getHandleProps} />
                                                                        ))
                                                                    }
                                                                    </div>
                                                                )
                                                            }
                                                            </Handles>
                                                            <Tracks left={false} right={false}>
                                                            {
                                                                ({ tracks, getTrackProps }) => (
                                                                    <div className="slider-tracks">
                                                                    {
                                                                        tracks.map(({ id, source, target }) => (
                                                                            <Track
                                                                                key={id}
                                                                                source={source}
                                                                                target={target}
                                                                                getTrackProps={getTrackProps} />
                                                                        ))
                                                                    }
                                                                    </div>
                                                                )
                                                            }
                                                            </Tracks>
                                                        </Slider>
                                                    </div>
                                                </div>
                                                <div className="row adv-search-price-value">
                                                    <div className="col-6"><FontAwesome name="rupee" /> {toCurrency(advSearchPrice[0])}</div>
                                                    <div className="col-6 text-right"><FontAwesome name="rupee" /> {toCurrency(advSearchPrice[1])}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row no-gutters">
                                <div className="col-8 offset-lg-2">
                                    <div className="w-100 text-right">
                                        <span className="pg-xxxx-search-type-link" 
                                            onClick={this.toggleSearchMode}>{ searchMode ? 'Normal Search' : 'Advanced Search' }</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

PropertySearch.protoTypes = {
    setAppState : PropTypes.func
};

export default PropertySearch;