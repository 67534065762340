import                              './App.css';
import React, { Component }    from 'react';
import {
    BrowserRouter as Router, 
    Route, Switch }            from 'react-router-dom';
import { askUserPermission }   from './services/Firebase';
import API                     from './helpers/API';
import PrivateRoute            from './components/PrivateRoute';
import ScrollToTop             from './components/ScrollToTop';
import PageHeader              from './components/PageHeader';
import Home                    from './pages/home/Home';
import AddPost                 from './pages/add-post/AddPost';
import Properties              from './pages/properties/Properties';
import Property                from './pages/property/Property';
import PropertyInterest        from './pages/property-interest/PropertyInterest';
import UHome                   from './pages/user/home/UHome';
import MyPropertiesTimeBased   from './pages/user/my-properties/MyPropertiesTimeBased';
import MyPropertiesProfitBased from './pages/user/my-properties/MyPropertiesProfitBased';
import MyPropertiesKissan      from './pages/user/my-properties/MyPropertiesKissan';
import MyProperty              from './pages/user/my-property/MyProperty';
import Profile                 from './pages/user/profile/Profile';
import Chat                    from './pages/user/chat/Chat';
import PageFooter              from './components/PageFooter';
import Modals                  from './components/Modals';
import { Link }                from 'react-router-dom';
import FontAwesome             from 'react-fontawesome';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading                    : true,
            location                   : { value : '0', label : 'All Locations' },
            category                   : { value : '0', label : 'All Categories' },
            locations                  : [],
            categories                 : [],
            isloggedin                 : false,
            isfcmauthenticated         : false,
            showsigninmodal            : false,
            showsignupmodal            : false,
            resetpwdtoken              : '',
            showresetpwdmodal          : false,
            showofferads               : true,
            showrecentads              : true,
            showkissandeals            : true,
            showotherads               : true,
            offerads                   : [],
            recentads                  : [],
            kissandeals                : [],
            otherads                   : [],
            // SEARCH
            searchMode                 : false,
            normalSearchCategory       : { value : '0', label : 'Select Category' },
            normalSearchValue          : '',
            advSearchLocation          : { value : '0', label : 'Select Location' },
            showPropertyStatusCb       : false,
            advSearchPropStatus        : '1',
            showPropertyOfferCb        : false,
            advSearchPriceRange        : [0, 10000000],
            advSearchPrice             : [2000000, 7000000]
        };

        this.setAppState         = this.setAppState.bind(this);
        this.resetAppSearchState = this.resetAppSearchState.bind(this);
    }

    componentDidMount() {
        askUserPermission();

        let accesstoken = localStorage.getItem('anshik-token');
        if (accesstoken !== null) {
            this.setState({ isloggedin : true });
        }

        let locations = [];
        let categories = [];

        API
            .get('location')
            .then((response) => {
                locations.push({
                    value : '0',
                    label : 'All Locations'
                });
                response.data.location.forEach((loc) => 
                    locations.push({
                        value : loc._id,
                        label : loc.location
                    })
                );
                this.setState({ locations : locations });
            });

        API
            .get('category')
            .then((response) => {
                categories.push({
                    value : '0',
                    label : 'All Categories'
                });
                response.data.category.forEach((catg) => 
                    categories.push({
                        value : catg._id,
                        label : catg.category
                    })
                );
                this.setState({ categories : categories });
            });

        API
            .get('property-details-limited-ads/0/0')
            .then((response) => {
                let responsedata = response.data;
                this.setState({
                    offerads    : responsedata.offeraddetails,
                    recentads   : responsedata.recentadsdetails,
                    kissandeals : responsedata.kissanadsdetails,
                    otherads    : responsedata.othersadsdetails,
                    loading     : false
                });
            });
    }

    setAppState(key, value) {
        this.setState({ [key] : value });
    }

    resetAppSearchState() {
        this.setState({
            searchMode           : false,
            normalSearchCategory : { value : '0', label : 'Select Category' },
            normalSearchValue    : '',
            advSearchLocation    : { value : '0', label : 'Select Location' },
            showPropertyStatusCb : false,
            advSearchPropStatus  : '1',
            showPropertyOfferCb  : false,
            advSearchPrice       : [2000000, 7000000]
        });
    }

    render() {
        const { loading, isloggedin, showsigninmodal, showsignupmodal, resetpwdtoken, showresetpwdmodal, 
            category, location, showofferads, showrecentads, showkissandeals, 
            showotherads, offerads, recentads, kissandeals, otherads, locations, categories,
            searchMode, normalSearchCategory, normalSearchValue, advSearchLocation, showPropertyStatusCb, 
            advSearchPropStatus, showPropertyOfferCb, advSearchPriceRange, advSearchPrice } = this.state;

        return (
            (loading === true) ? (
                <div className="h-100 d-flex justify-content-center align-items-center">
                    <img src={require("./images/loaders/page-loader.gif")} alt="Loader" />
                </div>
            ) : (
                <Router>
                    <ScrollToTop />

                    <div className="App">
                        <PageHeader
                            isloggedin={isloggedin}
                            locations={locations}
                            categories={categories}
                            setAppState={this.setAppState}
                            resetAppSearchState={this.resetAppSearchState} />
                        <div className="container mt-1 mb-1">
                            <div className="row">
                                <div className="col">
                                    <Link to={'/addpost'} className="mobile-add-post">
                                        <button type="button" className="btn add-post-btn-mobile container align-items-center pl-0">
                                            <FontAwesome name="plus" className="col-2 pl-0 ml-0" />
                                            <span className="col-8">Add Post</span>
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    

                        <Switch>
                            {
                                ["/", "/email/verify/:token", "/password/reset/:token"].map(path => (
                                    <Route
                                        exact
                                        key={path}
                                        path={path}
                                        render={(routeProps) => (
                                            <Home
                                                {...routeProps}
                                                location={location}
                                                category={category}
                                                locations={locations}
                                                categories={categories}
                                                searchMode={searchMode}
                                                normalSearchCategory={normalSearchCategory}
                                                normalSearchValue={normalSearchValue}
                                                advSearchLocation={advSearchLocation}
                                                showPropertyStatusCb={showPropertyStatusCb}
                                                advSearchPropStatus={advSearchPropStatus}
                                                showPropertyOfferCb={showPropertyOfferCb}
                                                advSearchPriceRange={advSearchPriceRange}
                                                advSearchPrice={advSearchPrice}
                                                showresetpwdmodal={showresetpwdmodal}
                                                showofferads={showofferads}
                                                showrecentads={showrecentads}
                                                showkissandeals={showkissandeals}
                                                showotherads={showotherads}
                                                offerads={offerads}
                                                recentads={recentads}
                                                kissandeals={kissandeals}
                                                otherads={otherads}
                                                setAppState={this.setAppState} />
                                        )} />
                                ))
                            }

                            <Route
                                path="/addpost"
                                render={(routeProps) => (
                                    <AddPost
                                        {...routeProps}
                                        setAppState={this.setAppState} />
                                )} />

                            {
                                [
                                    "/properties/search",
                                    "/properties/compsearch",
                                    "/properties/viewmore/:section/:location/:category",
                                    "/properties/category/:location/:category/:subcategory/:scatgname?"
                                ].map(path => (
                                    <Route
                                        exact
                                        key={path}
                                        path={path}
                                        render={(routeProps) => (
                                            <Properties
                                                {...routeProps}
                                                locations={locations}
                                                categories={categories}
                                                searchMode={searchMode}
                                                normalSearchCategory={normalSearchCategory}
                                                normalSearchValue={normalSearchValue}
                                                advSearchLocation={advSearchLocation}
                                                showPropertyStatusCb={showPropertyStatusCb}
                                                advSearchPropStatus={advSearchPropStatus}
                                                showPropertyOfferCb={showPropertyOfferCb}
                                                advSearchPriceRange={advSearchPriceRange}
                                                advSearchPrice={advSearchPrice}
                                                setAppState={this.setAppState} />
                                        )} />
                                ))
                            }

                            <Route
                                path="/property/:id"
                                render={(routeProps) => (
                                    <Property
                                        {...routeProps}
                                        locations={locations}
                                        categories={categories}
                                        searchMode={searchMode}
                                        normalSearchCategory={normalSearchCategory}
                                        normalSearchValue={normalSearchValue}
                                        advSearchLocation={advSearchLocation}
                                        showPropertyStatusCb={showPropertyStatusCb}
                                        advSearchPropStatus={advSearchPropStatus}
                                        showPropertyOfferCb={showPropertyOfferCb}
                                        advSearchPriceRange={advSearchPriceRange}
                                        advSearchPrice={advSearchPrice}
                                        setAppState={this.setAppState} />
                                )} />
                            <Route
                                path="/propertyint/:id"
                                render={(routeProps) => (
                                    <PropertyInterest
                                        {...routeProps}
                                        setAppState={this.setAppState} />
                                )} />

                            <PrivateRoute isloggedin={isloggedin} path="/user/home" component={UHome} />
                            <PrivateRoute isloggedin={isloggedin} path="/user/myproperties/timebased" component={MyPropertiesTimeBased} />
                            <PrivateRoute isloggedin={isloggedin} path="/user/myproperties/profitbased" component={MyPropertiesProfitBased} />
                            <PrivateRoute isloggedin={isloggedin} path="/user/myproperties/kissanbased" component={MyPropertiesKissan} />
                            <PrivateRoute isloggedin={isloggedin} path="/user/myproperty/:propertyid" component={MyProperty} />
                            <PrivateRoute isloggedin={isloggedin} path="/user/profile" component={Profile} />
                            <PrivateRoute isloggedin={isloggedin} path="/user/chat" component={Chat} />
                        </Switch>

                        <PageFooter />

                        <Modals
                            showsigninmodal={showsigninmodal}
                            showsignupmodal={showsignupmodal}
                            resetpwdtoken={resetpwdtoken}
                            showresetpwdmodal={showresetpwdmodal}
                            setAppState={this.setAppState} />
                    </div>
                </Router>
            )
        );
    }
}

export default App;