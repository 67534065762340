import NTWConverter from 'number-to-words';
import tempe        from 'tempe';

export function toCurrency(number) {
    return new Intl.NumberFormat("en-IN").format(number);
}

export function toCustomWords(stepno) {
    return NTWConverter.toOrdinal(parseInt(stepno));
}

export function toCustomDate(datestr) {
    var datearr = datestr.split('-');
    var day     = parseInt(datearr[2]);
    var month   = parseInt(datearr[1]);
    var year    = parseInt(datearr[0]);
    return tempe(year, month, day).format('DD MMM YYYY');
}

export function toDateTime(unixts) {
    var d       = new Date(unixts * 1000);
    var year    = d.getFullYear();
    var month   = ('0' + (d.getMonth() + 1)).slice(-2);
    var date    = d.getDate() < 10 ? '0' + d.getDate() : d.getDate();
    var hours   = d.getHours();
    var minutes = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes();
    var seconds = d.getSeconds() < 10 ? '0' + d.getSeconds() : d.getSeconds();
    var ampm    = 'AM';

    if (hours > 12) {
        hours = hours - 12;
        ampm = 'PM';
    } else if (hours === 12) {
        hours = 12;
        ampm = 'PM';
    } else if (hours === 0) {
        hours = 12;
    }

    var dtime = date + '-' + month + '-' + year + ' ' + hours + ':' + minutes + ':' + seconds + ' ' + ampm;
    return dtime;
}

export function toDateForCalender(datestr) {
    var datestrarr = datestr.split('-');
    var year       = parseInt(datestrarr[0]);
    var month      = parseInt(datestrarr[1]) - 1;
    var day        = parseInt(datestrarr[2]);

    return new Date(year, month, day);
}