import React, { Component } from 'react';
import { Link }             from 'react-router-dom';
import Img                  from 'react-image';
import FontAwesome          from 'react-fontawesome';
import { toCurrency }       from '../../helpers/Formatters';

class PropertyKissan extends Component {
    render() {
        const { imageloader, property, adstatus } = this.props;

        return(
            <React.Fragment>
            <div className="row mb-5 no-mobile" key={property.property_details._id}>
                <div className="col-3">
                    <div className="ad-img-wrapper">
                        <Link to={'/propertyint/' + property.property_details._id}>
                            <Img
                                src={property.property_details.image} alt="..."
                                loader={ imageloader }
                                unloader={ imageloader }
                                className="card-img-top img-fluid img-rounded ad-img" />
                        </Link>
                    </div>
                    {adstatus}
                </div>
                <div className="col-5 ad-details-wrapper">
                    <p className="ad-category">{property.property_details.category}</p>
                    <Link to={'/propertyint/' + property.property_details._id}>
                        <p className="ad-title">{property.property_details.title}</p>
                    </Link>
                    <div className="row ad-location-user-row">
                        <div className="col-12">
                            <span className="ad-location-wrapper">
                                <img src={require("../../images/icons/ad-location.png")} alt="Location Icon" />
                                <span className="ml-2 ad-location">{property.property_details.location}</span>
                            </span>
                        </div>
                    </div>
                    <div className="ad-description">{property.property_details.description}</div>
                    <div className="row">
                        <div className="col-12">
                            <img src={require("../../images/icons/share-ad.png")} className="float-right" alt="Share Ad Icon" />
                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <div className="row h-100 align-items-center">
                        <div className="col-6">
                            <div className="pg-tmr5-prop-kissan-ilv-wrapper">
                                <div className="row pg-tmr5-prop-kissan-ilv-row">
                                    <div className="col-12">
                                        <div className="pg-tmr5-prop-kissan-icon-wrapper">
                                            <img src={require("../../images/icons/pg-tmr5-land.png")} alt="Land" />
                                        </div>
                                        <div className="pg-tmr5-prop-kissan-lv-wrapper">
                                            <p className="pg-tmr5-prop-kissan-ly-label">Land</p>
                                            <p className="pg-tmr5-prop-kissan-ly-value">{property.share_details.land} sq.ft</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="pg-tmr5-prop-kissan-icon-wrapper">
                                            <img src={require("../../images/icons/pg-tmr5-yield.png")} alt="Yield" />
                                        </div>
                                        <div className="pg-tmr5-prop-kissan-lv-wrapper">
                                            <p className="pg-tmr5-prop-kissan-ly-label">Yield</p>
                                            <p className="pg-tmr5-prop-kissan-ly-value">{property.share_details.yield} kg</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-6">
                            <p className="pg-tmr5-ad-amount-label">Amount</p>
                            <p className="pg-tmr5-ad-amount-value">
                                <FontAwesome name="rupee" /> {toCurrency(property.property_details.price)}</p>

                            <div className="row">
                                <div className="col-12">
                                    <Link to={'/propertyint/' + property.property_details._id} 
                                        className="btn btn-block apf-button interest-link">Join</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* mobile view only */}

            <div className="row mb-5 mobile" key={property.property_details._id}>
                <div className="col-5">
                    <div className="ad-img-wrapper">
                        <Link to={'/propertyint/' + property.property_details._id}>
                            <Img
                                src={property.property_details.image} alt="..."
                                loader={ imageloader }
                                unloader={ imageloader }
                                className="card-img-top img-fluid img-rounded ad-img" />
                        </Link>
                    </div>
                    {adstatus}
                </div>
                <div className="col-7 ad-details-wrapper">
                    <p className="ad-category">{property.property_details.category}</p>
                    <Link to={'/propertyint/' + property.property_details._id}>
                        <p className="ad-title">{property.property_details.title}</p>
                    </Link>
                    <div className="row ad-location-user-row">
                        <div className="col-12">
                            <span className="ad-location-wrapper">
                                <img src={require("../../images/icons/ad-location.png")} alt="Location Icon" />
                                <span className="ml-2 ad-location">{property.property_details.location}</span>
                            </span>
                        </div>
                    </div>
                    <div className="ad-description">{property.property_details.description}</div>
                    <div className="row">
                        <div className="col-12">
                            <img src={require("../../images/icons/share-ad.png")} className="float-right" alt="Share Ad Icon" />
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="row h-100 align-items-center">
                        
                            
                                
                                    <div className="col-6">
                                        <div className="pg-tmr5-prop-kissan-icon-wrapper">
                                            <img src={require("../../images/icons/pg-tmr5-land.png")} alt="Land" />
                                        </div>
                                        <div className="pg-tmr5-prop-kissan-lv-wrapper">
                                            <p className="pg-tmr5-prop-kissan-ly-label">Land</p>
                                            <p className="pg-tmr5-prop-kissan-ly-value">{property.share_details.land} sq.ft</p>
                                        </div>
                                    </div>
                                
                                
                                    <div className="col-6">
                                        <div className="pg-tmr5-prop-kissan-icon-wrapper">
                                            <img src={require("../../images/icons/pg-tmr5-yield.png")} alt="Yield" />
                                        </div>
                                        <div className="pg-tmr5-prop-kissan-lv-wrapper">
                                            <p className="pg-tmr5-prop-kissan-ly-label">Yield</p>
                                            <p className="pg-tmr5-prop-kissan-ly-value">{property.share_details.yield} kg</p>
                                        </div>
                                    </div>
                                
                            
                        

                        <div className="col-12">
                            <p className="pg-tmr5-ad-amount-label">Amount</p>
                            <p className="pg-tmr5-ad-amount-value">
                                <FontAwesome name="rupee" /> {toCurrency(property.property_details.price)}</p>

                            <div className="row">
                                <div className="col-12">
                                    <Link to={'/propertyint/' + property.property_details._id} 
                                        className="btn btn-block apf-button interest-link">Join</Link>
                                </div>
                            </div>
                            
                        </div>
                        <div className='horizontal-rule'></div>
                    </div>
                </div>
            </div>
            
            </React.Fragment>
        );
    }
}

export default PropertyKissan;