import React, { Component }              from 'react';
import PropTypes                         from 'prop-types';
import Select                            from 'react-select';
import { Link }                          from 'react-router-dom';
import FontAwesome                       from 'react-fontawesome';
import { Slider, Rail, Handles, Tracks } from 'react-compound-slider';
import { toCurrency }                    from '../../helpers/Formatters';
import { SliderRail, Handle, Track }     from '../../components/CompoundSlider';
import CategoryCarousel                  from './CategoryCarosel';
import './HomeBanner.css';
class HomeBanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchMode           : this.props.searchMode,
            showPropertyStatusCb : this.props.showPropertyStatusCb,
            showPropertyOfferCb  : this.props.showPropertyOfferCb,
            advSearchPriceRange  : this.props.advSearchPriceRange,
            normalSearchCategory : this.props.normalSearchCategory,
            normalSearchValue    : this.props.normalSearchValue,
            advSearchLocation    : this.props.advSearchLocation,
            advSearchPropStatus  : this.props.advSearchPropStatus,
            advSearchPrice       : this.props.advSearchPrice,
            qstring              : ''
        };

        this.handleNormSearchCategoryChange  = this.handleNormSearchCategoryChange.bind(this);
        this.handleNormSearchValueChange     = this.handleNormSearchValueChange.bind(this);
        this.handleAdvSearchCategoryChange   = this.handleAdvSearchCategoryChange.bind(this);
        this.handleAdvSearchValueChange      = this.handleAdvSearchValueChange.bind(this);
        this.handleAdvSearchLocationChange   = this.handleAdvSearchLocationChange.bind(this);
        this.handleAdvSearchPropStatusChange = this.handleAdvSearchPropStatusChange.bind(this);
        this.handleAdvSearchPriceChange      = this.handleAdvSearchPriceChange.bind(this);
        this.toggleSearchMode                = this.toggleSearchMode.bind(this);
    }

    /***************************************************************************************/
    /* NORMAL SEARCH MODE */

    handleNormSearchCategoryChange(newValue: any, actionMeta: any) {
        const { normalSearchValue } = this.state;
        var id = newValue.value;
        let qstring = 'smode=0&key=' + normalSearchValue + '&cid=' + id;

        this.setState({
            qstring              : qstring,
            normalSearchCategory : newValue
        });
        this.props.setAppState('normalSearchCategory', newValue);
    }
    handleNormSearchValueChange(e) {
        const { normalSearchCategory } = this.state;
        let qstring = 'smode=0&key=' + e.target.value + '&cid=' + normalSearchCategory.value;

        this.setState({
            qstring           : qstring,
            normalSearchValue : e.target.value
        });
        this.props.setAppState('normalSearchValue', e.target.value);
    }

    /***************************************************************************************/
    /* ADVANCED SEARCH MODE */

    handleAdvSearchCategoryChange(newValue: any, actionMeta: any) {
        const { normalSearchValue, advSearchLocation, advSearchPropStatus, advSearchPrice } = this.state;
        var id = newValue.value;
        let qstring = 'smode=1&key=' + normalSearchValue + '&cid=' + id + '&lid=' + advSearchLocation.value + 
            '&minprice=' + advSearchPrice[0] + '&maxprice=' + advSearchPrice[1] + '&pmode=' + advSearchPropStatus;

        // Automotive
        if (id === '5e6612586d4437782916dd24') {
            this.setState({
                qstring              : qstring,
                normalSearchCategory : newValue,
                showPropertyStatusCb : true,
                showPropertyOfferCb  : false
            });

            this.props.setAppState('showPropertyStatusCb', true);
            this.props.setAppState('showPropertyOfferCb', false);
        }
        // Kissan
        else if (id === '5e6612f28d757322385c17e5') {
            this.setState({
                qstring              : qstring,
                normalSearchCategory : newValue,
                showPropertyStatusCb : false,
                showPropertyOfferCb  : true
            });

            this.props.setAppState('showPropertyStatusCb', false);
            this.props.setAppState('showPropertyOfferCb', true);
        }
        // Real Estate | Business | Others
        else if (
            id === '5e661296c44aa032797a1c72' ||
            id === '5e6612c1992fb52d286478f5' ||
            id === '5e6612378d757322385c17e4') {
            this.setState({
                qstring              : qstring,
                normalSearchCategory : newValue,
                showPropertyStatusCb : false,
                showPropertyOfferCb  : false
            });

            this.props.setAppState('showPropertyStatusCb', false);
            this.props.setAppState('showPropertyOfferCb', false);
        }

        this.props.setAppState('normalSearchCategory', newValue);
    }
    handleAdvSearchValueChange(e) {
        const { normalSearchCategory, advSearchLocation, advSearchPropStatus, advSearchPrice } = this.state;
        let qstring = 'smode=1&key=' + e.target.value + '&cid=' + normalSearchCategory.value + '&lid=' + advSearchLocation.value + 
            '&minprice=' + advSearchPrice[0] + '&maxprice=' + advSearchPrice[1] + '&pmode=' + advSearchPropStatus;

        this.setState({
            qstring           : qstring,
            normalSearchValue : e.target.value
        });
        this.props.setAppState('normalSearchValue', e.target.value);
    }
    handleAdvSearchLocationChange(newValue: any, actionMeta: any) {
        const { normalSearchCategory, normalSearchValue, advSearchPropStatus, advSearchPrice } = this.state;
        var id = newValue.value;
        let qstring = 'smode=1&key=' + normalSearchValue + '&cid=' + normalSearchCategory.value + '&lid=' + id + 
            '&minprice=' + advSearchPrice[0] + '&maxprice=' + advSearchPrice[1] + '&pmode=' + advSearchPropStatus;

        this.setState({
            qstring           : qstring,
            advSearchLocation : newValue
        });
        this.props.setAppState('advSearchLocation', newValue);
    }
    handleAdvSearchPropStatusChange(e) {
        const { normalSearchCategory, normalSearchValue, advSearchLocation, advSearchPrice } = this.state;
        let qstring = 'smode=1&key=' + normalSearchValue + '&cid=' + normalSearchCategory.value + '&lid=' + advSearchLocation.value + 
            '&minprice=' + advSearchPrice[0] + '&maxprice=' + advSearchPrice[1] + '&pmode=' + e.target.value;

        this.setState({
            qstring             : qstring,
            advSearchPropStatus : e.target.value
        });
        this.props.setAppState('advSearchPropStatus', e.target.value);
    }

    /* ADVANCED SEARCH MIN-MAX PRICE SLIDER EVENT HANDLERS */

    handleAdvSearchPriceChange(sliderValues) {
        const { normalSearchCategory, normalSearchValue, advSearchLocation, advSearchPropStatus } = this.state;
        let qstring = 'smode=1&key=' + normalSearchValue + '&cid=' + normalSearchCategory.value + '&lid=' + advSearchLocation.value + 
            '&minprice=' + sliderValues[0] + '&maxprice=' + sliderValues[1] + '&pmode=' + advSearchPropStatus;

        this.setState({
            qstring        : qstring,
            advSearchPrice : sliderValues
        });
        this.props.setAppState('advSearchPrice', sliderValues);
    }

    /***************************************************************************************/
    /* SWITCH SEARCH MODES */

    toggleSearchMode() {
        const { searchMode, normalSearchCategory, normalSearchValue } = this.state;

        let qstring = '';
        if (searchMode) {
            const { advSearchLocation, advSearchPropStatus, advSearchPrice } = this.state;
            qstring = 'smode=1&key=' + normalSearchValue + '&cid=' + normalSearchCategory.value + '&lid=' + advSearchLocation.value + 
                '&minprice=' + advSearchPrice[0] + '&maxprice=' + advSearchPrice[1] + '&pmode=' + advSearchPropStatus;
        } else {
            qstring = 'smode=0&key=' + normalSearchValue + '&cid=' + normalSearchCategory.value;
        }

        this.setState({ qstring : qstring });
        this.setState(prevState => ({
            searchMode : !prevState.searchMode
        }));

        this.props.setAppState('searchMode', !searchMode);
    }

    /***************************************************************************************/

    render() {
        const { categories, locations, location } = this.props;
        const { searchMode, normalSearchCategory, normalSearchValue, advSearchLocation, 
            showPropertyStatusCb, showPropertyOfferCb, advSearchPriceRange, advSearchPrice, 
            qstring } = this.state;
            const catalog = categories.filter((b,i)=>b.label !== "Others" && b)
              
            
        //     console.log(catalog)
        //    console.log(categories)
        return(
            <section className={ searchMode ? 'pg-hbr4-home-banner pg-hbr4-home-banner-search-mode' : 'pg-hbr4-home-banner' }>
                <div className="pg-hbr4-banner-search-wrapper">
                    <div className="row no-gutters pg-hbr4-banner-wrapper">
                        <div className="col-6 home-banner-left"></div>
                        <div className="col-6 home-banner-right"></div>
                    </div>

                    <div className="row no-gutters pg-hbr4-cap-search-wrapper">
                        <div className="col-12">
                            <div className="banner-container container">
                                <div className="row no-gutters h-100">
                                    <div className="col-sm-12 col-md-6">
                                        <div className="home-banner-left-content d-flex">
                                            <div className="w-100 my-auto  mr-4 home-banner-title">
                                                <span>OWN IMPOSSIBLE<br /> WITH A LITTLE</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6">
                                        <div className="home-banner-right-content d-flex h-100">
                                            <div className="w-100 my-auto ml-4">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="search-caption">
                                                            <span>What are you looking for?</span>
                                                        </div>
                                                        <div className="search-fields mt-4">
                                                            <div id="normal-search" className={ searchMode ? "d-none" : "" }>
                                                                <div className="row no-gutters normal-search-row">
                                                                    <div className="normal-search-select col-sm-12 col-md-5">
                                                                        <div className="h-100 float-left ns-category-icon"></div>
                                                                        <Select
                                                                            value={normalSearchCategory}
                                                                            options={catalog}
                                                                            placeholder="Select Category"
                                                                            isSearchable={false}
                                                                            className="pg-hbr4-nsselect-o"
                                                                            classNamePrefix="pg-hbr4-nsselect"
                                                                            components={{ IndicatorSeparator : null }}
                                                                            onChange={this.handleNormSearchCategoryChange} />
                                                                    </div>
                                                                    <div className="normal-search-text col-sm-12 col-md-7">
                                                                        <div className="input-group ns-input-group">
                                                                            <div className="input-group-prepend">
                                                                                <span className="h-100 input-group-text ns-input-group-text">
                                                                                    <span className="h-100 ns-search-icon"></span>
                                                                                </span>
                                                                            </div>
                                                                            <input type="text" id="ns-value" className="form-control ns-search-value" 
                                                                                placeholder="What are you looking for?" value={normalSearchValue}
                                                                                onChange={this.handleNormSearchValueChange} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div id="advanced-search" className={ searchMode ? "" : "d-none" }>
                                                                <div className="row no-gutters adv-search-row">
                                                                    <div className="col-12">
                                                                        <div className="h-100 float-left adv-category-icon"></div>
                                                                        <Select
                                                                            value={normalSearchCategory}
                                                                             options={catalog}
                                                                            placeholder="Select Category"
                                                                            isSearchable={false}
                                                                            className="pg-hbr4-ascatselect-o"
                                                                            classNamePrefix="pg-hbr4-nsselect"
                                                                            components={{ IndicatorSeparator : null }}
                                                                            onChange={this.handleAdvSearchCategoryChange} />
                                                                    </div>
                                                                </div>
                                                                <div className="row no-gutters adv-search-row">
                                                                    <div className="col-12">
                                                                        <div className="input-group adv-input-group">
                                                                            <div className="input-group-prepend">
                                                                                <span className="h-100 input-group-text adv-input-group-text">
                                                                                    <span className="h-100 adv-search-icon"></span>
                                                                                </span>
                                                                            </div>
                                                                            <input type="text" id="as-value" className="form-control adv-search-value" 
                                                                                placeholder="What are you looking for?" value={normalSearchValue}
                                                                                onChange={this.handleAdvSearchValueChange} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row no-gutters adv-search-row">
                                                                    <div className="col-12">
                                                                        <div className="h-100 float-left adv-location-icon"></div>
                                                                        <Select
                                                                            value={advSearchLocation}
                                                                            options={locations}
                                                                            placeholder="Select Location"
                                                                            isSearchable={false}
                                                                            className="pg-hbr4-asselect-o"
                                                                            classNamePrefix="pg-hbr4-nsselect"
                                                                            components={{ IndicatorSeparator : null }}
                                                                            onChange={this.handleAdvSearchLocationChange} />
                                                                    </div>
                                                                </div>
                                                                <div className={"row no-gutters adv-search-row2" + (showPropertyStatusCb ? "" : " d-none")}>
                                                                    <div className="col-12">
                                                                        <div className="form-check form-check-inline">
                                                                            <input type="radio" className="form-check-input" id="property-status1" 
                                                                                name="property-status" value="1" onChange={this.handleAdvSearchPropStatusChange} />
                                                                            <label htmlFor="property-status1" className="form-check-label adv-search-radiobtn">Used</label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input type="radio" className="form-check-input" id="property-status2" 
                                                                                name="property-status" value="0" onChange={this.handleAdvSearchPropStatusChange} />
                                                                            <label htmlFor="property-status2" className="form-check-label adv-search-radiobtn">New</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className={"row no-gutters adv-search-row2" + (showPropertyOfferCb ? "" : " d-none")}>
                                                                    <div className="col-12">
                                                                        <div className="form-check form-check-inline">
                                                                            <input type="radio" className="form-check-input" id="property-offer" 
                                                                                name="property-offer" value="offer" onChange={this.handlePropertyOfferChange} />
                                                                            <label htmlFor="property-offer" className="form-check-label adv-search-radiobtn">Offer</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row no-gutters adv-search-row2">
                                                                    <div className="col-12">
                                                                        <div className="row adv-search-price-text">
                                                                            <div className="col-6">Min price</div>
                                                                            <div className="col-6 text-right">Max price</div>
                                                                        </div>
                                                                        <div className="row no-gutters adv-search-slider-row">
                                                                            <div className="col-12">
                                                                                <Slider
                                                                                    mode={2}
                                                                                    step={1}
                                                                                    domain={advSearchPriceRange}
                                                                                    values={advSearchPrice}
                                                                                    onChange={this.handleAdvSearchPriceChange}>
                                                                                    <Rail>
                                                                                    {
                                                                                        ({ getRailProps }) => <SliderRail getRailProps={getRailProps} />
                                                                                    }
                                                                                    </Rail>
                                                                                    <Handles>
                                                                                    {
                                                                                        ({ handles, getHandleProps }) => (
                                                                                            <div className="slider-handles">
                                                                                            {
                                                                                                handles.map(handle => (
                                                                                                    <Handle
                                                                                                        key={handle.id}
                                                                                                        handle={handle}
                                                                                                        domain={advSearchPriceRange}
                                                                                                        getHandleProps={getHandleProps} />
                                                                                                ))
                                                                                            }
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                    </Handles>
                                                                                    <Tracks left={false} right={false}>
                                                                                    {
                                                                                        ({ tracks, getTrackProps }) => (
                                                                                            <div className="slider-tracks">
                                                                                            {
                                                                                                tracks.map(({ id, source, target }) => (
                                                                                                    <Track
                                                                                                        key={id}
                                                                                                        source={source}
                                                                                                        target={target}
                                                                                                        getTrackProps={getTrackProps} />
                                                                                                ))
                                                                                            }
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                    </Tracks>
                                                                                </Slider>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row adv-search-price-value">
                                                                            <div className="col-6"><FontAwesome name="rupee" /> {toCurrency(advSearchPrice[0])}</div>
                                                                            <div className="col-6 text-right"><FontAwesome name="rupee" /> {toCurrency(advSearchPrice[1])}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row mt-4">
                                                            <div className="col-sm-12 col-md-6">
                                                                <Link to={'/properties/search?' + qstring} role="button" className="btn search-btn">Search</Link>
                                                            </div>
                                                            <div className="advanced-search-box-container col-sm-12 col-md-6 d-flex h-100">
                                                                <div className="advanced-search-box w-100 my-auto">
                                                                    <span className="search-mode-btn" 
                                                                        onClick={this.toggleSearchMode}>{ searchMode ? 'Normal Search' : 'Advanced Search' }</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row home-banner-row-2">
                    <div className="col-12">
                        <ul className="categories-link  d-flex justify-content-around align-items-center">
                            <li className="h-100 justify-content-end">
                                <Link to={'/properties/category/' + location.value + '/5e6612586d4437782916dd24/0'}>
                                    <img src={require("../../images/icons/cat-1.png")} alt="Automotive" />
                                </Link>
                            </li>
                            <li className="h-100 justify-content-end">
                                <Link to={'/properties/category/' + location.value + '/5e661296c44aa032797a1c72/0'}>
                                    <img src={require("../../images/icons/cat-2.png")} alt="Real Estate" />
                                </Link>
                            </li>
                            <li className="h-100 justify-content-end">
                                <Link to={'/properties/category/' + location.value + '/5e6612c1992fb52d286478f5/0'}>
                                    <img src={require("../../images/icons/cat-3.png")} alt="Business" />
                                </Link>
                            </li>
                            <li className="h-100 justify-content-end">
                                <Link to={'/properties/category/' + location.value + '/5e6612f28d757322385c17e5/0'}>
                                    <img src={require("../../images/icons/cat-4.png")} alt="Kissan" />
                                </Link>
                            </li>
                                     {/* hiding others menu  */}

                            {/* <li className='hide'>
                                <Link to={'/properties/category/' + location.value + '/5e6612378d757322385c17e4/0'}>
                                    <img className="hide"src={require("../../images/icons/cat-5.png")} alt="Others" />
                                </Link>
                            </li> */}
                        </ul>
                    </div>
                </div>
                <CategoryCarousel {...this.props}></CategoryCarousel>
            </section>
        );
    }
}

HomeBanner.protoTypes = {
    setAppState : PropTypes.func
};

export default HomeBanner;