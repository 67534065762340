import                                  './MyProperty.css';
import React, { Component }        from 'react';
import FontAwesome                 from 'react-fontawesome';
import jwt_decode                  from 'jwt-decode';
import API                         from '../../../helpers/API';
import { imageloader }             from '../../../helpers/Constants';
import UCommon                     from '../common/UCommon';
import MyPropertyKissan            from './MyPropertyKissan';
import MyPropertyTimeOrProfitBased from './MyPropertyTimeOrProfitBased';

class MyProperty extends Component {
    constructor(props) {
        super(props);
        this.state = {
            property            : null,
            showdesc            : false,
            showimagegallery    : false,
            showimagevideomodal : false
        };

        this.goBack                = this.goBack.bind(this);
        this.toggleDesc            = this.toggleDesc.bind(this);
        this.toggleImageGallery    = this.toggleImageGallery.bind(this);
        this.toggleImageVideoModal = this.toggleImageVideoModal.bind(this);
    }

    componentDidMount() {
        let propertyid  = this.props.match.params.propertyid;
        let accesstoken = localStorage.getItem('anshik-token');
        var decoded = jwt_decode(accesstoken);
        var userid = decoded.sub;

        const requestHeaders = {
            headers : {
                'Authorization' : 'Bearer ' + accesstoken,
                'Content-Type'  : 'application/json'
            }
        };

        API
            .get('my-property-details-with-group-and-share/' + userid + '/' + propertyid, requestHeaders)
            .then((response) => {
                this.setState({ property : response.data.propertywithgroupandsharedetails });
            })
            .catch((error) => {
                // console.log('AXIOS GET: Error: ');
                // console.log(error);
            });
    }

    goBack(e) {
        e.preventDefault();
        this.props.history.goBack();
    }

    toggleDesc() {
        this.setState(prevState => ({
            showdesc : !prevState.showdesc
        }));
    }
    toggleImageGallery() {
        this.setState(prevState => ({
            showimagegallery : !prevState.showimagegallery
        }));
    }
    toggleImageVideoModal() {
        this.setState(prevState => ({
            showimagevideomodal : !prevState.showimagevideomodal
        }));
    }

    render() {
        const { showdesc, showimagegallery, showimagevideomodal, property } = this.state;

        let adstatus = '';
        if (property != null) {
            if (property.property_details.property_status === "0") {
                adstatus = <div className="pg-g83h-ad-status ad-status-new">NEW</div>;
            } else if (property.property_details.property_status === "1") {
                adstatus = <div className="pg-g83h-ad-status ad-status-used">USED</div>;
            }
        }

        return(
            <React.Fragment>
                <UCommon />

                {
                    property != null &&
                    <React.Fragment>
                        <section className="pg-g83h-blink-section">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 back-link-wrapper">
                                        <button className="properties-back-link" onClick={this.goBack}>
                                            <FontAwesome name="angle-left" className="properties-back-icon" />
                                            <span className="properties-back-text">Back</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {
                            (property.property_details.kissan_mode === "1") ? (
                                <MyPropertyKissan
                                    imageloader={imageloader}
                                    property={property}
                                    adstatus={adstatus}
                                    showdesc={showdesc}
                                    showimagegallery={showimagegallery}
                                    showimagevideomodal={showimagevideomodal}
                                    toggleDesc={this.toggleDesc}
                                    toggleImageGallery={this.toggleImageGallery}
                                    toggleImageVideoModal={this.toggleImageVideoModal} />
                            ) : (
                                <MyPropertyTimeOrProfitBased
                                    imageloader={imageloader}
                                    property={property}
                                    adstatus={adstatus}
                                    showdesc={showdesc}
                                    showimagegallery={showimagegallery}
                                    showimagevideomodal={showimagevideomodal}
                                    toggleDesc={this.toggleDesc}
                                    toggleImageGallery={this.toggleImageGallery}
                                    toggleImageVideoModal={this.toggleImageVideoModal} />
                            )
                        }
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

export default MyProperty;