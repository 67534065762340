import React, { Component } from 'react';
import GoogleLogin          from 'react-google-login';
import PropTypes            from 'prop-types';
import API                  from '../../helpers/API';

class GoogleOAuth extends Component {
    constructor(props) {
        super(props);
        this.responseGoogle = this.responseGoogle.bind(this);
    }

    responseGoogle(response) {
        const socialapiresponse = {
            provider : 'google',
            token    : response.tokenId
        };

        API
            .post('OAuth', socialapiresponse)
            .then((response) => {
                localStorage.setItem('anshik-token', response.data.accesstoken);

                this.props.setModalState('isloggedin', true);
                this.props.setModalState('showsigninmodal', false);
            });
    }

    render() {
        return (
            <GoogleLogin
                clientId="996599650254-qg5mcacki326lfe3ruuuogvq7rih7gjj.apps.googleusercontent.com"
                buttonText=""
                onSuccess={this.responseGoogle}
                onFailure={this.responseGoogle}
                cookiePolicy={'single_host_origin'}
                render={(renderProps) => (
                    <img src={require("../../images/icons/social2-google.png")} alt="Google Signin Link"
                        className="pg-xxxx-social-btn" onClick={renderProps.onClick} disabled={renderProps.disabled} />
                )}
            />
        );
    }
}

GoogleOAuth.protoTypes = {
    setModalState : PropTypes.func
};

export default GoogleOAuth;