import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import Select               from 'react-select';
import API                  from '../../helpers/API';

class FormWizardStepOne extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categories      : this.props.categories,
            subcategories   : this.props.subcategories,
            locations       : this.props.locations,
            category        : this.props.category,
            subcategory     : this.props.subcategory,
            location        : this.props.location,
            categoryname    : this.props.categoryname,
            subcategoryname : this.props.subcategoryname,
            locationname    : this.props.locationname
        };

        this.handleCategoryChange    = this.handleCategoryChange.bind(this);
        this.handleSubcategoryChange = this.handleSubcategoryChange.bind(this);
        this.handleLocationChange    = this.handleLocationChange.bind(this);
    }

    handleCategoryChange(newValue: any, actionMeta: any) {
        var id = newValue.value;
        var title = newValue.label;

        if (id === "5e6612586d4437782916dd24") {
            this.props.updateAddPostFormState('steps', 4);
        } else {
            this.props.updateAddPostFormState('steps', 3);
        }

        this.setState({
            category        : newValue,
            categoryname    : title,
            subcategory     : { value : '0', label : 'Select Subcategory' },
            subcategoryname : 'Select Subcategory'
        });

        this.props.updateAddPostFormState('category', newValue);
        this.props.updateAddPostFormState('categoryname', title);
        this.props.updateAddPostFormState('subcategory', { value : '0', label : 'Select Subcategory' });
        this.props.updateAddPostFormState('subcategoryname', 'Select Subcategory');

        API
            .get('sub-category-listing/' + id)
            .then((response) => {
                let subcategories = [];
                subcategories.push({
                    value : '0',
                    label : 'Select Subcategory'
                });

                response.data.subcategory.forEach((subcatg) => 
                    subcategories.push({
                        value : subcatg._id,
                        label : subcatg.subcategory
                    })
                );

                this.setState({ subcategories : subcategories });
                this.props.updateAddPostFormState('subcategories', subcategories);
            });
    }

    handleSubcategoryChange(newValue: any, actionMeta: any) {
        var title = newValue.label;

        this.setState({
            subcategory     : newValue,
            subcategoryname : title
        });

        this.props.updateAddPostFormState('subcategory', newValue);
        this.props.updateAddPostFormState('subcategoryname', title);
    }

    handleLocationChange(newValue: any, actionMeta: any) {
        var title = newValue.label;

        this.setState({
            location     : newValue,
            locationname : title
        });

        this.props.updateAddPostFormState('location', newValue);
        this.props.updateAddPostFormState('locationname', title);
    }

    render() {
        const { category, categories, subcategory, subcategories, location, locations } = this.state;
        const catalog = categories.filter((b,i)=>b.label !== "Others" && b)
        return (
            <React.Fragment>
                <div className="row add-post-heading-row">
                    <div className="col-12">
                        <span className="add-post-heading">Property Details</span>
                    </div>
                </div>

                <div className="row add-post-form">
                    <div className="col-12 col-sm-4">
                        <label htmlFor="category" className="control-label add-post-form-label">Category</label>
                        <Select
                            value={category}
                              options={catalog}
                            isSearchable={false}
                            classNamePrefix="pg-ber9-apfselect"
                            components={{ IndicatorSeparator : null }}
                            onChange={this.handleCategoryChange} />
                    </div>

                    <div className="col-12 col-sm-4">
                        <label htmlFor="subcategory" className="control-label add-post-form-label">Subcategory</label>
                        <Select
                            value={subcategory}
                            options={subcategories}
                            isSearchable={false}
                            classNamePrefix="pg-ber9-apfselect"
                            components={{ IndicatorSeparator : null }}
                            onChange={this.handleSubcategoryChange} />
                    </div>

                    <div className="col-12 col-sm-4">
                        <label htmlFor="location" className="control-label add-post-form-label">Location</label>
                        <Select
                            value={location}
                            options={locations}
                            isSearchable={false}
                            classNamePrefix="pg-ber9-apfselect"
                            components={{ IndicatorSeparator : null }}
                            onChange={this.handleLocationChange} />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

FormWizardStepOne.protoTypes = {
    updateAddPostFormState : PropTypes.func
};

export default FormWizardStepOne;