import                             './FormWizard.css';
import React, { Component }   from 'react';
import Img                    from 'react-image';
import Modal                  from 'react-bootstrap/Modal';
import FontAwesome            from 'react-fontawesome';
import PropTypes              from 'prop-types';
import { compareAsc, format } from 'date-fns';
import API                    from '../../helpers/API';
import FormWizardStepOne      from './FormWizardStepOne';
import FormWizardStepTwo      from './FormWizardStepTwo';
import FormWizardStepThree    from './FormWizardStepThree';
import FormWizardStepFour     from './FormWizardStepFour';
import {
    isAndroid,
    isMobile
  } from "react-device-detect";

const cssClazz = {
    "progtrckr-todo"  : "progtrckr-todo__2rCwX",
    "progtrckr-doing" : "progtrckr-doing__3GALV",
    "progtrckr-done"  : "progtrckr-done__c0N9Q"
};

class FormWizard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // COMMON
            steps                : 3,
            comp                 : 0,
            styles               : this.getNavStyles(0, 3),
            buttons              : this.getButtonsState(0, 3),
            wasstep2submitted    : false,
            wasstep3submitted    : false,
            // STEP 1
            category             : { value: '0', label: 'Select Category' },
            subcategory          : { value: '0', label: 'Select Subcategory' },
            location             : { value: '0', label: 'Select Location' },
            categories           : [],
            subcategories        : [],
            locations            : [],
            // STEP 2
            pid                  : '',
            ptitle               : '',
            pdescr               : '',
            pprice               : '',
            pvideo               : '',
            pstatus              : { value :  '', label : 'Select Status' },
            pimages              : [],
            pimagepreviews       : [],
            // STEP 3
            psid                 : '',
            useramount           : 0,
            userdays             : 0,
            usershare            : 0,
            balanceamount        : 0,
            balancedays          : 90,
            balanceshare         : 100,
            // STEP 4
            selecteddays         : [],
            // API Response
            respmsg              : '',
            showapiresponsemodal : false,
            publish: false
        };

        this.setFormWizardState          = this.setFormWizardState.bind(this);
        this.goToPrevStep                = this.goToPrevStep.bind(this);
        this.goToNextStep                = this.goToNextStep.bind(this);
        this.submitStep2Data             = this.submitStep2Data.bind(this);
        this.submitStep3Data             = this.submitStep3Data.bind(this);
        this.submitStep4Data             = this.submitStep4Data.bind(this);
        this.publishProperty             = this.publishProperty.bind(this);
        this.canStep1BeSubmitted         = this.canStep1BeSubmitted.bind(this);
        this.canStep2BeSubmitted         = this.canStep2BeSubmitted.bind(this);
        this.canStep3BeSubmitted         = this.canStep3BeSubmitted.bind(this);
        this.canStep4BeSubmitted         = this.canStep4BeSubmitted.bind(this);
        this.updateAddPostFormState      = this.updateAddPostFormState.bind(this);
        this.toggleAPIResponseModal      = this.toggleAPIResponseModal.bind(this);
        this.downloadTermsNConditionsDoc = this.downloadTermsNConditionsDoc.bind(this);
        this.routeToMyPropertiesScreen   = this.routeToMyPropertiesScreen.bind(this);
    }

    componentDidMount() {
        let locations = [];
        let categories = [];

        API
            .get('category')
            .then((response) => {
                categories.push({
                    value : '0',
                    label : 'Select Category'
                });
                response.data.category.forEach((catg) => 
                    categories.push({
                        value : catg._id,
                        label : catg.category
                    })
                );

                this.setState({ categories : categories });
            });

        API
            .get('location')
            .then((response) => {
                locations.push({
                    value : '0',
                    label : 'Select Location'
                });
                response.data.location.forEach((loc) => 
                    locations.push({
                        value : loc._id,
                        label : loc.location
                    })
                );

                this.setState({ locations : locations });
            });
    }

    getNavStyles(indx, length) {
        var styles = [];
        for (var i = 0; i < length; i++) {
            if (i < indx) {
                styles.push('done');
            } else if (i === indx) {
                styles.push('doing');
            } else {
                styles.push('todo');
            }
        }
        return styles;
    }

    getButtonsState(indx, length) {
        if (indx > 0 && indx < length - 1) {
            return {
                showPreviousBtn : true,
                showNextBtn     : true,
                showPublishBtn  : false,
                disableNextBtn  : true
            }
        } else if (indx === 0) {
            return {
                showPreviousBtn : false,
                showNextBtn     : true,
                showPublishBtn  : false,
                disableNextBtn  : true
            }
        } else {
            return {
                showPreviousBtn   : true,
                showNextBtn       : false,
                showPublishBtn    : true,
                disablePublishBtn : true
            }
        }
    }

    setFormWizardState(indx) {
        const { steps, comp } = this.state;

        this.setState({
            styles  : this.getNavStyles(indx, steps),
            comp    : (indx < steps) ? indx : comp,
            buttons : this.getButtonsState(indx, steps)
        });
    }

    goToPrevStep() {
        const { comp } = this.state;
        this.setFormWizardState(comp > 0 ? comp - 1 : comp);
    }

    goToNextStep() {
        const { comp, wasstep2submitted, wasstep3submitted } = this.state;

        if (comp === 0) {
            this.setFormWizardState(comp + 1);
        } else if (comp === 1) {
            if (wasstep2submitted) {
                this.setFormWizardState(comp + 1);
            } else {
                this.submitStep2Data();
            }
        } else if (comp === 2) {
            if (wasstep3submitted) {
                this.setFormWizardState(comp + 1);
            } else {
                this.submitStep3Data();
            }
        }
    }

    submitStep2Data() {
        const { comp, category, subcategory, location, ptitle, pdescr, pprice, pstatus, pvideo, pimages } = this.state;

        const requestData = new FormData();
        requestData.append('category_id', category.value);
        requestData.append('sub_category_id', subcategory.value);
        requestData.append('location_id', location.value);
        requestData.append('title', ptitle);
        requestData.append('description', pdescr);
        requestData.append('price', pprice);
        requestData.append('property_status', pstatus.value);
        requestData.append('video_url', pvideo);
        requestData.append('kissan_mode', '0');
        requestData.append('offer_mode', '0');
        pimages.forEach((pimage) => {
            requestData.append('image[]', pimage);
        });

        API
            .post('property-details', requestData)
            .then((response) => {
                this.setState({
                    pid               : response.data.propertydetails._id,
                    wasstep2submitted : true
                });
                if(isAndroid && isMobile) {
                    window.location.href = response.data.propertydetails.shortLink;
                } else {
                    this.setFormWizardState(comp + 1);
                }
                
            })
            .catch((error) => {
                // console.log('AXIOS POST: Error: ');
                // console.log(error);
            });
    }

    submitStep3Data() {
        let accesstoken = localStorage.getItem('anshik-token');

        if (accesstoken !== null) {
            const { category } = this.state;
            const requestHeaders = {
                headers : {
                    'Authorization' : 'Bearer ' + accesstoken,
                    'Content-Type'  : 'application/json'
                }
            };

            if (category.value === "5e6612586d4437782916dd24") {
                const { comp, pid, pprice, useramount, userdays, usershare, balanceamount } = this.state;
                const requestParams = {
                    'property_details_id' : pid,
                    'totalprice'          :Math.round(pprice),
                    'useramount'          : Math.round(useramount),
                    'userdays'            : Math.round(userdays),
                    'usershare'           : Math.round(usershare),
                    'balance'             : Math.round(balanceamount)
                };

                API
                    .post('property-share-details', requestParams, requestHeaders)
                    .then((response) => {
                        this.setState({
                            psid              : response.data.propertysharedetails._id,
                            wasstep3submitted : true
                        });
                        this.setFormWizardState(comp + 1);
                    })
                    .catch((error) => {
                        // console.log('AXIOS POST: Error: ');
                        // console.log(error);
                    });
            } else {
                const { pid, pprice, useramount, usershare, balanceamount } = this.state;
                const requestParams = {
                    'property_details_id' : pid,
                    'category_id'         : category,
                    'totalprice'          : Math.round(pprice),
                    'usershare'           : Math.round(useramount),
                    'userprofit'          : Math.round(usershare),
                    'balance'             :Math.round(balanceamount)
                };

                API
                    .post('property-share-details-profit', requestParams, requestHeaders)
                    .then((response) => {
                        this.toggleAPIResponseModal();
                    })
                    .catch((error) => {
                        // console.log('AXIOS POST: Error: ');
                        // console.log(error);
                    });
            }
        } else {
            this.props.setAppState('showsigninmodal', true);
        }
    }

    submitStep4Data() {
        let accesstoken = localStorage.getItem('anshik-token');

        if (accesstoken !== null) {
            const { pid, psid, selecteddays } = this.state;
            const requestHeaders = {
                headers : {
                    'Authorization' : 'Bearer ' + accesstoken,
                    'Content-Type'  : 'application/json'
                }
            };

            selecteddays.sort(compareAsc);
            let selecteddayss = [];
            for (var i = 0; i <= selecteddays.length - 1; i++) {
                selecteddayss.push(format(selecteddays[i], 'yyyy-MM-dd'));
            }

            const requestParams = {
                'property_details_id'       : pid,
                'property_share_details_id' : psid,
                'days'                      : selecteddayss
            };

            API
                .post('partner-days', requestParams, requestHeaders)
                .then((response) => {
                    this.toggleAPIResponseModal();
                })
                .catch((error) => {
                    // console.log('AXIOS POST: Error: ');
                    // console.log(error);
                });
        } else {
            this.props.setAppState('showsigninmodal', true);
        }
    }

    publishProperty() {
        const { category } = this.state;
        this.setState({publish:true})

        if (category.value !== '5e6612586d4437782916dd24') {
            this.submitStep3Data();
        } else {
            this.submitStep4Data();
        }
    }

    canStep1BeSubmitted() {
        const { category, subcategory, location } = this.state;
        return (
            category.value    !== '0' && 
            subcategory.value !== '0' && 
            location.value    !== '0'
        );
    }

    canStep2BeSubmitted() {
        const { ptitle, pdescr, pprice, pstatus, pvideo, pimages } = this.state;

        return (
            ptitle        !== '' &&
            pdescr        !== '' &&
            pprice        !== '' &&
            pstatus.value !== '' &&
            pvideo        !== '' &&
            pimages.length > 0
        );
    }

    canStep3BeSubmitted() {
        const { useramount, userdays } = this.state;
        return (
            useramount !== 0 &&
            userdays   !== 0
        );
    }

    canStep4BeSubmitted() {
        const { userdays, selecteddays } = this.state;
        return (Math.round(userdays) === selecteddays.length);
    }

    updateAddPostFormState(statekey, statevalue) {
        if (statekey === 'steps') {
            this.setState({
                [statekey] : statevalue,
                styles     : this.getNavStyles(0, statevalue),
                buttons    : this.getButtonsState(0, statevalue)
            });
        } else {
            this.setState({ [statekey] : statevalue });
        }
    }

    /***************** MODAL POPUP FUNCTIONS *****************/

    toggleAPIResponseModal() {
        this.setState(prevState => ({
            showapiresponsemodal : !prevState.showapiresponsemodal
        }));
    }

    downloadTermsNConditionsDoc() {
        API
            .request({
                url          : '/terms-and-conditions-export',
                method       : 'GET',
                responseType : 'blob'
            })
            .then((response) => {
                const furl = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = furl;
                link.setAttribute('download', 'aanshik-terms-n-conditions.pdf');
                link.click();

                window.URL.revokeObjectURL(furl);
            });
    }

     routeToMyPropertiesScreen() {
        const { history } = this.props;
        history.push('/user/home');
    }

    /***************** RENDER FUNCTIONS *****************/

    renderFormWizardSteps(styles) {
        const { steps } = this.state;

        let formWizardSteps = [
            { name: 'Step 1' },
            { name: 'Step 2' },
            { name: 'Step 3' },
            { name: 'Step 4' }
        ];

        return formWizardSteps.map(function(s, i) {
            var cstyle = styles[i];
            var cclass = 'progtrckr-' + cstyle;

            if (i < steps) {
                return (
                    <div key={i} value={i} className={'progtrckr-step ' + cssClazz[cclass]}>
                        <div className="step-circle"></div>
                        <p>{formWizardSteps[i].name}</p>
                    </div>
                );
            }
        });
    }

    renderFormWizardNav() {
        const { comp, buttons, category } = this.state;
        let { enableNextBtn, enablePublishBtn } = false;

        if (comp === 0) {
            enableNextBtn = this.canStep1BeSubmitted();
        } else if (comp === 1) {
            enableNextBtn = this.canStep2BeSubmitted();
        } else if (comp === 2) {
            if (category.value !== "5e6612586d4437782916dd24") {
                enablePublishBtn = this.canStep3BeSubmitted();
            } else {
                enableNextBtn = this.canStep3BeSubmitted();
            }
        } 
        
        else if (comp === 3) {
            enablePublishBtn = this.canStep4BeSubmitted();
        }

        return (
            <div className="row fw-btn-row apf-step1-button-row">
                <div className="col-6">
                {
                    buttons.showPreviousBtn && 
                        <button className="btn apf-button" onClick={this.goToPrevStep}>
                            <FontAwesome name="chevron-left" />Previous
                        </button>
                }
                </div>
                <div className="col-6">
                {
                    buttons.showNextBtn && 
                        <button className="float-right apf-button" disabled={!enableNextBtn} onClick={this.goToNextStep}>
                            Next<FontAwesome name="chevron-right" />
                        </button>
                }
                {
                    buttons.showPublishBtn && 
                        <button className="float-right apf-button" disabled={!enablePublishBtn} onClick={this.publishProperty}>
                            Publish<FontAwesome name="chevron-right" />
                        </button>
                }
                </div>
            </div>
        );
    }

    calenderModal(){
            const { userdays, selecteddays, publish } = this.state;
            if(!publish && selecteddays.length > 0 && (Math.round(userdays) === selecteddays.length)){
                alert(`Your ${Math.round(userdays)} days  of selection is completed`)
            }
    }

    modalShow(bool){
        console.log(bool);
        if(bool == "true"){
            this.setState({modalOpen: bool})
        }
    }

   

    render() {
        const {
            comp, styles,
            category, categoryname, categories, subcategory, subcategoryname, subcategories, location, locationname, locations,
            ptitle, pdescr, pprice, pstatus, pstatusname, pvideo, pimages, pimagepreviews,
            useramount, userdays, usershare, balanceamount, balancedays, balanceshare,modalOpen,
            selecteddays,
            showapiresponsemodal
        } = this.state;

        let formWizardSteps = [
            { component: <FormWizardStepOne
                category={category}
                categoryname={categoryname}
                categories={categories}
                subcategory={subcategory}
                subcategoryname={subcategoryname}
                subcategories={subcategories}
                location={location}
                locationname={locationname}
                locations={locations}
                updateAddPostFormState={this.updateAddPostFormState.bind(this)} /> },
            { component: <FormWizardStepTwo
                ptitle={ptitle}
                pdescr={pdescr}
                pprice={pprice}
                pstatus={pstatus}
                pstatusname={pstatusname}
                pvideo={pvideo}
                pimages={pimages}
                pimagepreviews={pimagepreviews}
                updateAddPostFormState={this.updateAddPostFormState.bind(this)} /> },
            { component: <FormWizardStepThree
                category={category}
                productprice={pprice}
                useramount={useramount}
                userdays={userdays}
                usershare={usershare}
                balanceamount={balanceamount}
                balancedays={balancedays}
                balanceshare={balanceshare}
                updateAddPostFormState={this.updateAddPostFormState.bind(this)} /> },
            { component: <FormWizardStepFour
                userdays={userdays}
                selecteddays={selecteddays}
                updateAddPostFormState={this.updateAddPostFormState.bind(this)} /> }
        ];

        let isloading = true;
        if (categories.length > 0 && locations.length > 0) {
            isloading = false;
        }

        return (
            <React.Fragment>
               
                
            {
                !isloading &&
                <React.Fragment>
                    <div className="row">
                        <div className="col-12">
                            <div className="progtrckr">
                                <div className="progtrckr-row">
                                    {this.renderFormWizardSteps(styles)}
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            {formWizardSteps[comp].component}
                        </div>
                        <div className="col-12">
                            {this.renderFormWizardNav()}
                        </div>
                    </div>

                    

                    <Modal show={showapiresponsemodal} onHide={this.toggleAPIResponseModal} size="sm" aria-labelledby="modal-apiresponse" centered>
                        <Modal.Header closeButton>
                            <Modal.Title id="modal-apiresponse"></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="text-center">
                                <Img src={require("../../images/icons/success.png")} className="img-fluid" alt="Success Icon" />
                            </div>

                            <div className="form-group mt-4 text-center">
                                <label className="d-block otpveri-status">Success</label>
                                <label className="d-block otpveri-message">Post successfully added</label>
                                <button className="d-block pg-ber9-tnclink" onClick={this.downloadTermsNConditionsDoc}>
                                    Download T &amp; C and sample document</button>
                            </div>

                            <button className="mt-5 d-flex mx-auto btn btn-signin btn-done" onClick={this.routeToMyPropertiesScreen}>Done</button>
                        </Modal.Body>
                    </Modal>
                </React.Fragment>
            }
             {this.calenderModal()}
            </React.Fragment>
        );
    }
}

FormWizard.protoTypes = {
    setAppState : PropTypes.func
};

export default FormWizard;