import                                                  './VerticalTimeline.css';
import React, { Component }                        from 'react';
import PropTypes                                   from 'prop-types';
import Img                                         from 'react-image';
import FontAwesome                                 from 'react-fontawesome';
import Slider                                      from 'react-styled-carousel-am';
import Modal                                       from 'react-bootstrap/Modal';
import ReactImageVideoLightbox                     from '../../../components/react-image-video-lightbox/index';
import API                                         from '../../../helpers/API';
import { toCurrency, toCustomWords, toCustomDate } from '../../../helpers/Formatters';
import { getYoutubeEmbedURL }                      from '../../../helpers/StringUtil';

class MyPropertyKissan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            farmerdetailsid      : this.props.property.farmer_details_id,
            pvideoandimages      : [],
            timelinevideo        : '',
            respmsg              : '',
            showvideomodal       : false,
            showapiresponsemodal : false
        };

        this.showTimelineVideo      = this.showTimelineVideo.bind(this);
        this.toggleVideoModal       = this.toggleVideoModal.bind(this);
        this.toggleAPIResponseModal = this.toggleAPIResponseModal.bind(this);
        this.relievePartnership     = this.relievePartnership.bind(this);
    }

    componentDidMount() {
        const { property } = this.props;

        let pvideoandimages = [];
        let videourl = property.property_details.video_url;
        if (videourl !== '') {
            let embedurl = getYoutubeEmbedURL(videourl);
            pvideoandimages.push({
                url    : embedurl,
                type   : 'video',
                altTag : ''
            });
        }

        let images = property.property_details.image;
        images.forEach((image) => {
            pvideoandimages.push({
                url    : image,
                type   : 'photo',
                altTag : ''
            });
        });

        this.setState({ pvideoandimages : pvideoandimages });
    }

    showTimelineVideo(videoUrl) {
        let embedurl = getYoutubeEmbedURL(videoUrl);
        var video = {
            url    : embedurl,
            type   : 'video',
            altTag : ''
        }
        var timelineVideos = [ video ];

        this.setState({ timelinevideo : timelineVideos });
        this.toggleVideoModal();
    }

    toggleVideoModal() {
        this.setState(prevState => ({
            showvideomodal : !prevState.showvideomodal
        }));
    }

    toggleAPIResponseModal() {
        this.setState(prevState => ({
            showapiresponsemodal : !prevState.showapiresponsemodal
        }));
    }

    relievePartnership() {
        const accesstoken = localStorage.getItem('anshik-token');
        const requestHeaders = {
            headers : {
                'Authorization' : 'Bearer ' + accesstoken,
                'Content-Type'  : 'application/json'
            }
        };

        const { farmerdetailsid } = this.state;
        const requestParams = {
            'deal_id'     : farmerdetailsid,
            'kissan_mode' : "1"
        };

        API
            .post('deals-relieving-requests', requestParams, requestHeaders)
            .then((response) => {
                this.setState({ respmsg : 'Relieve request successfully sent to Admin' });
                this.toggleAPIResponseModal();
            })
            .catch((error) => {
                // console.log('AXIOS POST: Error');
                // console.log(error);
            });
    }

    render() {
        const { imageloader, showdesc, showimagegallery, showimagevideomodal, property, adstatus } = this.props;
        const { farmerdetailsid, pvideoandimages, timelinevideo, showvideomodal, showapiresponsemodal, respmsg } = this.state;

        return(
            <React.Fragment>
                <section className="pg-g83h-ad-det-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="pg-g83h-ad-catg-img-wrapper">
                                    <Img src={property.property_details.category.image} alt="..." className="img-fluid" />
                                    {adstatus}
                                </div>

                                <div className="pg-g83h-ad-det-wrapper">
                                    <div className="row align-items-center">
                                        <div className="col-9 pg-g83h-ad-det-col1">
                                            <p className="pg-g83h-ad-category">{property.property_details.category.category}</p>
                                            <p className="pg-g83h-ad-title">{property.property_details.title}</p>

                                            <div className="row">
                                                <div className="col-12">
                                                    <span>
                                                        <img src={require("../../../images/icons/ad-location.png")} alt="Location Icon" />
                                                        <span className="ml-2 pg-g83h-ad-location">
                                                            {(property.property_details.location != null) ? property.property_details.location : 'Not Given' }
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-3">
                                            <div className="pg-g83h-ad-tprice-wrapper">
                                                <p className="pg-g83h-ad-tprice-label">Amount</p>
                                                <p className="pg-g83h-ad-tprice-value">
                                                    <FontAwesome name="rupee" /> {toCurrency(property.property_details.price)}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row pg-g83h-ad-specs-row">
                                        <div className="col-9">
                                            <div className="row">
                                                <div className="col-4 mb-3">
                                                    <div className="pg-6ef4-prop-kissan-icon-wrapper">
                                                        <img src={require("../../../images/icons/pg-22rt-land.png")} alt="Land" />
                                                    </div>
                                                    <div className="pg-6ef4-prop-kissan-lv-wrapper">
                                                        <p className="pg-6ef4-prop-kissan-ly-label">Land</p>
                                                        <p className="pg-6ef4-prop-kissan-ly-value">{property.property_details.land} sq.ft</p>
                                                    </div>
                                                </div>

                                                <div className="col-4 mb-3">
                                                    <div className="pg-6ef4-prop-kissan-icon-wrapper">
                                                        <img src={require("../../../images/icons/pg-22rt-crop.png")} alt="Crop" />
                                                    </div>
                                                    <div className="pg-6ef4-prop-kissan-lv-wrapper">
                                                        <p className="pg-6ef4-prop-kissan-ly-label">Crop</p>
                                                        <p className="pg-6ef4-prop-kissan-ly-value">{property.property_details.crop}</p>
                                                    </div>
                                                </div>

                                                <div className="col-4 mb-3">
                                                    <div className="pg-6ef4-prop-kissan-icon-wrapper">
                                                        <img src={require("../../../images/icons/pg-22rt-yield.png")} alt="Yield" />
                                                    </div>
                                                    <div className="pg-6ef4-prop-kissan-lv-wrapper">
                                                        <p className="pg-6ef4-prop-kissan-ly-label">Yield</p>
                                                        <p className="pg-6ef4-prop-kissan-ly-value">{property.property_details.yield} kg</p>
                                                    </div>
                                                </div>

                                                <div className="col-4 mb-3">
                                                    <div className="pg-6ef4-prop-kissan-icon-wrapper">
                                                        <img src={require("../../../images/icons/pg-22rt-time.png")} alt="Time" />
                                                    </div>
                                                    <div className="pg-6ef4-prop-kissan-lv-wrapper">
                                                        <p className="pg-6ef4-prop-kissan-ly-label">Time</p>
                                                        <p className="pg-6ef4-prop-kissan-ly-value">{property.property_details.time} months</p>
                                                    </div>
                                                </div>

                                                <div className="col-4 mb-3">
                                                    <div className="pg-6ef4-prop-kissan-icon-wrapper">
                                                        <img src={require("../../../images/icons/pg-g83h-methodology.png")} alt="Methodology" />
                                                    </div>
                                                    <div className="pg-6ef4-prop-kissan-lv-wrapper">
                                                        <p className="pg-6ef4-prop-kissan-ly-label">Methodology</p>
                                                        <p className="pg-6ef4-prop-kissan-ly-value">
                                                            {(property.property_details.methodology != null) ? property.property_details.methodology : 'Not Defined' }
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row pg-g83h-ad-toggle-controls-row">
                                                <div className="col-12">
                                                    <span className="pg-g83h-sh-link" 
                                                        onClick={this.props.toggleDesc}>{ showdesc ? 'Hide details' : 'View details' }</span>
                                                    <span className="ml-3 pg-g83h-sh-link" 
                                                        onClick={this.props.toggleImageGallery}>{ showimagegallery ? 'Hide gallery' : 'View gallery' }</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="pg-g83h-descadimgs-wrapper">
                    <section className={"pg-g83h-desc-section" + (showdesc ? '' : ' d-none')}>
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="row pg-g83h-sect-heading-row">
                                        <div className="col-12">
                                            <span className="pg-g83h-section-heading">Description</span>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12">
                                            <p className="pg-g83h-desc">{property.property_details.description}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className={"pg-g83h-ad-imgs-section" + (showimagegallery ? '' : ' d-none')}>
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <Slider autoSlide={false} showDots={false} hideArrowsOnNoSlides={false} padding={'0px 20px'} cardsToShow={4}>
                                        <div className="pg-g83h-ad-video-wrapper" onClick={this.props.toggleImageVideoModal}>
                                            <Img src={property.property_details.image[0]} alt="..." className="img-fluid" loader={imageloader} unloader={imageloader} />
                                            <div className="pg-g83h-video-play-icon"></div>
                                        </div>
                                        {
                                            property.property_details.image.map((pimage, index) => {
                                                return(
                                                    <div key={'pimage' + index} className="pg-g83h-ad-img-wrapper" onClick={this.props.toggleImageVideoModal}>
                                                        <Img src={pimage} alt="..." className="img-fluid" loader={imageloader} unloader={imageloader} />
                                                    </div>
                                                );
                                            })
                                        }
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <section className="pg-g83h-grp-det-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="row pg-g83h-sect-heading-row">
                                    <div className="col-12">
                                        <span className="pg-g83h-section-heading">Kissan Cultivation Timeline</span>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <div className="timeline--wrapper">
                                            <div className="timeline timeline--animate">
                                            {
                                                property.cultivation_timeline.map((ctentry, index) => {
                                                    return(
                                                        <div key={'ctentry' + index} className="entry">
                                                            <div className="title">
                                                                <div className="timeline-count-date">
                                                                    <span className="timeline-count">{toCustomWords(index+1)}</span><br/>
                                                                    <span className="timeline-date">{toCustomDate(ctentry.date)}</span>
                                                                </div>
                                                            </div>
                                                            <div className="body">
                                                                <div className="body-container">
                                                                    <p>{ctentry.description}</p>

                                                                    <div className="row">
                                                                    {
                                                                        ctentry.video_url !== '' &&
                                                                        <div className="col-3">
                                                                            <div className="video-wrapper" onClick={() => { this.showTimelineVideo(ctentry.video_url) }}>
                                                                                <Img src={ctentry.images[0]} alt="..." className="img-fluid" 
                                                                                    loader={imageloader} unloader={imageloader} />
                                                                                <div className="pg-g83h-video-play-icon"></div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        ctentry.images.map((ctimage, index) => {
                                                                            return(
                                                                                <div key={'ctimage' + index} className="col-3">
                                                                                    <div className="img-wrapper">
                                                                                        <Img src={ctimage} alt="..." className="img-fluid" 
                                                                                            loader={imageloader} unloader={imageloader} />
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })
                                                                    }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="pg-g83h-benefit-det-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="row pg-g83h-sect-heading-row">
                                    <div className="col-12">
                                        <span className="pg-g83h-section-heading">Benefit Details</span>
                                    </div>
                                </div>

                                <div className="row align-items-center">
                                    <div className="col-6">
                                        <div className="d-inline-block">
                                            <div className="pg-6ef4-prop-kissan-icon-wrapper">
                                                <img src={require("../../../images/icons/pg-22rt-crop.png")} alt="Crop" />
                                            </div>
                                            <div className="pg-6ef4-prop-kissan-lv-wrapper">
                                                <p className="pg-6ef4-prop-kissan-ly-label">Crop</p>
                                                <p className="pg-6ef4-prop-kissan-ly-value">{property.property_details.crop}</p>
                                            </div>
                                        </div>

                                        <div className="d-inline-block ml-5">
                                            <div className="pg-6ef4-prop-kissan-icon-wrapper">
                                                <img src={require("../../../images/icons/pg-22rt-yield.png")} alt="Yield" />
                                            </div>
                                            <div className="pg-6ef4-prop-kissan-lv-wrapper">
                                                <p className="pg-6ef4-prop-kissan-ly-label">Yield</p>
                                                <p className="pg-6ef4-prop-kissan-ly-value">{property.property_details.yield} kg</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                    {
                                        (farmerdetailsid !== null) &&
                                        <button className="btn float-right apf-button pg-6ef4-int-btn" 
                                            onClick={() => { this.relievePartnership() }}>Relieve</button>
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="pg-g83h-docs-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="row pg-g83h-sect-heading-row">
                                    <div className="col-12">
                                        <span className="pg-g83h-section-heading">Documents</span>
                                    </div>
                                </div>

                                <div className="row">
                                {
                                    property.user_document.map((udoc, index) => {
                                        return(
                                            <div key={'udoc' + index} className="col-4 pg-g83h-prop-doc-col">
                                                <a href={udoc.document_link} target="_blank" rel="noopener noreferrer" 
                                                    className="pg-g83h-prop-doc">{udoc.document_name}</a>
                                            </div>
                                        );
                                    })
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {
                    showimagevideomodal &&
                    <ReactImageVideoLightbox
                        data={pvideoandimages}
                        startIndex={0}
                        showResourceCount={false}
                        onCloseCallback={this.props.toggleImageVideoModal} />
                }
                {
                    (showvideomodal && timelinevideo !== '') &&
                    <ReactImageVideoLightbox
                        data={timelinevideo}
                        startIndex={0}
                        showResourceCount={false}
                        onCloseCallback={this.toggleVideoModal} />
                }

                <Modal show={showapiresponsemodal} onHide={this.toggleAPIResponseModal} size="sm" aria-labelledby="modal-apiresponse" centered>
                    <Modal.Header closeButton>
                        <Modal.Title id="modal-apiresponse"></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="text-center">
                            <Img src={require("../../../images/icons/success.png")} className="img-fluid" alt="Success Icon" />
                        </div>

                        <div className="form-group mt-4 text-center">
                            <label className="d-block otpveri-message">{respmsg}</label>
                        </div>
                        <button className="mt-5 d-flex mx-auto btn btn-signin btn-done" onClick={this.toggleAPIResponseModal}>Done</button>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}

MyPropertyKissan.protoTypes = {
    toggleDesc            : PropTypes.func,
    toggleImageGallery    : PropTypes.func,
    toggleImageVideoModal : PropTypes.func
};

export default MyPropertyKissan;