import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import Img                  from 'react-image';
import Select               from 'react-select';
import { withRouter }       from 'react-router';
import { Link }             from 'react-router-dom';
import FontAwesome          from 'react-fontawesome';
import API                  from '../helpers/API';
import {
    isAndroid,
    isMobile
  } from "react-device-detect";

class PageHeader extends Component {
    static propTypes = {
        history  : PropTypes.object.isRequired,
        location : PropTypes.object.isRequired,
        match    : PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            location : { value : '0', label : 'All Locations' },
            category : { value : '0', label : 'All Categories' }
        };

        this.handleCategoryChange    = this.handleCategoryChange.bind(this);
        this.handleLocationChange    = this.handleLocationChange.bind(this);
        this.showOrHideHomeSections  = this.showOrHideHomeSections.bind(this);
        this.onHeaderLogoClick       = this.onHeaderLogoClick.bind(this);
        this.onHeaderSigninLinkClick = this.onHeaderSigninLinkClick.bind(this);
        this.onHeaderSignupLinkClick = this.onHeaderSignupLinkClick.bind(this);
        this.logout                  = this.logout.bind(this);
        this.redirectToHomeScreen    = this.redirectToHomeScreen.bind(this);
    }

    handleCategoryChange(newValue: any, actionMeta: any) {
        this.setState({ category : newValue });
        this.showOrHideHomeSections(newValue, this.state.location);
    }

    handleLocationChange(newValue: any, actionMeta: any) {
        this.setState({ location : newValue });
        this.showOrHideHomeSections(this.state.category, newValue);
    }

    showOrHideHomeSections(category, location) {
        this.props.setAppState('category', category);
        this.props.setAppState('location', location);

        API
            .get('property-details-limited-ads/' + location.value + '/' + category.value)
            .then((response) => {
                let responsedata = response.data;
                this.props.setAppState('offerads', responsedata.offeraddetails);
                this.props.setAppState('recentads', responsedata.recentadsdetails);
                this.props.setAppState('kissandeals', responsedata.kissanadsdetails);
                this.props.setAppState('otherads', responsedata.othersadsdetails);
            });

        this.props.setAppState('showofferads', true);
        this.props.setAppState('showrecentads', true);

        // All Categories
        if (category.value === '0') {
            this.props.setAppState('showkissandeals', true);
            this.props.setAppState('showotherads', true);
        }
        // Automotive | Real Estate | Business
        else if (
            category.value === '5e6612586d4437782916dd24' ||
            category.value === '5e661296c44aa032797a1c72' ||
            category.value === '5e6612c1992fb52d286478f5') {
            this.props.setAppState('showkissandeals', false);
            this.props.setAppState('showotherads', false);
        }
        // Kissan
        else if (category.value === '5e6612f28d757322385c17e5') {
            this.props.setAppState('showkissandeals', true);
            this.props.setAppState('showotherads', false);
        }
        // Others
        else if (category.value === '5e6612378d757322385c17e4') {
            this.props.setAppState('showkissandeals', false);
            this.props.setAppState('showotherads', true);
        }
    }

    onHeaderLogoClick(e) {
        e.preventDefault();
        this.props.resetAppSearchState();
        this.redirectToHomeScreen();
    }

    onHeaderSigninLinkClick(e) {
        e.preventDefault();
        if (isAndroid && isMobile) {
            window.location.href = 'https://aanshik.page.link/JJUp';
        } else {
            this.props.setAppState('showsigninmodal', true);
        }
    }

    onHeaderSignupLinkClick(e) {
        e.preventDefault();
        if (isAndroid && isMobile) {
            window.location.href = 'https://aanshik.page.link/JJUp';
        } else {
            this.props.setAppState('showsignupmodal', true);
        }
    }

    logout() {
        localStorage.removeItem('anshik-token');
        localStorage.removeItem('anshik-username');

        this.props.setAppState('isloggedin', false);
        this.redirectToHomeScreen();
    }

    redirectToHomeScreen() {
        const { history } = this.props;
        if (history) {
            history.push('/');
        }
    }

    renderUserIcons() {
        let notificationcount = 0;
        if (this.props.isloggedin) {
            return <div className="navbar-mobile-icons mobile">

                <button id="ph-user-links" className="nav-link nav-link-style btn" data-toggle="dropdown" 
                    aria-haspopup="true" aria-expanded="false">
                    <FontAwesome name="user-o" />
                </button>

                <div className="dropdown-menu ph-user-links-menu" aria-labelledby="ph-user-links">
                    <Link className="dropdown-item ph-user-link" to={'/user/home'}>
                        <span className="ph-user-link-icon ph-ul-home-icon"></span>
                        <span className="ph-user-link-text">Home</span>
                    </Link>
                    <Link className="dropdown-item ph-user-link" to={'/user/profile'}>
                        <span className="ph-user-link-icon ph-ul-profile-icon"></span>
                        <span className="ph-user-link-text">Profile</span>
                    </Link>
                    <Link className="dropdown-item ph-user-link" to={'/'}>
                        <span className="ph-user-link-icon ph-ul-aanshik-icon"></span>
                        <span className="ph-user-link-text">Aanshik</span>
                    </Link>
                    <Link className="dropdown-item ph-user-link" to={'/properties/category/0/5e6612f28d757322385c17e5/0'}>
                        <span className="ph-user-link-icon ph-ul-kissan-icon"></span>
                        <span className="ph-user-link-text">Kissan</span>
                    </Link>
                    <div className="dropdown-divider"></div>
                    <div className="dropdown-item ph-user-link" onClick={this.logout}>
                        <span className="ph-user-link-icon ph-ul-logout-icon"></span>
                        <span className="ph-user-link-text">Logout</span>
                    </div>
                </div>

                <button className="nav-link nav-link-style btn">
                    <FontAwesome name="bell-o" />
                    {
                        notificationcount > 0 &&
                        <span className="ph-notification-count">{notificationcount}</span>
                    }
                </button>
            </div>
        }

        return null;
    }

    render() {
        const { isloggedin, categories, locations } = this.props;
        const { category, location } = this.state;
        const catalog = categories.filter((b,i)=>b.label !== "Others" && b)
        let notificationcount = 0;

        let userlinks = '';
        if (isloggedin) {
            userlinks = 
                <React.Fragment>
                    <li className="nav-item right-nav-item no-mobile">
                        <button className="nav-link nav-link-style btn">
                            <FontAwesome name="bell-o" />
                            {
                                notificationcount > 0 &&
                                <span className="ph-notification-count">{notificationcount}</span>
                            }
                        </button>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link " href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        </a>
                        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                        </div>
                    </li>
                    <li className="nav-item dropdown right-nav-item no-mobile">
                        <button className="nav-link nav-link-style btn" aria-expanded="false" id="ph-user-links"  data-toggle="dropdown" 
                            aria-haspopup="true" >
                            <FontAwesome name="user-o"/>&nbsp;&nbsp;Account
                        </button>

                        <div className="dropdown-menu ph-user-links-menu" aria-labelledby="ph-user-links">
                            <Link className="dropdown-item ph-user-link" to={'/user/home'}>
                                <span className="ph-user-link-icon ph-ul-home-icon"></span>
                                <span className="ph-user-link-text">Home</span>
                            </Link>
                            <Link className="dropdown-item ph-user-link" to={'/user/profile'}>
                                <span className="ph-user-link-icon ph-ul-profile-icon"></span>
                                <span className="ph-user-link-text">Profile</span>
                            </Link>
                            <Link className="dropdown-item ph-user-link" to={'/'}>
                                <span className="ph-user-link-icon ph-ul-aanshik-icon"></span>
                                <span className="ph-user-link-text">Aanshik</span>
                            </Link>
                            <Link className="dropdown-item ph-user-link" to={'/properties/category/0/5e6612f28d757322385c17e5/0'}>
                                <span className="ph-user-link-icon ph-ul-kissan-icon"></span>
                                <span className="ph-user-link-text">Kissan</span>
                            </Link>
                            <div className="dropdown-divider"></div>
                            <div className="dropdown-item ph-user-link" onClick={this.logout}>
                                <span className="ph-user-link-icon ph-ul-logout-icon"></span>
                                <span className="ph-user-link-text">Logout</span>
                            </div>
                        </div>
                    </li>
                </React.Fragment>;
        } else {
            userlinks = 
            <React.Fragment>
                <li className="nav-item no-mobile">
                    <button className="nav-link nav-link-style btn ph-btn-signin" 
                        onClick={this.onHeaderSigninLinkClick}>Sign In</button>
                </li>
                <li className="nav-item no-mobile">
                    <button className="nav-link nav-link-style btn ph-btn-signin" 
                        onClick={this.onHeaderSignupLinkClick}>Sign Up</button>
                </li>
                <li className="nav-item mobile-signin">
                    <button className="nav-link nav-link-style btn ph-btn-signin mobile-signin" 
                        onClick={this.onHeaderSigninLinkClick}>Sign In</button>
                </li>
            </React.Fragment>;
        }

        return(
            <React.Fragment>
                <nav className="navbar navbar-expand-lg navbar-light container">
                    <Link className="navbar-brand" to={'/'} onClick={this.onHeaderLogoClick}>
                        <Img src={require("../images/logos/anshik-logo.png")} alt="Anshik Logo" className="img-fluid navbar-logo" />
                    </Link>

                    {this.renderUserIcons()}

                    <button type="button" className="navbar-toggler " data-toggle="collapse" data-target="#navbarSupportedContent" 
                        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item nav-separator">
                                {/* <img src={require("../images/bgs/nav-item-separator.svg")} className="nav-item-separator" alt="Separator" /> */}
                            </li>
                            <li className="nav-item no-show-mobile">
                            <Select
                                value={category}
                                options={catalog}
                                placeholder="Category"
                                isSearchable={false}
                                className="pg-hbr4-pghcatselect-o no-show-mobile"
                                classNamePrefix="pg-hbr4-pghselect"
                                components={{ IndicatorSeparator : null }}
                                onChange={this.handleCategoryChange} />
                            </li>
                            <li className="nav-item">
                                <a className="nav-link nav-link-style" href="#how-we-work">How we work</a>
                            </li>
                            <li className="nav-item mobile-block">
                                <a className="nav-link nav-link-style" href="#">About Us</a>
                            </li>
                        </ul>

                        <ul className="navbar-nav">
                            <li className="nav-item no-show-mobile">
                                <Select
                                    value={location}
                                    options={locations}
                                    placeholder="Location"
                                    isSearchable={false}
                                    className="pg-hbr4-pghlocselect-o"
                                    classNamePrefix="pg-hbr4-pghselect"
                                    components={{ IndicatorSeparator : null }}
                                    onChange={this.handleLocationChange} />
                            </li>
                            { userlinks }
                            <li className="nav-item right-nav-item  no-show-mobile">
                                <Link to={'/addpost'}>
                                    <button type="button" className="btn add-post-btn">
                                        <FontAwesome name="plus" />Add Post
                                    </button>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </nav>

                <div className="mobile d-flex justify-content-between mobile">
                    <div className="flexbox d-flex justify-content-center align-items-center header-drop-item">
                        <div className="float-left header-category-icon"></div>
                        <Select
                            value={category}
                            options={categories}
                            placeholder="Category"
                            isSearchable={false}
                            className="pg-hbr4-pghcatselect-o"
                            classNamePrefix="pg-hbr4-pghselect"
                            components={{ IndicatorSeparator : null }}
                            onChange={this.handleCategoryChange} />
                    </div>
                    <div className="flexbox d-flex justify-content-center align-items-center header-drop-item">
                        <div className="float-left header-location-icon"></div>
                        <Select
                            value={location}
                            options={locations}
                            placeholder="Location"
                            isSearchable={false}
                            className="pg-hbr4-pghlocselect-o"
                            classNamePrefix="pg-hbr4-pghselect"
                            components={{ IndicatorSeparator : null }}
                            onChange={this.handleLocationChange} />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

PageHeader.protoTypes = {
    setAppState         : PropTypes.func,
    resetAppSearchState : PropTypes.func
};

export default withRouter(PageHeader);