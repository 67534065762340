import                           './PropertyInterest.css';
import React, { Component } from 'react';
import Img                  from 'react-image';
import FontAwesome          from 'react-fontawesome';
import Slider               from 'react-rangeslider';
import PropTypes            from 'prop-types';
import API                  from '../../helpers/API';
import { numregex }         from '../../helpers/Constants';
import { toCurrency }       from '../../helpers/Formatters';

class PropertyInterestTimeOrProfitBased extends Component {
    constructor(props) {
        super(props);
        this.state = {
            totalamount   : this.props.propertydata.price,
            totaldays     : 90,
            totalshare    : 100,
            takenamount   : this.props.sharetakendata.amount,
            takendays     : this.props.sharetakendata.days,
            takenshare    : this.props.sharetakendata.share,
            useramount    : 0,
            userdays      : 0,
            usershare     : 0,
            balanceamount : this.props.balancesharedata.amount,
            balancedays   : this.props.balancesharedata.days,
            balanceshare  : this.props.balancesharedata.share
        };

        this.handleUserAmountChange = this.handleUserAmountChange.bind(this);
        this.handleUserDaysChange   = this.handleUserDaysChange.bind(this);
        this.handleUserShareChange  = this.handleUserShareChange.bind(this);
        this.expressInterest        = this.expressInterest.bind(this);
    }

    updateStateValues(useramount, userdays, usershare) {
        const { totalamount, totaldays, totalshare, takenamount, takendays, takenshare } = this.state;

        this.setState({
            useramount    : useramount,
            userdays      : userdays,
            usershare     : usershare,
            balanceamount : (totalamount - takenamount - useramount),
            balancedays   : (totaldays - takendays - userdays),
            balanceshare  : (totalshare - takenshare - usershare)
        });
    }

    handleUserAmountChange(e) {
        let useramount = e.target.value.replace(/,/g, '');
        if (useramount !== '' || numregex.test(useramount)) {
            const { totalamount, takenamount } = this.state;

            var balamount = totalamount - takenamount;
            if (useramount <= balamount) {
                const { totaldays } = this.state;

                let usershare     = (useramount / totalamount);
                let usershareperc = Math.round(usershare * 100);
                let userdays      = Math.round(totaldays * usershare);

                this.updateStateValues(useramount, userdays, usershareperc);
            } else {
                alert('User Amount cannot be greater than ' + balamount);
                return;
            }
        }
    }

    handleUserDaysChange(e) {
        let userdays = e.target.value;
        if (userdays !== '' || numregex.test(userdays)) {
            const { totaldays, takendays } = this.state;

            var baldays = totaldays - takendays;
            if (userdays <= baldays) {
                const { totalamount } = this.state;

                let usershare     = userdays / totaldays;
                let usershareperc = Math.round(usershare * 100);
                let useramount    = Math.round(totalamount * usershare);

                this.updateStateValues(useramount, userdays, usershareperc);
            } else {
                alert('User Days cannot be greater than ' + baldays);
                return;
            }
        }
    }

    handleUserShareChange(value) {
        let usershareperc = value;
        if (usershareperc !== '') {
            const { totalamount, totaldays } = this.state;

            let usershare  = usershareperc / 100;
            let useramount = Math.round(totalamount * usershare);
            let userdays   = Math.round(totaldays * usershare);

            this.updateStateValues(useramount, userdays, usershareperc);
        }
    }

    calcpbarstyle(shareperc) {
        var pbarstyle = { width : shareperc + '%' };
        return pbarstyle;
    }

    expressInterest(propertyid) {
        let accesstoken = localStorage.getItem('anshik-token');

        if (accesstoken !== null) {
            const { useramount, userdays, usershare } = this.state;
            if (usershare === '') {
                alert('User Share is required');
                return;
            }
            let iusershare = parseInt(usershare);
            if (iusershare === 0) {
                alert('User Share cannot be 0');
                return;
            }
            if (iusershare < 10) {
                alert('Minimum User Share is 10%');
                return;
            }
            if (iusershare > 90) {
                alert('Maximum User Share is 90%');
                return;
            }

            if (useramount === '') {
                alert('User Amount is required');
                return;
            }
            let iuseramount = parseInt(useramount);
            if (iuseramount === 0) {
                alert('User Amount cannot be 0');
                return;
            }

            const requestHeaders = {
                headers : {
                    'Authorization' : 'Bearer ' + accesstoken,
                    'Content-Type'  : 'application/json'
                }
            };
            const requestParams = {
                'property_details_id'        : propertyid,
                'user_interest_share_amount' : iuseramount,
                'user_interest_share_days'   : userdays,
                'user_interest_share'        : iusershare
            };

            console.log('AXIOS Post: Express Property Interest');
            console.log(requestHeaders);
            console.log(requestParams);

            API
                .post('property-interest-details', requestParams, requestHeaders)
                .then((response) => {
                    this.props.toggleSuccessModal();
                })
                .catch((error) => {
                    // console.log('AXIOS Post: Error: ');
                    // console.log(error);
                });
        } else {
            this.props.setAppState('showsigninmodal', true);
        }
    }

    render() {
        const { categorydata, propertydata, groupdata, totalsharedata, sharetakendata, 
            balancesharedata, adstatus } = this.props;
        const { totalshare, takenshare, useramount, userdays, usershare, balanceamount, 
            balancedays, balanceshare } = this.state;

        let daysorprofit = '';
        let shareorprofit = '';
        if (categorydata.category === "Automotive") {
            daysorprofit = ' days';
            shareorprofit = 'Share';
        } else if (categorydata.category === "Real Estate" || categorydata.category === "Business") {
            daysorprofit = '% Profit';
            shareorprofit = 'Profit';
        }

        return(
            <React.Fragment>
                <section className="pg-a67d-ad-det-section no-mobile">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-9 pg-a67d-ad-det-col1">
                                <div className="pg-a67d-ad-catgimg-status-wrapper">
                                    <div className="pg-a67d-ad-catgimg-wrapper">
                                        <Img src={categorydata.image} alt="..." className="img-fluid" />
                                    </div>
                                    {adstatus}
                                </div>

                                <div className="pg-a67d-ad-det-wrapper">
                                    <p className="pg-a67d-ad-category">{categorydata.category}</p>
                                    <p className="pg-a67d-ad-title">{propertydata.title}</p>

                                    <div className="row">
                                        <div className="col-12">
                                            <span>
                                                <img src={require("../../images/icons/ad-location.png")} alt="Location Icon" />
                                                <span className="ml-2 pg-a67d-ad-location">{propertydata.location}</span>
                                            </span>
                                            <span className="ml-3">
                                                <img src={require("../../images/icons/ad-by.png")} alt="User Icon" />
                                                <span className="ml-2 pg-a67d-ad-by">{propertydata.user_name}</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-3">
                                <div className="pg-a67d-ad-tprice-wrapper">
                                    <p className="pg-a67d-ad-tprice-label">Total Price</p>
                                    <p className="pg-a67d-ad-tprice-value">
                                        <FontAwesome name="rupee" /> {toCurrency(propertydata.price)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* mobile only */}
                <section className="pg-a67d-ad-det-section mobile">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 pg-a67d-ad-det-col1 d-flex justify-content-center">
                                <div className="pg-a67d-ad-catgimg-status-wrapper">
                                    <div className="pg-a67d-ad-catgimg-wrapper d-flex justify-content-center">
                                        <Img src={categorydata.image} alt="..." className="img-fluid" />
                                    </div>
                                    {adstatus}
                                </div>
                                </div>
                            <div className="col-12">

                                <div className="pg-a67d-ad-det-wrapper">
                                    <p className="pg-a67d-ad-category">{categorydata.category}</p>
                                    <p className="pg-a67d-ad-title">{propertydata.title}</p>

                                    
                                </div>
                            </div>

                            <div className="row container d-flex justify-content-center">
                                <div>
                                    <span>
                                        <img src={require("../../images/icons/ad-location.png")} alt="Location Icon" />
                                        <span className="ml-2 pg-a67d-ad-location">{propertydata.location}</span>
                                    </span>
                                </div>
                                <div>
                                    <span className="ml-3">
                                        <img src={require("../../images/icons/ad-by.png")} alt="User Icon" />
                                        <span className="ml-2 pg-a67d-ad-by">{propertydata.user_name}</span>
                                    </span>
                                </div>
                            </div>

                            <div className="col-12 mt-2">
                                <div className="pg-a67d-ad-tprice-wrapper">
                                    <p className="pg-a67d-ad-tprice-label">Total Price</p>
                                    <p className="pg-a67d-ad-tprice-value">
                                        <FontAwesome name="rupee" /> {toCurrency(propertydata.price)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="pg-a67d-gd-int-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="row pg-a67d-sect-heading-row">
                                    <div className="col-12">
                                        <span className="pg-a67d-section-heading">Group Details</span>
                                    </div>
                                </div>

                                <div className="row pg-a67d-gd-users-row">
                                {
                                    groupdata.map((groupi, index) => {
                                        return(
                                            <div key={'groupi' + index} className="col-12 col-md-6">
                                                <div className="row pg-a67d-gd-user-row align-items-end">
                                                    <div className="col-2">
                                                        <Img src={require("../../images/icons/user-in-group.png")} alt="..." />
                                                    </div>
                                                    <div className="col-10">
                                                        <div className="row pg-a67d-gd-ushare-row">
                                                            <div className="col-6">
                                                                <span className="pg-a67d-gd-ushare">
                                                                    <FontAwesome name="rupee" /> {toCurrency(groupi.user_interest_share_amount)}</span>
                                                            </div>
                                                            <div className="col-6 text-right">
                                                                <span className="pg-a67d-gd-ushare">{groupi.user_interest_share_days}{daysorprofit}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="pg-a67d-gd-us-pbar">
                                                                    <div className="pg-a67d-gd-us-pbar-seek" 
                                                                        style={this.calcpbarstyle(groupi.user_interest_share)}>
                                                                        {groupi.user_interest_share}%
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="pg-a67d-sh-det-section">
                    <div className="container">
                        <div className="row pg-a67d-sh-det-row">
                            <div className="col-12 col-md-3">
                                <div className="h-100 pg-a67d-mshare-wrapper">
                                    <div className="pg-a67d-mshare-heading">My Share</div>
                                    <div className="pg-a67d-mshare-row">
                                        <label htmlFor="userAmount" className="d-block pg-a67d-mshare-form-label">Amount</label>
                                        <div className="input-group pg-a67d-mshare-igroup">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text"><FontAwesome name="rupee" /></span>
                                            </div>
                                            <input type="text" id="userAmount" className="form-control pg-a67d-mshare-textbox"
                                                placeholder="Your share" value={useramount === '' ? '' : toCurrency(useramount)} 
                                                onChange={this.handleUserAmountChange} />
                                        </div>
                                    </div>
                                    {
                                        (categorydata.category === "Automotive") ? (
                                            <React.Fragment>
                                                <div className="pg-a67d-mshare-row">
                                                    <label htmlFor="userDays" className="d-block pg-a67d-mshare-form-label">Days</label>
                                                    <input type="text" id="userDays" className="form-control pg-a67d-mshare-textbox"
                                                        placeholder="Your days" value={userdays} onChange={this.handleUserDaysChange} />
                                                </div>
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                <div className="pg-a67d-mshare-row">
                                                    <label htmlFor="userProfit" className="d-block pg-a67d-mshare-form-label">Profit</label>
                                                    <div className="input-group pg-6ef4-mshare-igroup">
                                                        <input type="text" id="userProfit" className="form-control pg-6ef4-mshare-textbox-yield"
                                                            value={usershare} onChange={this.handleUserShareChange} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text pg-6ef4-mshare-igt-yield">%</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="col-12 col-md-3 mt-4 mt-md-0">
                                <div className="h-100 pg-a67d-oshare-wrapper">
                                    <div className="pg-a67d-oshare-heading">Total Share</div>
                                    <div className="row no-gutters pg-a67d-oshare-row">
                                        <div className="col-7">
                                            <div className="pg-a67d-icon-wrapper">
                                                <img src={require("../../images/icons/pg-a67d-amount.png")} alt="Amt Icon" />
                                            </div>
                                            <div className="pg-a67d-lv-wrapper">
                                                <p className="pg-a67d-oshare-label">Amount</p>
                                                <p className="pg-a67d-oshare-value"><FontAwesome name="rupee" /> {toCurrency(totalsharedata.amount)}</p>
                                            </div>
                                        </div>
                                        <div className="col-5">
                                        {
                                            (categorydata.category === "Automotive") &&
                                            <React.Fragment>
                                                <div className="pg-a67d-icon-wrapper">
                                                    <img src={require("../../images/icons/pg-a67d-days.png")} alt="Days Icon" />
                                                </div>
                                                <div className="pg-a67d-lv-wrapper">
                                                    <p className="pg-a67d-oshare-label">Days</p>
                                                    <p className="pg-a67d-oshare-value">{totalsharedata.days}</p>
                                                </div>
                                            </React.Fragment>
                                        }
                                        </div>
                                    </div>
                                    <div className="row no-gutters pg-a67d-oshare-row">
                                        <div className="col-7">
                                            <div className="pg-a67d-icon-wrapper">
                                                <img src={require("../../images/icons/pg-a67d-share.png")} alt="Share Icon" />
                                            </div>
                                            <div className="pg-a67d-lv-wrapper">
                                                <p className="pg-a67d-oshare-label">{shareorprofit}</p>
                                                <p className="pg-a67d-oshare-value">{totalsharedata.share}%</p>
                                            </div>
                                        </div>
                                        <div className="col-5">
                                        {
                                            (categorydata.category === "Automotive") &&
                                            <React.Fragment>
                                                <div className="pg-a67d-icon-wrapper">
                                                    <img src={require("../../images/icons/pg-a67d-holder.png")} alt="User Icon" />
                                                </div>
                                                <div className="pg-a67d-lv-wrapper">
                                                    <p className="pg-a67d-oshare-label">Holder</p>
                                                    <p className="pg-a67d-oshare-value">{totalsharedata.holder}</p>
                                                </div>
                                            </React.Fragment>
                                        }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-3 mt-4 mt-md-0">
                                <div className="h-100 pg-a67d-oshare-wrapper">
                                    <div className="pg-a67d-oshare-heading">Share Taken</div>
                                    <div className="row no-gutters pg-a67d-oshare-row">
                                        <div className="col-7">
                                            <div className="pg-a67d-icon-wrapper">
                                                <img src={require("../../images/icons/pg-a67d-amount.png")} alt="Amt Icon" />
                                            </div>
                                            <div className="pg-a67d-lv-wrapper">
                                                <p className="pg-a67d-oshare-label">Amount</p>
                                                <p className="pg-a67d-oshare-value"><FontAwesome name="rupee" /> {toCurrency(sharetakendata.amount)}</p>
                                            </div>
                                        </div>
                                        <div className="col-5">
                                        {
                                            (categorydata.category === "Automotive") &&
                                            <React.Fragment>
                                                <div className="pg-a67d-icon-wrapper">
                                                    <img src={require("../../images/icons/pg-a67d-days.png")} alt="Days Icon" />
                                                </div>
                                                <div className="pg-a67d-lv-wrapper">
                                                    <p className="pg-a67d-oshare-label">Days</p>
                                                    <p className="pg-a67d-oshare-value">{sharetakendata.days}</p>
                                                </div>
                                            </React.Fragment>
                                        }
                                        </div>
                                    </div>
                                    <div className="row no-gutters pg-a67d-oshare-row">
                                        <div className="col-7">
                                            <div className="pg-a67d-icon-wrapper">
                                                <img src={require("../../images/icons/pg-a67d-share.png")} alt="Share Icon" />
                                            </div>
                                            <div className="pg-a67d-lv-wrapper">
                                                <p className="pg-a67d-oshare-label">{shareorprofit}</p>
                                                <p className="pg-a67d-oshare-value">{sharetakendata.share}%</p>
                                            </div>
                                        </div>
                                        <div className="col-5">
                                            <div className="pg-a67d-icon-wrapper">
                                                <img src={require("../../images/icons/pg-a67d-holder.png")} alt="User Icon" />
                                            </div>
                                            <div className="pg-a67d-lv-wrapper">
                                                <p className="pg-a67d-oshare-label">Holder</p>
                                                <p className="pg-a67d-oshare-value">{sharetakendata.holder}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-3 mt-4 mt-md-0">
                                <div className="h-100 pg-a67d-oshare-wrapper">
                                    <div className="pg-a67d-oshare-heading">Balance Share</div>
                                    <div className="row no-gutters pg-a67d-oshare-row">
                                        <div className="col-7">
                                            <div className="pg-a67d-icon-wrapper">
                                                <img src={require("../../images/icons/pg-a67d-amount.png")} alt="Amt Icon" />
                                            </div>
                                            <div className="pg-a67d-lv-wrapper">
                                                <p className="pg-a67d-oshare-label">Amount</p>
                                                <p className="pg-a67d-oshare-value"><FontAwesome name="rupee" /> {toCurrency(balanceamount)}</p>
                                            </div>
                                        </div>
                                        <div className="col-5">
                                        {
                                            (categorydata.category === "Automotive") &&
                                            <React.Fragment>
                                                <div className="pg-a67d-icon-wrapper">
                                                    <img src={require("../../images/icons/pg-a67d-days.png")} alt="Days Icon" />
                                                </div>
                                                <div className="pg-a67d-lv-wrapper">
                                                    <p className="pg-a67d-oshare-label">Days</p>
                                                    <p className="pg-a67d-oshare-value">{balancedays}</p>
                                                </div>
                                            </React.Fragment>
                                        }
                                        </div>
                                    </div>
                                    <div className="row no-gutters pg-a67d-oshare-row">
                                        <div className="col-7">
                                            <div className="pg-a67d-icon-wrapper">
                                                <img src={require("../../images/icons/pg-a67d-share.png")} alt="Share Icon" />
                                            </div>
                                            <div className="pg-a67d-lv-wrapper">
                                                <p className="pg-a67d-oshare-label">{shareorprofit}</p>
                                                <p className="pg-a67d-oshare-value">{balanceshare}%</p>
                                            </div>
                                        </div>
                                        <div className="col-5">
                                        {
                                            (categorydata.category === "Automotive") &&
                                            <React.Fragment>
                                                <div className="pg-a67d-icon-wrapper">
                                                    <img src={require("../../images/icons/pg-a67d-holder.png")} alt="User Icon" />
                                                </div>
                                                <div className="pg-a67d-lv-wrapper">
                                                    <p className="pg-a67d-oshare-label">Holder</p>
                                                    <p className="pg-a67d-oshare-value">{balancesharedata.holder}</p>
                                                </div>
                                            </React.Fragment>
                                        }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row pg-a67d-notes-row">
                            <div className="col-12">* The days will be cyclic</div>
                        </div>

                        <div className="row pg-a67d-sh-slider-row">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-6">
                                        <span className="w-100 rangesilder-values rangeslider-current">{usershare}%</span>
                                    </div>
                                    <div className="col-6"></div>
                                </div>
                                <Slider
                                    min={0}
                                    max={totalshare-takenshare}
                                    value={usershare}
                                    onChange={this.handleUserShareChange} />
                            </div>
                        </div>

                        <div className="row text-center">
                            <div className="col-12">
                                <button className="btn apf-button pg-a67d-int-btn"
                                    onClick={() => {this.expressInterest(propertydata._id)}}>Join</button>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

PropertyInterestTimeOrProfitBased.protoTypes = {
    setAppState        : PropTypes.func,
    toggleSuccessModal : PropTypes.func
};

export default PropertyInterestTimeOrProfitBased;