import                                        './PropertyInterest.css';
import React, { Component }              from 'react';
import Img                               from 'react-image';
import FontAwesome                       from 'react-fontawesome';
import Modal                             from 'react-bootstrap/Modal';
import API                               from '../../helpers/API';
import PropertyInterestTimeOrProfitBased from './PropertyInterestTimeOrProfitBased';
import PropertyInterestKissan            from './PropertyInterestKissan';

class PropertyInterest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isfetching       : true,
            categorydata     : null,
            propertydata     : null,
            groupdata        : [],
            totalsharedata   : null,
            sharetakendata   : null,
            balancesharedata : null,
            totalamount      : 0,
            balanceamount    : 0,
            balancedays      : 90,
            balanceshare     : 100,
            showsuccessmodal : false
        };

        this.goBack                      = this.goBack.bind(this);
        this.toggleSuccessModal          = this.toggleSuccessModal.bind(this);
        this.downloadTermsNConditionsDoc = this.downloadTermsNConditionsDoc.bind(this);
        this.routeToNextScreen           = this.routeToNextScreen.bind(this);
    }

    componentDidMount() {
        const { id } = this.props.match.params;

        API
            .get('property-details-with-group-and-share/' + id)
            .then((response) => {
                let responsedata = response.data.propertywithgroupandsharedetails;
                if (responsedata.property_details.kissan_mode === "1") {
                    this.setState({
                        categorydata  : responsedata.category,
                        propertydata  : responsedata.property_details,
                        isfetching    : false
                    });
                } else {
                    this.setState({
                        categorydata     : responsedata.category,
                        propertydata     : responsedata.property_details,
                        groupdata        : responsedata.group,
                        totalsharedata   : responsedata.total_share,
                        sharetakendata   : responsedata.share_taken,
                        balancesharedata : responsedata.balance_share,
                        totalamount      : responsedata.property_details.price,
                        balanceamount    : responsedata.balance_share.amount,
                        balancedays      : responsedata.balance_share.days,
                        balanceshare     : responsedata.balance_share.share,
                        isfetching       : false
                    });
                }
            })
            .catch((error) => {
                // console.log('AXIOS GET: Error:');
                // console.log(error);
            });
    }

    goBack(e) {
        e.preventDefault();
        this.props.history.goBack();
    }

    toggleSuccessModal() {
        this.setState(prevState => ({
            showsuccessmodal : !prevState.showsuccessmodal
        }));
    }

    downloadTermsNConditionsDoc() {
        API
            .request({
                url          : '/terms-and-conditions-export',
                method       : 'GET',
                responseType : 'blob'
            })
            .then((response) => {
                const furl = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = furl;
                link.setAttribute('download', 'aanshik-terms-n-conditions.pdf');
                link.click();

                window.URL.revokeObjectURL(furl);
            });
    }

    routeToNextScreen() {
        this.toggleSuccessModal();
        this.props.history.push('/user/home');
    }

    render() {
        const { isfetching, categorydata, propertydata, groupdata, totalsharedata, sharetakendata, 
            balancesharedata, showsuccessmodal } = this.state;

        let adstatus = '';
        if (!isfetching) {
            if (propertydata.property_status === "0") {
                adstatus = <div className="pg-a67d-ad-status ad-status-new">NEW</div>;
            } else if (propertydata.property_status === "1") {
                adstatus = <div className="pg-a67d-ad-status ad-status-used">USED</div>;
            }
        }

        return(
            <React.Fragment>
            {
                !isfetching &&
                <React.Fragment>
                    <section className="pg-a67d-blink-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 back-link-wrapper">
                                    <button className="properties-back-link" onClick={this.goBack}>
                                        <FontAwesome name="angle-left" className="properties-back-icon" />
                                        <span className="properties-back-text">Back</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </section>

                    {
                        (propertydata.kissan_mode === "1") ? (
                            <PropertyInterestKissan
                                categorydata={categorydata}
                                propertydata={propertydata}
                                adstatus={adstatus}
                                setAppState={this.props.setAppState.bind(this)}
                                toggleSuccessModal={this.toggleSuccessModal} />
                        ) : (
                            <PropertyInterestTimeOrProfitBased
                                categorydata={categorydata}
                                propertydata={propertydata}
                                groupdata={groupdata}
                                totalsharedata={totalsharedata}
                                sharetakendata={sharetakendata}
                                balancesharedata={balancesharedata}
                                adstatus={adstatus}
                                setAppState={this.props.setAppState.bind(this)}
                                toggleSuccessModal={this.toggleSuccessModal} />
                        )
                    }

                    <Modal show={showsuccessmodal} onHide={this.toggleSuccessModal} size="sm" aria-labelledby="modal-success" centered>
                        <Modal.Header closeButton>
                            <Modal.Title id="modal-success"></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="text-center">
                                <Img src={require("../../images/icons/success.png")} className="img-fluid" alt="Success Icon" />
                            </div>

                            <div className="form-group mt-4 text-center">
                                <label className="d-block otpveri-status">Success</label>
                                <label className="d-block otpveri-message">Successfully added</label>
                                <button className="d-block pg-a67d-tnclink" onClick={this.downloadTermsNConditionsDoc}>
                                Download T &amp; C and sample document</button>
                            </div>

                            <button className="mt-5 d-flex mx-auto btn btn-signin btn-done" 
                                onClick={this.routeToNextScreen}>Done</button>
                        </Modal.Body>
                    </Modal>
                </React.Fragment>
            }
            </React.Fragment>
        );
    }
}

export default PropertyInterest;