import                               './UHome.css';
import React, { Component }     from 'react';
import { Link }                 from 'react-router-dom';
import jwt_decode               from 'jwt-decode';
import API                      from '../../../helpers/API';
import UCommon                  from '../common/UCommon';
import UHomePropertyTimeBased   from './UHomePropertyTimeBased';
import UHomePropertyProfitBased from './UHomePropertyProfitBased';
import UHomePropertyKissan      from './UHomePropertyKissan';

class UHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            timebasedprops       : [],
            timebasedcpage       : 0,
            timebasednoofpages   : 0,
            profitbasedprops     : [],
            profitbasedcpage     : 0,
            profitbasednoofpages : 0,
            kissanprops          : [],
            kissanpropscpage     : 0,
            kissanpropsnoofpages : 0
        };

        this.loadMyProperties            = this.loadMyProperties.bind(this);
        this.loadTimeBasedProperties     = this.loadTimeBasedProperties.bind(this);
        this.loadProfitBasedProperties   = this.loadProfitBasedProperties.bind(this);
        this.loadKissanProperties        = this.loadKissanProperties.bind(this);
        this.loadTimeBasedNextPage       = this.loadTimeBasedNextPage.bind(this);
        this.loadTimeBasedPreviousPage   = this.loadTimeBasedPreviousPage.bind(this);
        this.loadProfitBasedNextPage     = this.loadProfitBasedNextPage.bind(this);
        this.loadProfitBasedPreviousPage = this.loadProfitBasedPreviousPage.bind(this);
        this.loadKissanNextPage          = this.loadKissanNextPage.bind(this);
        this.loadKissanPreviousPage      = this.loadKissanPreviousPage.bind(this);
    }

    componentDidMount() {
        this.loadMyProperties();
    }

    loadMyProperties() {
        const { timebasedcpage, profitbasedcpage, kissanpropscpage } = this.state;

        let tbpageno = timebasedcpage + 1;
        let pbpageno = profitbasedcpage + 1;
        let kissanpageno = kissanpropscpage + 1;

        this.setState({
            timebasedcpage   : tbpageno,
            profitbasedcpage : pbpageno,
            kissanpropscpage : kissanpageno
        });
        this.loadTimeBasedProperties(tbpageno);
        this.loadProfitBasedProperties(pbpageno);
        this.loadKissanProperties(kissanpageno);
    }

    loadTimeBasedProperties(pageno) {
        let accesstoken = localStorage.getItem('anshik-token');
        const requestHeaders = {
            headers : {
                'Authorization' : 'Bearer ' + accesstoken,
                'Content-Type'  : 'application/json'
            }
        };

        var decoded = jwt_decode(accesstoken);
        var userid  = decoded.sub;

        API
            .get('my-property-details-with-share/' + userid + '/timebased?page=' + pageno, requestHeaders)
            .then((response) => {
                let responsedata = response.data.categorybaseddetails;
                this.setState({
                    timebasedprops     : responsedata.timebased.data,
                    timebasednoofpages : responsedata.timebased.last_page
                });
            })
            .catch((error) => {
                // console.log('AXIOS GET: Error: ');
                // console.log(error);
            });
    }

    loadProfitBasedProperties(pageno) {
        let accesstoken = localStorage.getItem('anshik-token');
        const requestHeaders = {
            headers : {
                'Authorization' : 'Bearer ' + accesstoken,
                'Content-Type'  : 'application/json'
            }
        };

        var decoded = jwt_decode(accesstoken);
        var userid  = decoded.sub;

        API
            .get('my-property-details-with-share/' + userid + '/profitbased?page=' + pageno, requestHeaders)
            .then((response) => {
                let responsedata = response.data.categorybaseddetails;
                this.setState({
                    profitbasedprops     : responsedata.profitbased.data,
                    profitbasednoofpages : responsedata.profitbased.last_page
                });
            })
            .catch((error) => {
                // console.log('AXIOS GET: Error: ');
                // console.log(error);
            });
    }

    loadKissanProperties(pageno) {
        let accesstoken = localStorage.getItem('anshik-token');
        const requestHeaders = {
            headers : {
                'Authorization' : 'Bearer ' + accesstoken,
                'Content-Type'  : 'application/json'
            }
        };

        var decoded = jwt_decode(accesstoken);
        var userid  = decoded.sub;

        API
            .get('my-property-details-with-share/' + userid + '/kissanbased?page=' + pageno, requestHeaders)
            .then((response) => {
                let responsedata = response.data.categorybaseddetails;
                this.setState({
                    kissanprops          : responsedata.kissanbased.data,
                    kissanpropsnoofpages : responsedata.kissanbased.last_page
                });
            })
            .catch((error) => {
                // console.log('AXIOS GET: Error: ');
                // console.log(error);
            });
    }

    loadTimeBasedNextPage() {
        const { timebasedcpage, timebasednoofpages } = this.state;
        let pageno = timebasedcpage + 1;

        if (pageno >= 1 && pageno <= timebasednoofpages) {
            this.setState({ timebasedcpage : pageno });
            this.loadTimeBasedProperties(pageno);
        }
    }

    loadTimeBasedPreviousPage() {
        const { timebasedcpage, timebasednoofpages } = this.state;
        let pageno = timebasedcpage - 1;

        if (pageno >= 1 && pageno <= timebasednoofpages) {
            this.setState({ timebasedcpage : pageno });
            this.loadTimeBasedProperties(pageno);
        }
    }

    loadProfitBasedNextPage() {
        const { profitbasedcpage, profitbasednoofpages } = this.state;
        let pageno = profitbasedcpage + 1;

        if (pageno >= 1 && pageno <= profitbasednoofpages) {
            this.setState({ profitbasedcpage : pageno });
            this.loadProfitBasedProperties(pageno);
        }
    }

    loadProfitBasedPreviousPage() {
        const { profitbasedcpage, profitbasednoofpages } = this.state;
        let pageno = profitbasedcpage - 1;

        if (pageno >= 1 && pageno <= profitbasednoofpages) {
            this.setState({ profitbasedcpage : pageno });
            this.loadProfitBasedProperties(pageno);
        }
    }

    loadKissanNextPage() {
        const { kissanpropscpage, kissanpropsnoofpages } = this.state;
        let pageno = kissanpropscpage + 1;

        if (pageno >= 1 && pageno <= kissanpropsnoofpages) {
            this.setState({ kissanpropscpage : pageno });
            this.loadKissanProperties(pageno);
        }
    }

    loadKissanPreviousPage() {
        const { kissanpropscpage, kissanpropsnoofpages } = this.state;
        let pageno = kissanpropscpage - 1;

        if (pageno >= 1 && pageno <= kissanpropsnoofpages) {
            this.setState({ kissanpropscpage : pageno });
            this.loadKissanProperties(pageno);
        }
    }

    render() {
        const { timebasedprops, profitbasedprops, kissanprops } = this.state;
        const wfamarkup = <div className="pg-nd75-wfa-overlay"><div className="pg-nd75-wfa-text">Waiting for Approval...</div></div>;

        return(
            <React.Fragment>
                <UCommon />

                <div className="pg-nd75-sections-wrapper">
                    <section className="pg-nd75-myprop-timebased-section">
                        <div className="container">
                            <div className="row pg-nd75-sect-heading-row">
                                <div className="col-6">
                                    <span className="pg-nd75-section-heading">My Properties - Time Based</span>
                                </div>
                                <div className="col-6">
                                    <Link to={'/user/myproperties/timebased'}>
                                        <div className="ml-3 float-right pg-nd75-section-maximize"></div>
                                    </Link>
                                    <div className="ml-3 float-right pg-nd75-section-sright" onClick={this.loadTimeBasedNextPage}></div>
                                    <div className="float-right pg-nd75-section-sleft" onClick={this.loadTimeBasedPreviousPage}></div>
                                </div>
                            </div>

                            <div className="row row-cols-1 row-cols-md-3">
                            {
                                timebasedprops.map((property) => {
                                    return (
                                        <UHomePropertyTimeBased
                                            key={property.property_details._id}
                                            property={property}
                                            wfamarkup={wfamarkup} />
                                    );
                                })
                            }
                            </div>
                        </div>
                    </section>

                    <section className="pg-nd75-myprop-profitbased-section">
                        <div className="container">
                            <div className="row pg-nd75-sect-heading-row">
                                <div className="col-6">
                                    <span className="pg-nd75-section-heading">My Properties - Profit Based</span>
                                </div>
                                <div className="col-6">
                                    <Link to={'/user/myproperties/profitbased'}>
                                        <div className="ml-3 float-right pg-nd75-section-maximize"></div>
                                    </Link>
                                    <div className="ml-3 float-right pg-nd75-section-sright" onClick={this.loadProfitBasedNextPage}></div>
                                    <div className="float-right pg-nd75-section-sleft" onClick={this.loadProfitBasedPreviousPage}></div>
                                </div>
                            </div>

                            <div className="row row-cols-1 row-cols-md-3">
                            {
                                profitbasedprops.map((property) => {
                                    return (
                                        <UHomePropertyProfitBased
                                            key={property.property_details._id}
                                            property={property}
                                            wfamarkup={wfamarkup} />
                                    );
                                })
                            }
                            </div>
                        </div>
                    </section>

                    <section className="pg-nd75-myprop-kissan-section">
                        <div className="container">
                            <div className="row pg-nd75-sect-heading-row">
                                <div className="col-6">
                                    <span className="pg-nd75-section-heading">My Properties - Kissan</span>
                                </div>
                                <div className="col-6">
                                    <Link to={'/user/myproperties/kissanbased'}>
                                        <div className="ml-3 float-right pg-nd75-section-maximize"></div>
                                    </Link>
                                    <div className="ml-3 float-right pg-nd75-section-sright" onClick={this.loadKissanNextPage}></div>
                                    <div className="float-right pg-nd75-section-sleft" onClick={this.loadKissanPreviousPage}></div>
                                </div>
                            </div>

                            <div className="row row-cols-1 row-cols-md-3">
                            {
                                kissanprops.map((property) => {
                                    return (
                                        <UHomePropertyKissan
                                            key={property.property_details._id}
                                            property={property}
                                            wfamarkup={wfamarkup} />
                                    );
                                })
                            }
                            </div>
                        </div>
                    </section>
                </div>
            </React.Fragment>
        );
    }
}

export default UHome;