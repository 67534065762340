import                          './CompoundSlider.css';
import React, { Fragment } from 'react';
import PropTypes           from 'prop-types';

// *******************************************************
// RAIL
// *******************************************************

export function SliderRail({ getRailProps }) {
    return (
        <Fragment>
            <div className="compslider-rail-outer" {...getRailProps()} />
            <div className="compslider-rail-inner" />
        </Fragment>
    )
}

SliderRail.propTypes = {
    getRailProps: PropTypes.func.isRequired,
}

// *******************************************************
// HANDLE COMPONENT
// *******************************************************

export function Handle({ domain: [min, max], handle: { id, value, percent }, disabled, getHandleProps }) {
    return (
        <Fragment>
            <div
                className="compslider-handle-dummy"
                style={{ left: `${percent}%` }}
                {...getHandleProps(id)} />
            <div
                className="compslider-handle"
                role="slider"
                aria-valuemin={min}
                aria-valuemax={max}
                aria-valuenow={value}
                style={{
                    left: `${percent}%`,
                    backgroundColor: disabled ? '#666' : '#37B837'
                }} />
        </Fragment>
    )
}

Handle.propTypes = {
    domain: PropTypes.array.isRequired,
    handle: PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        percent: PropTypes.number.isRequired,
    }).isRequired,
    disabled: PropTypes.bool,
    getHandleProps: PropTypes.func.isRequired
}

Handle.defaultProps = {
    disabled: false
}

// *******************************************************
// KEYBOARD HANDLE COMPONENT
// Uses a button to allow keyboard events
// *******************************************************
export function KeyboardHandle({ domain: [min, max], handle: { id, value, percent }, disabled, getHandleProps }) {
    return (
        <button
            className="compslider-handle-kb"
            role="slider"
            aria-valuemin={min}
            aria-valuemax={max}
            aria-valuenow={value}
            style={{
                left: `${percent}%`,
                backgroundColor: disabled ? '#666' : '#37B837'
            }}
            {...getHandleProps(id)} />
    )
}

KeyboardHandle.propTypes = {
    domain: PropTypes.array.isRequired,
    handle: PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        percent: PropTypes.number.isRequired,
    }).isRequired,
    disabled: PropTypes.bool,
    getHandleProps: PropTypes.func.isRequired
}

KeyboardHandle.defaultProps = {
    disabled: false
}

// *******************************************************
// TRACK COMPONENT
// *******************************************************
export function Track({ source, target, getTrackProps, disabled }) {
    return (
        <div
            className="compslider-track"
            style={{
                left: `${source.percent}%`,
                width: `${target.percent - source.percent}%`,
                backgroundColor: disabled ? '#999' : '#1CA9E1'
            }}
            {...getTrackProps()} />
    )
}

Track.propTypes = {
    source: PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        percent: PropTypes.number.isRequired,
    }).isRequired,
    target: PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        percent: PropTypes.number.isRequired,
    }).isRequired,
    disabled: PropTypes.bool,
    getTrackProps: PropTypes.func.isRequired
}

Track.defaultProps = {
    disabled: false
}