import                                './Chat.css';
import React, { Component }      from 'react';
import jwt_decode                from 'jwt-decode';
import Select , {components}     from 'react-select';
import API                       from '../../../helpers/API';
import UCommon                   from '../common/UCommon';
import {firestore}               from '../../../services/Firebase'
import {getGroup}                from './ChatUtil';
import LeftChatBubble from './LeftChatBubble';
import RightChatBubble from './RightChatBubble';

const Option = props => {
    const {data} = props;
    return (
      <components.Option {...props}>
          <div className="category-option-container">
            <img className="category-select-img" src={data.image} />
            <div className="category-option-title-container ml-1">
                <span className="category-option-title">{data.title}</span>
                {typeof data.user_name != 'undefined' ? 
                    <div className="category-option-user-container">
                    <span aria-hidden="true" class="fa fa-user-o"></span>
                    <span className="category-option-user ml-1">{data.user_name}</span>
                </div>
                :null}
                
            
            </div>
        </div>
      </components.Option>
    );
  };

class Chat extends Component {
    isSending = false;
    constructor(props) {
        super(props);
        
        this.state = {
            groupId:null,
            group:{},
            messages : [],
            currentText : '',
            userId : 0,
            propertyList : [],
            propertyId : null,
            propertyImage:'',
            selectedProperty : null,
            groups:[]
        };
        this.loadProfile = this.loadProfile.bind(this);
        this._handleKeyDown = this._handleKeyDown.bind(this)
        this.setMessages = this.setMessages.bind(this)
    }

    componentDidMount() {
        this.loadProfile();
    }

    getAllChatsByUser(){
        var strSearch = this.state.userId;
        var strlength = strSearch.length;
        var strFrontCode = strSearch.slice(0, strlength-1);
        var strEndCode = strSearch.slice(strlength-1, strSearch.length);

        var startcode = strSearch;
        var endcode= strFrontCode + String.fromCharCode(strEndCode.charCodeAt(0) + 1);
        firestore.collection('groups').where('name', '>=', startcode)
        .where('name', '<', endcode)
        .onSnapshot((querySnapshot) => {
            if(querySnapshot.empty) {
                // create group
            } else {
                let groupArray = [];
                querySnapshot.forEach((doc)=> {
                    // doc.data() is never undefined for query doc snapshots
                    var data = doc.data()
                    if(data.isActive)
                    groupArray.push(doc.data());
                });
                this.setState({groups:groupArray})
            }

        })
    }

    setMessages(messages){
        this.setState({messages : messages.reverse()},()=>{
            this.scrollToBottom()
        })
    }

    loadmessages(){
        firestore.collection(`groups/${this.state.groupId}/messages`)
        .onSnapshot((querySnapshot)=> {
            let data = [];
            querySnapshot.forEach(function(doc) {
                data.push(doc.data());
            });
            this.setMessages(data);
        });
        
    }

    createChatGroup(){
            // get group
            if(this.state.propertyId != null) {
                firestore.collection('groups').where('name','==',getGroup(this.state.userId, this.state.propertyId)).get()
                .then((querySnapshot) => {
                    if(querySnapshot.empty) {
                        // create group
                        firestore.collection('groups').add({
                            name: getGroup(this.state.userId, this.state.propertyId),
                            property : this.state.propertyId,
                            userId : this.state.userId,
                            image : this.state.propertyImage,
                            propertyObject : this.state.selectedProperty,
                            messages:[],
                            isActive:true
                        }).then(ref=>{
                            console.log('created:',ref)
                            firestore.collection('groups').doc(ref.id).get().then(qs=>{
                                this.setGroup(qs)
                            })
                        });  
                        
                    } else {
                        querySnapshot.forEach((doc)=> {
                            // doc.data() is never undefined for query doc snapshots
                            console.log(doc,doc.id, " => ", doc.data());
                            firestore.collection('groups').doc(doc.id).update({isActive:true});
                            this.setGroup(doc);
                        });
                    }
        
                })
            }
       
        
    }

    setGroup(group){
        this.setState({groupId:group.id, group:group.data()},()=>{
            this.loadmessages()
        })
    }

    loadProfile() {
        let accesstoken = localStorage.getItem('anshik-token');
        var decoded = jwt_decode(accesstoken);
        var userid = decoded.sub;
        this.setState({userId: userid},()=>{
            this.getAllChatsByUser();
        });

        const requestHeaders = {
            headers : {
                'Authorization' : 'Bearer ' + accesstoken,
                'Content-Type'  : 'application/json'
            }
        };

        API
            .get('user/' + userid, requestHeaders)
            .then((response) => {
                let responsedata = response.data.user;
                this.setState({
                    user                : responsedata,
                });
            })
            .catch(console.log);
            API
            .get('user-property-listing/' + userid, requestHeaders)
            .then((response) => {
                let responsedata = response.data.userpropertylist;
                let maped = responsedata.map((data,index)=>{
                    console.log(data)
                    data.value = data._id;
                    data.label = data.title;

                    return data;
                });
                this.setState({
                    propertyList                : maped
                });
            })
            .catch(console.log);
    }

    onClickSend(){
        this.sendMessage()
    }

    sendMessage(){
        if(!this.isSending && this.state.currentText.trim()) {
            this.isSending = true;
            var ref = firestore.collection("groups").doc(`${this.state.groupId}`).collection('messages');
        ref.doc(new Date().getTime().toString()).set({message:this.state.currentText, sender : this.state.userId}).then(()=> {
            console.log("Document successfully written!");
            this.setState({currentText:''},()=>{
                this.isSending = false
            })
            this.scrollToBottom()
        });
        }
        
    }

    _handleKeyDown = function(e) {
        if (e.key === 'Enter') {
            // post message to group
            // firestore.doc(`groups/${this.state.groupId}/messages`).update('array', [...]);
            if(this.state.currentText) {
                this.sendMessage();
            }
        }
      }

      handleChange(event){
        this.setState({currentText:event.target.value})
      }

      renderMessages(){
          return this.state.messages.map((item,index)=>{
              return (
                <React.Fragment key={item.message}>
                    {item.sender == "admin" ? <RightChatBubble>{item.message}</RightChatBubble> : <LeftChatBubble>{item.message}</LeftChatBubble>}
                </React.Fragment>
              )
          })
      }

      handlePropertyChange(newValue: any, actionMeta: any) {
          console.log('in on handle change')
        this.setState({ selectedProperty : newValue, propertyId:newValue._id,propertyImage : newValue.image},()=>{
            this.createChatGroup()
        });

    }

    onClickEnd(){
        firestore.collection('groups').doc(this.state.groupId).update({isActive:false});
        this.setState({groupId:null, group:{}, selectedProperty : null, propertyId:null,propertyImage : '',})

    }

    scrollToBottom = () => {
    const scrollHeight = this.messagesEnd.scrollHeight;
    const height = this.messagesEnd.clientHeight;
    const maxScrollTop = scrollHeight - height;
    this.messagesEnd.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }

    onClickGroup(group){
        if(this.state.group.name != group.name) {
        this.setState({propertyId : group.propertyObject._id, propertyImage : group.propertyObject.image, selectedProperty: group.propertyObject},()=>{
            this.createChatGroup()
        })
        }
    
    }

    renderEmptyChatBg(){
        return(
            <div className="empty-chat-bg-conatiner">
                <div className="empty-chat-text-container">
                <img className="empty-chat-bg" src={require("../../../images/icons/pg-306f-question.svg")}  alt="Separator" />
                <span>Start Here</span>
            </div>
            </div>
        )

    }

    renderLeftEmpty(){
        return(
        <div className="col-lg-7 col-12 left-empty-container">
            <div className="empty-chat-bg-conatiner">
                <div className="empty-chat-text-container">
                    <img className="empty-chat-bg" src={require("../../../images/icons/pg-306f-question.svg")}  alt="Separator" />
                    <span>Start Here</span>
                </div>
            </div>
        </div>
        )
    }

    renderGroups(){
        return this.state.groups.map(group=>{
            const boxClass = this.state.group.name != group.name ? 'group-box  mt-1 mb-1 disabled':'group-box  mt-1 mb-1'
            return(
                <div key={group.name} className={boxClass} onClick={this.onClickGroup.bind(this,group)}>
                    <div className="group-inner-box">
                        <img className="group-image" src={require("../../../images/icons/pg-306f-question.svg")}  alt="Separator" />
                        <div className="group-title-box ml-2">
                            <span className="category-option-title">{group.propertyObject.title}</span>
                            <span className="category-option-user">Chat with admin</span>
                        </div>
                    </div>
                    {
                        this.state.group.name == group.name ? <button onClick={this.onClickEnd.bind(this)} type="button" className="btn end-btn ml-5 pl-4 pr-4">End</button> :null
                    }
                </div>
            )
        })
    }

    render() {
        return(
            <React.Fragment>
                <UCommon />
                <section className="pg-1mw0-uprofile-section">
                    <div className="container-fluid select-category-section">
                    <div className="container">
                        <div className="row chat">
                        <img src={require("../../../images/icons/pg-306f-question.svg")}  alt="Separator" />
                        <h5 className="" style={{marginTop:'20px'}}>Chat</h5>
                            <Select
                                value={this.state.selectedProperty}
                                options={this.state.propertyList}
                                placeholder="Select Properties"
                                isSearchable={false}
                                className="pg-hbr4-pghpropselect-o no-show-mobile ml-4"
                                classNamePrefix="pg-hbr4-pghselect"
                                components={{Option,IndicatorSeparator : null,}}
                                onChange={this.handlePropertyChange.bind(this)} />
                                {/* <button onClick={this.onPressStart.bind(this)} className="btn start-button ml-5">Start</button> */}
                        </div>
                        </div>
                    </div>
                    <div className="chat-container">
                    {(this.state.groups.length != 0) ?
                        <div className="container">
                            <div className="row">
                            {(this.state.groupId != null) ?
                                <div className="col-lg-7 col-12 left-container">
                                    <div className="chat-input-box">
                                        <input className="input-field" value={this.state.currentText} onChange={this.handleChange.bind(this)} type="text" id="chat-input" onKeyDown={this._handleKeyDown}></input>
                                        <div onClick={this.onClickSend.bind(this)} className="sent-icon-container">
                                            <span className="message-sent-icon"></span>
                                        </div>
                                    </div>
                                    <div className="messages-container-wrapper"  ref={(div) => {
                                        this.messagesEnd = div;
                                        }}>
                                    <div className="messages-container">
                                        {this.renderMessages()}
                                    </div>
                                    </div>

                                </div>
                                :this.renderLeftEmpty()}
                                <div className="col-lg-5 col-12 ">
                                        {this.renderGroups()}    
                                </div>

                            </div>
                        </div>
                        : this.renderEmptyChatBg() }
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default Chat;