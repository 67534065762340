import React, { Component }    from 'react';
import PropTypes               from 'prop-types';
import Img                     from 'react-image';
import FontAwesome             from 'react-fontawesome';
import Slider                  from 'react-styled-carousel-am';
import RangeSlider             from 'react-rangeslider';
import ReactImageVideoLightbox from '../../components/react-image-video-lightbox/index';
import API                     from '../../helpers/API';
import { numregex }            from '../../helpers/Constants';
import { toCurrency }          from '../../helpers/Formatters';
import { getYoutubeEmbedURL }  from '../../helpers/StringUtil';

class PropertyInterestKissan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            totalamount         : this.props.propertydata.price,
            totalyield          : this.props.propertydata.yield,
            totalshare          : 100,
            useramount          : 0,
            useryield           : 0,
            usershare           : 0,
            balanceshare        : 100,
            pvideoandimages     : [],
            showimagevideomodal : false
        };

        this.handleUserAmountChange = this.handleUserAmountChange.bind(this);
        this.handleUserYieldChange  = this.handleUserYieldChange.bind(this);
        this.handleUserShareChange  = this.handleUserShareChange.bind(this);
        this.toggleImageVideoModal  = this.toggleImageVideoModal.bind(this);
        this.expressInterest        = this.expressInterest.bind(this);
    }

    componentDidMount() {
        const { propertydata } = this.props;

        let pvideoandimages = [];
        let videourl = propertydata.video_url;
        if (videourl !== '') {
            let embedurl = getYoutubeEmbedURL(videourl);
            pvideoandimages.push({
                url    : embedurl,
                type   : 'video',
                altTag : ''
            });
        }

        let images = propertydata.image;
        images.forEach((image) => {
            pvideoandimages.push({
                url    : image,
                type   : 'photo',
                altTag : ''
            });
        });

        this.setState({ pvideoandimages : pvideoandimages });
    }

    updateStateValues(useramount, useryield, usershare) {
        const { totalshare } = this.state;

        this.setState({
            useramount   : Math.round(useramount),
            useryield    : Math.round(useryield),
            usershare    : Math.round(usershare),
            balanceshare : totalshare - Math.round(usershare)
        });
    }

    handleUserAmountChange(e) {
        let useramount = e.target.value.replace(/,/g, '');

        if (useramount === '' || numregex.test(useramount)) {
            const { totalamount, totalyield } = this.state;

            let usershare     = (useramount / totalamount);
            let usershareperc = usershare * 100;
            let useryield     = totalyield * usershare;

            this.updateStateValues(useramount, useryield, usershareperc);
        }
    }

    handleUserYieldChange(e) {
        let useryield = e.target.value;

        if (useryield === '' || numregex.test(useryield)) {
            const { totalamount, totalyield } = this.state;

            let usershare     = (useryield / totalyield);
            let usershareperc = usershare * 100;
            let useramount    = totalamount * usershare;

            this.updateStateValues(useramount, useryield, usershareperc);
        }
    }

    handleUserShareChange(value) {
        let usershareperc = value;

        const { totalamount, totalyield } = this.state;

        let usershare  = usershareperc / 100;
        let useramount = totalamount * usershare;
        let useryield  = totalyield * usershare;

        this.updateStateValues(useramount, useryield, usershareperc);
    }

    toggleImageVideoModal() {
        this.setState(prevState => ({
            showimagevideomodal : !prevState.showimagevideomodal
        }));
    }

    expressInterest(propertyid) {
        let accesstoken = localStorage.getItem('anshik-token');

        if (accesstoken !== null) {
            const { useramount, useryield, totalamount, totalyield } = this.state;
            if (useramount === '') {
                alert('User Amount is required');
                return;
            }
            let iuseramount = parseInt(useramount);
            if (iuseramount === 0) {
                alert('User Amount cannot be 0');
                return;
            }
            if (iuseramount > totalamount) {
                alert('User Amount cannot be greater than Total Amount');
                return;
            }

            if (useryield === '') {
                alert('User Yield is required');
                return;
            }
            let iuseryield = parseInt(useryield);
            if (iuseryield === 0) {
                alert('User Yield cannot be 0');
                return;
            }
            if (iuseryield > totalyield) {
                alert('User Yield cannot be greater than Total Yield');
                return;
            }

            const requestHeaders = {
                headers : {
                    'Authorization' : 'Bearer ' + accesstoken,
                    'Content-Type'  : 'application/json'
                }
            };
            const requestParams = {
                'property_details_id' : propertyid,
                'investor_amount'     : iuseramount,
                'investor_yield'      : iuseryield
            };

            API
                .post('property-interest-details-kissan', requestParams, requestHeaders)
                .then((response) => {
                    this.props.toggleSuccessModal();
                })
                .catch((error) => {
                    // console.log('AXIOS Post: Error: ');
                    // console.log(error);
                });
        } else {
            this.props.setAppState('showsigninmodal', true);
        }
    }

    render() {
        const { categorydata, propertydata, adstatus } = this.props;
        const { totalshare, useramount, useryield, usershare, balanceshare, pvideoandimages, showimagevideomodal } = this.state;
        const responsive = [
            { breakPoint: 768, cardsToShow: 4 }, // this will be applied if screen size is greater than 1280px. cardsToShow will become 4.
            { breakPoint: 480, cardsToShow: 3 },
            { breakPoint: 0, cardsToShow: 2 },
          ];
        return(
            <React.Fragment>
                <section className="pg-6ef4-ad-det-section">
                    <div className="container">
                        <div className="row no-mobile">
                            <div className="col-12">
                                <div className="pg-6ef4-ad-catg-img-wrapper">
                                    <Img src={categorydata.image} alt="..." className="img-fluid" />
                                    {adstatus}
                                </div>

                                <div className="pg-6ef4-ad-det-wrapper">
                                    <div className="row align-items-center">
                                        <div className="col-9 pg-6ef4-ad-det-col1">
                                            <p className="pg-6ef4-ad-category">{categorydata.category}</p>
                                            <p className="pg-6ef4-ad-title">{propertydata.title}</p>
                                            <div className="row pg-6ef4-ad-location-user-row">
                                                <div className="col-12">
                                                    <span>
                                                        <img src={require("../../images/icons/ad-location.png")} alt="Location Icon" />
                                                        <span className="ml-2 pg-6ef4-ad-location">{propertydata.location}</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3">
                                                    <div className="pg-6ef4-prop-kissan-icon-wrapper">
                                                        <img src={require("../../images/icons/pg-22rt-land.png")} alt="Land" />
                                                    </div>
                                                    <div className="pg-6ef4-prop-kissan-lv-wrapper">
                                                        <p className="pg-6ef4-prop-kissan-ly-label">Land</p>
                                                        <p className="pg-6ef4-prop-kissan-ly-value">{propertydata.land} sq.ft</p>
                                                    </div>
                                                </div>

                                                <div className="col-3">
                                                    <div className="pg-6ef4-prop-kissan-icon-wrapper">
                                                        <img src={require("../../images/icons/pg-22rt-yield.png")} alt="Yield" />
                                                    </div>
                                                    <div className="pg-6ef4-prop-kissan-lv-wrapper">
                                                        <p className="pg-6ef4-prop-kissan-ly-label">Yield</p>
                                                        <p className="pg-6ef4-prop-kissan-ly-value">{propertydata.yield} kg</p>
                                                    </div>
                                                </div>

                                                <div className="col-3">
                                                    <div className="pg-6ef4-prop-kissan-icon-wrapper">
                                                        <img src={require("../../images/icons/pg-22rt-crop.png")} alt="Crop" />
                                                    </div>
                                                    <div className="pg-6ef4-prop-kissan-lv-wrapper">
                                                        <p className="pg-6ef4-prop-kissan-ly-label">Crop</p>
                                                        <p className="pg-6ef4-prop-kissan-ly-value">{propertydata.crop}</p>
                                                    </div>
                                                </div>

                                                <div className="col-3">
                                                    <div className="pg-6ef4-prop-kissan-icon-wrapper">
                                                        <img src={require("../../images/icons/pg-22rt-time.png")} alt="Time" />
                                                    </div>
                                                    <div className="pg-6ef4-prop-kissan-lv-wrapper">
                                                        <p className="pg-6ef4-prop-kissan-ly-label">Time</p>
                                                        <p className="pg-6ef4-prop-kissan-ly-value">{propertydata.time} months</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-3">
                                            <div className="pg-6ef4-ad-tprice-wrapper">
                                                <p className="pg-6ef4-ad-tprice-label">Amount</p>
                                                <p className="pg-6ef4-ad-tprice-value">
                                                    <FontAwesome name="rupee" /> {toCurrency(propertydata.price)}</p>

                                                <div className="row">
                                                    <div className="col-12">
                                                        <button className="btn btn-block apf-button interest-link" 
                                                            onClick={() => { this.expressInterest(propertydata._id) }}>Join</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row pg-6ef4-ad-toggle-controls-row">
                                        <div className="col-12">
                                            <a className="pg-6ef4-sh-link" href="#ad-full-desc">View full details</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mobile">
                                <div className="col-12 pg-6ef4-ad-catg-img-wrapper">
                                    <Img src={categorydata.image} alt="..." className="img-fluid" />
                                    {adstatus}
                                </div>

                                <div className="col-12">
                                    <div className="row align-items-center">
                                        <div className="col-12">
                                            <p className="pg-6ef4-ad-category">{categorydata.category}</p>
                                            <p className="pg-6ef4-ad-title">{propertydata.title}</p>
                                            <div className="row pg-6ef4-ad-location-user-row">
                                                <div className="col-12">
                                                    <span>
                                                        <img src={require("../../images/icons/ad-location.png")} alt="Location Icon" />
                                                        <span className="ml-2 pg-6ef4-ad-location">{propertydata.location}</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="row container">
                                                <div className="col-6 icon-box">
                                                    <div className="pg-6ef4-prop-kissan-icon-wrapper">
                                                        <img src={require("../../images/icons/pg-22rt-land.png")} alt="Land" />
                                                    </div>
                                                    <div className="pg-6ef4-prop-kissan-lv-wrapper icon-text-box">
                                                        <p className="pg-6ef4-prop-kissan-ly-label">Land</p>
                                                        <p className="pg-6ef4-prop-kissan-ly-value">{propertydata.land} sq.ft</p>
                                                    </div>
                                                </div>

                                                <div className="col-6 icon-box">
                                                    <div className="pg-6ef4-prop-kissan-icon-wrapper">
                                                        <img src={require("../../images/icons/pg-22rt-yield.png")} alt="Yield" />
                                                    </div>
                                                    <div className="pg-6ef4-prop-kissan-lv-wrapper icon-text-box">
                                                        <p className="pg-6ef4-prop-kissan-ly-label">Yield</p>
                                                        <p className="pg-6ef4-prop-kissan-ly-value">{propertydata.yield} kg</p>
                                                    </div>
                                                </div>
                                                <div className="clearfix"></div>

                                                <div className="col-6 icon-box">
                                                    <div className="pg-6ef4-prop-kissan-icon-wrapper">
                                                        <img src={require("../../images/icons/pg-22rt-crop.png")} alt="Crop" />
                                                    </div>
                                                    <div className="pg-6ef4-prop-kissan-lv-wrapper icon-text-box">
                                                        <p className="pg-6ef4-prop-kissan-ly-label">Crop</p>
                                                        <p className="pg-6ef4-prop-kissan-ly-value">{propertydata.crop}</p>
                                                    </div>
                                                </div>

                                                <div className="col-6 icon-box">
                                                    <div className="pg-6ef4-prop-kissan-icon-wrapper">
                                                        <img src={require("../../images/icons/pg-22rt-time.png")} alt="Time" />
                                                    </div>
                                                    <div className="pg-6ef4-prop-kissan-lv-wrapper icon-text-box">
                                                        <p className="pg-6ef4-prop-kissan-ly-label">Time</p>
                                                        <p className="pg-6ef4-prop-kissan-ly-value">{propertydata.time} months</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        
                                    </div>

                                    <div className="row pg-6ef4-ad-toggle-controls-row">
                                        
                                    </div>
                                </div>

                                <div className="col-12">
                                    <a className="pg-6ef4-sh-link" href="#ad-full-desc">View full details</a>
                                </div>

                                <div className="col-12">
                                    <div className="pg-6ef4-ad-tprice-wrapper">
                                        <p className="pg-6ef4-ad-tprice-label">Amount</p>
                                        <p className="pg-6ef4-ad-tprice-value">
                                            <FontAwesome name="rupee" /> {toCurrency(propertydata.price)}</p>

                                        <div className="row">
                                            <div className="col-12">
                                                <button className="btn btn-block apf-button interest-link" 
                                                    onClick={() => { this.expressInterest(propertydata._id) }}>Join</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                
                            
                        </div>
                    </div>
                </section>

                <section className="pg-6ef4-ad-imgs-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <Slider autoSlide={false} showDots={false} hideArrowsOnNoSlides={false} padding={'0px 20px'} responsive={responsive}>
                                    <div className="pg-6ef4-ad-video-wrapper" onClick={this.toggleImageVideoModal}>
                                        <Img src={propertydata.image[0]} alt="..." className="img-fluid" />
                                        <div className="pg-6ef4-video-play-icon"></div>
                                    </div>
                                    {
                                        propertydata.image.map((pimage, index) => {
                                            return(
                                                <div key={'pimage' + index} className="pg-6ef4-ad-img-wrapper" onClick={this.toggleImageVideoModal}>
                                                    <Img src={pimage} alt="..." className="img-fluid" />
                                                </div>
                                            );
                                        })
                                    }
                                </Slider>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="pg-6ef4-yield-prop-section no-mobile">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="row pg-6ef4-sect-heading-row">
                                    <div className="col-12">
                                        <span className="pg-6ef4-section-heading">Yield Proportion</span>
                                    </div>
                                </div>

                                <div className="row pg-6ef4-sh-slider-row">
                                    <div className="col-10 offset-lg-1">
                                        <div className="row pg-6ef4-mshare-tshare-row">
                                            <div className="col-6">
                                                <div className="h-100 pg-6ef4-mshare-wrapper">
                                                    <div className="pg-6ef4-mshare-heading">My Share</div>
                                                    <div className="row pg-6ef4-mshare-row">
                                                        <div className="col-6">
                                                            <label htmlFor="userAmount" className="d-block pg-6ef4-mshare-form-label">Amount</label>
                                                            <div className="input-group pg-6ef4-mshare-igroup">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text"><FontAwesome name="rupee" /></span>
                                                                </div>
                                                                <input type="text" id="userAmount" className="form-control pg-6ef4-mshare-textbox"
                                                                    placeholder="Your share" value={useramount === '' ? '' : toCurrency(useramount)} 
                                                                    onChange={this.handleUserAmountChange} />
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <label htmlFor="userYield" className="d-block pg-6ef4-mshare-form-label">Yield</label>
                                                            <div className="input-group pg-6ef4-mshare-igroup">
                                                                <input type="text" id="userYield" className="form-control pg-6ef4-mshare-textbox-yield"
                                                                    placeholder="Your yield" value={useryield} onChange={this.handleUserYieldChange} />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text pg-6ef4-mshare-igt-yield">Kg</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-6">
                                                <div className="h-100 pg-6ef4-oshare-wrapper">
                                                    <div className="pg-6ef4-oshare-heading">Total Share</div>
                                                    <div className="row no-gutters pg-6ef4-oshare-row">
                                                        <div className="col-4">
                                                            <div className="pg-6ef4-icon-wrapper">
                                                                <img src={require("../../images/icons/pg-a67d-amount.png")} alt="Amt Icon" />
                                                            </div>
                                                            <div className="pg-6ef4-lv-wrapper">
                                                                <p className="pg-6ef4-oshare-label">Amount</p>
                                                                <p className="pg-6ef4-oshare-value"><FontAwesome name="rupee" /> {toCurrency(propertydata.price)}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-4">
                                                            <div className="pg-6ef4-icon-wrapper">
                                                                <img src={require("../../images/icons/pg-6ef4-yield.png")} alt="Days Icon" />
                                                            </div>
                                                            <div className="pg-6ef4-lv-wrapper">
                                                                <p className="pg-6ef4-oshare-label">Yield</p>
                                                                <p className="pg-6ef4-oshare-value">{propertydata.yield} kg</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-4"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <Img src={require("../../images/icons/user-in-group-left.png")} alt="Investor Icon" />
                                                    </div>
                                                    <div className="col-6 text-right">
                                                        <Img src={require("../../images/icons/user-in-group.png")} alt="Kissan Icon" />
                                                    </div>
                                                </div>

                                                <RangeSlider
                                                    min={0}
                                                    max={totalshare}
                                                    value={usershare}
                                                    onChange={this.handleUserShareChange} />

                                                <div className="row">
                                                    <div className="col-6">
                                                        <span className="pg-6ef4-sh-slider-label">Investor</span>
                                                    </div>
                                                    <div className="col-6 text-right">
                                                        <span className="pg-6ef4-sh-slider-label">Kissan</span>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-6">
                                                        <span className="pg-6ef4-sh-slider-value">{usershare}%</span>
                                                    </div>
                                                    <div className="col-6 text-right">
                                                        <span className="pg-6ef4-sh-slider-value">{balanceshare}%</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row text-center">
                                    <div className="col-12">
                                        <button className="btn apf-button pg-6ef4-int-btn" 
                                            onClick={() => { this.expressInterest(propertydata._id) }}>Join</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="pg-6ef4-yield-prop-section mobile">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="row pg-6ef4-sect-heading-row">
                                    <div className="col-12">
                                        <span className="pg-6ef4-section-heading">Yield Proportion</span>
                                    </div>
                                </div>
                            </div>

                                
                            <div className="col-12 offset-lg-1">
                                <div className="row pg-6ef4-mshare-tshare-row">
                                    <div className="col-12">
                                        <div className="h-100 pg-6ef4-mshare-wrapper">
                                            <div className="pg-6ef4-mshare-heading">My Share</div>
                                            <div className="row pg-6ef4-mshare-row">
                                                <div className="col-6">
                                                    <label htmlFor="userAmount" className="d-block pg-6ef4-mshare-form-label">Amount</label>
                                                    <div className="input-group pg-6ef4-mshare-igroup">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text"><FontAwesome name="rupee" /></span>
                                                        </div>
                                                        <input type="text" id="userAmount" className="form-control pg-6ef4-mshare-textbox"
                                                            placeholder="Your share" value={useramount === '' ? '' : toCurrency(useramount)} 
                                                            onChange={this.handleUserAmountChange} />
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <label htmlFor="userYield" className="d-block pg-6ef4-mshare-form-label">Yield</label>
                                                    <div className="input-group pg-6ef4-mshare-igroup">
                                                        <input type="text" id="userYield" className="form-control pg-6ef4-mshare-textbox-yield"
                                                            placeholder="Your yield" value={useryield} onChange={this.handleUserYieldChange} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text pg-6ef4-mshare-igt-yield">Kg</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 mt-4">
                                        <div className="h-100 pg-6ef4-oshare-wrapper">
                                            <div className="pg-6ef4-oshare-heading">Total Share</div>
                                            <div className="row no-gutters pg-6ef4-oshare-row">
                                                <div className="col-4">
                                                    <div className="pg-6ef4-icon-wrapper">
                                                        <img src={require("../../images/icons/pg-a67d-amount.png")} alt="Amt Icon" />
                                                    </div>
                                                    <div className="pg-6ef4-lv-wrapper">
                                                        <p className="pg-6ef4-oshare-label">Amount</p>
                                                        <p className="pg-6ef4-oshare-value"><FontAwesome name="rupee" /> {toCurrency(propertydata.price)}</p>
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className="pg-6ef4-icon-wrapper">
                                                        <img src={require("../../images/icons/pg-6ef4-yield.png")} alt="Days Icon" />
                                                    </div>
                                                    <div className="pg-6ef4-lv-wrapper">
                                                        <p className="pg-6ef4-oshare-label">Yield</p>
                                                        <p className="pg-6ef4-oshare-value">{propertydata.yield} kg</p>
                                                    </div>
                                                </div>
                                                <div className="col-4"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-6">
                                                <Img src={require("../../images/icons/user-in-group-left.png")} alt="Investor Icon" />
                                            </div>
                                            <div className="col-6 text-right">
                                                <Img src={require("../../images/icons/user-in-group.png")} alt="Kissan Icon" />
                                            </div>
                                        </div>

                                        <RangeSlider
                                            min={0}
                                            max={totalshare}
                                            value={usershare}
                                            onChange={this.handleUserShareChange} />

                                        <div className="row">
                                            <div className="col-6">
                                                <span className="pg-6ef4-sh-slider-label">Investor</span>
                                            </div>
                                            <div className="col-6 text-right">
                                                <span className="pg-6ef4-sh-slider-label">Kissan</span>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-6">
                                                <span className="pg-6ef4-sh-slider-value">{usershare}%</span>
                                            </div>
                                            <div className="col-6 text-right">
                                                <span className="pg-6ef4-sh-slider-value">{balanceshare}%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                


                                    <div className="col-12 mt-4">
                                        <button className="btn apf-button pg-6ef4-int-btn" 
                                            onClick={() => { this.expressInterest(propertydata._id) }}>Join</button>
                                    </div>
                                
                            
                        </div>
                    </div>
                </section>

                <section className="pg-6ef4-desc-section" id="ad-full-desc">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="row pg-6ef4-sect-heading-row">
                                    <div className="col-12">
                                        <span className="pg-6ef4-section-heading">Description</span>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <p className="pg-6ef4-desc">{propertydata.description}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {
                    showimagevideomodal &&
                    <ReactImageVideoLightbox
                        data={pvideoandimages}
                        startIndex={0}
                        showResourceCount={false}
                        onCloseCallback={this.toggleImageVideoModal} />
                }
            </React.Fragment>
        );
    }
}

PropertyInterestKissan.protoTypes = {
    setAppState        : PropTypes.func,
    toggleSuccessModal : PropTypes.func
};

export default PropertyInterestKissan;