import                                './LeftChatBubble.css';
import React from 'react'

export default class LeftChatBubble extends React.Component {

    constructor(props) {
        super(props)
    }
    render(){
        return(
            <div className="d-flex">
                <div className="left-bubble-container">
                    <div style={{position:'relative'}}>
                    <div className="left-message-box">
                    {this.props.children}
                    </div>
                    </div>
                </div>
                
            </div>
        )
    }
}