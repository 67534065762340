import React, { Component } from 'react';
import { Link }             from 'react-router-dom';
import Img                  from 'react-image';
import FontAwesome          from 'react-fontawesome';
import { toCurrency }       from '../../../helpers/Formatters';

class UHomePropertyProfitBased extends Component {
    render() {
        const { property, wfamarkup } = this.props;

        let pstatus = '';
        if (property.property_details.partnership_status === 0) {
            pstatus = <div className="pg-nd75-ad-status pg-nd75-as-reload"></div>;
        } else if (property.property_details.partnership_status === 1) {
            pstatus = <div className="pg-nd75-ad-status pg-nd75-as-ok"></div>;
        }

        return(
            <div className="col pg-nd75-ad-col">
            {
                (property.property_details.approval_waiting === 0) ? (
                    <div className="card h-100 pg-nd75-ad-card">
                        {wfamarkup}
                        <div className="row h-100 no-gutters">
                            <div className="pg-nd75-ad-card-col1">
                                <Img src={property.property_details.image} alt="..." className="card-img pg-nd75-ad-img" />
                                <div className="pg-nd75-ad-price">
                                    <span><FontAwesome name="rupee" /> {toCurrency(property.property_details.price)}</span>
                                </div>
                            </div>
                            <div className="pg-nd75-ad-card-col2">
                                {pstatus}
                                <div className="card-body pg-nd75-ad-card-body">
                                    <h5 className="pg-nd75-ad-title">{property.property_details.title}</h5>
                                    <div className="row">
                                        <div className="col-12">
                                            <span>
                                                <Img src={require("../../../images/icons/pg-nd75-ad-users.png")} alt="Users" />
                                                <span className="ml-2 pg-nd75-ad-users">{property.share_details.total_approved_user} 
                                                    {property.share_details.total_approved_user < 2 ? ' user' : ' users'}</span>
                                            </span>
                                            <span className="ml-3">
                                                <Img src={require("../../../images/icons/pg-nd75-ad-profit.png")} alt="Profit" />
                                                <span className="ml-2 pg-nd75-ad-profit">20% profit</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <Link to={'/user/myproperty/' + property.property_details._id}>
                        <div className="card h-100 pg-nd75-ad-card">
                            <div className="row h-100 no-gutters">
                                <div className="pg-nd75-ad-card-col1">
                                    <Img src={property.property_details.image} alt="..." className="card-img pg-nd75-ad-img" />
                                    <div className="pg-nd75-ad-price">
                                        <span><FontAwesome name="rupee" /> {toCurrency(property.property_details.price)}</span>
                                    </div>
                                </div>
                                <div className="pg-nd75-ad-card-col2">
                                    {pstatus}
                                    <div className="card-body pg-nd75-ad-card-body">
                                        <h5 className="pg-nd75-ad-title">{property.property_details.title}</h5>
                                        <div className="row">
                                            <div className="col-12">
                                                <span>
                                                    <Img src={require("../../../images/icons/pg-nd75-ad-users.png")} alt="Users" />
                                                    <span className="ml-2 pg-nd75-ad-users">{property.share_details.total_approved_user} 
                                                        {property.share_details.total_approved_user < 2 ? ' user' : ' users'}</span>
                                                </span>
                                                <span className="ml-3">
                                                    <Img src={require("../../../images/icons/pg-nd75-ad-profit.png")} alt="Profit" />
                                                    <span className="ml-2 pg-nd75-ad-profit">20% profit</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                )
            }
            </div>
        );
    }
}

export default UHomePropertyProfitBased;